
import React from 'react';
import {memo} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState,useEffect } from 'react';
import { useDispatch, useSelector ,connect} from "react-redux";
import $, { post } from 'jquery';
import swal from 'sweetalert';


import { getCutoffFilters,newCourses, addNewCutoff } from '../../actions/Cutoffs';

const AddFeeBulk = () =>
{
 const [colleges,setColleges]=useState([]);
 const [courses,setCourses]=useState([]);
 const [college_id,setCollegeID]=useState(0);
 const[state,setState]=useState([]);
 const [year,setYear]=useState(0);
 const [course_level,setCourseLevel]=useState(0);
 const [college_type,setCollegeType]=useState("");
 const [other_fees,setOtherFees]=useState(false);
 const [rounds,setRounds]=useState([]);
 const [roundvalue,setRoundValue]=useState(0);
 const [categories,setCategories]=useState([]); 
 const [error,setError]=useState(<div></div>);
 const [errorcounter,setErrorCounter]=useState(0);
 const [course_id,setCourseId]=useState(0);
 const [courseempty,setCourseEmpty]=useState(true);
 const [college_course_id,setCollegeCourseId]=useState();
 const [quota,setQuota]=useState(0);
 const  [count,setCount]=useState(1);
 const [nrierrorcounter,setNriErrorCounter]=useState(0);
 const [counter,setCounter]=useState(0);
 let courses_list_total=useSelector(state => state.cutoff.courses)
 let userid=useSelector(state=>state.admin.admin_info.id)
 var college_courseid=0;
 const [user,setUser]=useState(userid)
 const dispatch = useDispatch();

 useEffect(() => {
 loadColleges();
 loadCategories();
 loadRounds();

}, []);

const loadRounds=async ()=>
{
  let token = "Bearer "+ localStorage.getItem('auth_token');
  await axios.get("https://api.prod.goocampus.in/api/admin/get_all_cutoff_rounds", { headers: { 'Authorization': token } })
  .then(response=>{
    setRounds(response.data.rounds)
   })
}

const loadCategories=async ()=>
{
  let token = "Bearer "+ localStorage.getItem('auth_token');
  await axios.get("https://api.prod.goocampus.in/api/admin/get_all_cutoff_categories", { headers: { 'Authorization': token } })
  .then(response=>{
    
  
    setCategories( response.data.categories)
  })
}


const loadColleges = async ()=> 
{
 
 
  let token = "Bearer "+ localStorage.getItem('auth_token');

  await axios.get("https://api.prod.goocampus.in/api/admin/get_all_colleges_for_cutoff", { headers: { 'Authorization': token } })
  .then(response=>{
    
    setColleges(response.data.colleges)
  })

}
const loadCourses = async (college_id,course_level)=>
    {
  
      let token = "Bearer "+ localStorage.getItem('auth_token');
      setCollegeID(college_id)
 
      setCourseId(0)
      await  axios.get(`https://api.prod.goocampus.in/api/admin/get_all_courses_for_cutoff/${college_id}/${course_level}`, { headers: { 'Authorization': token } })
      .then((response)=>{
        setCollegeType(response.data.college_type) 
        setState(response.data.college_state)
       
        let coursedata=[];
        let course=response.data.courses;
         
           
        
        if(course.length==0)
        {
          setCourseEmpty(true);
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> There are no Courses for this Course level in this College,Select Different Course Level or Add the courses for this college</div>)
         $('#course_id').hide()
          $('#course_label').hide()
        }
        else
        {
          setCourseEmpty(false);
          $('#course_id').show()
          $('#course_label').show()
          dispatch(newCourses(course));
          setError(<div></div>)
        }
       
       
        
      
        //  setCourses(coursedata)

      });
    
    }

   function  setCourseLevelvalue(value)
    {
      document.getElementById('course_level').value="0";
    }

    var course_all_list=[];

   
    let this_year = (new Date()).getFullYear()+1;
    let all_years = [];
    let years_list = [];
      for(let x = 0; x <= 5; x++) {
       all_years.push(this_year - x)
      }

        let rounds_list=[];
   
  if(rounds && rounds !== '' && rounds.length > 0){
    rounds_list = rounds.map((round)=>
   <option key={round.id} value={round.id}>{round.round}</option>
  )}
  
  let categories_list=[];
   
 
  if(categories && categories !== '' && categories.length > 0){
    categories_list = categories.map((category)=>
   <option key={category.id} value={category.id}>{category.category}</option>
  )}
   
  

if(all_years){
 years_list = all_years.map((year) => (
   <option key={year} value={year} >{year}</option>
 ));
}

let colleges_list = [];
  if(colleges && colleges !== '' && colleges.length > 0){
    colleges_list = colleges.map((college)=>
   <option key={college.id} value={college.id}>{college.col_name}</option>
  )}
 
  // let course_list=[];
  let table_data_list=[];
 
   if(courseempty===false && courses_list_total.length>0 ){
  
    if(college_type==="Private"){
    
      table_data_list=<div className='table_data'><table className='table table-bordered mt-5'>
      <thead>
        <tr>
          <th width="25%">Course Name</th>
          <th width="10%">Government Fee</th>
          <th width="10%">Private Fee</th>
          <th width="20%">Management Fee</th>
          <th width="20%"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NRI Fee</span><select  className='form-control text-left mt-1' id="currency" name="currency"><option value={0}>Choose currency</option><option value={"Rupees"}>₹(Rupees)</option><option value={"Dollars"}>$(Dollars)</option></select></th>
        </tr>
      </thead>
      <tbody>
        {courses_list_total.map((course,index)=>
      
          <tr key={index}>
          
            <td>{course.course_name}<input className='course' type="hidden" id={"course_id"+(index+1)} value={course.course_id}/><input type="hidden" id={"course_name"+(index+1)} value={course.course_name}/>
            <input type="hidden" id={"college_course_id"+(index+1)} name={"college_course_id"+(index+1)}  />
            </td>
            <td >
              <input className='form-control govt_fee' type="number" name ={"govt_fee"+(index+1)} id={"govt_fee"+(index+1)} placeholder='Govt Fee' />
             
            </td>
            <td>
              <input  type="number" className='form-control private_fee' name={"private_fee"+(index+1)}  id={"private_fee"+(index+1)} placeholder='Private Fee' />
              
            </td>
            <td> 
              
            <input type="number" className='form-control management_fee' name={"management_fee"+(index+1)} id={"management_fee"+(index+1)}  placeholder='Management Fee' />
            

            </td>
            <td>
              <input type="number" className='form-control nri_fee' name={"nri_fee"+(index+1)} id={"nri_fee"+(index+1)} placeholder='NRI Fees'   />
             

            </td>
          
          </tr>
         
      )} </tbody>
      </table></div>
    }else if(college_type==="Government")
    {

      table_data_list=
      <div className='table_data'>
      <table className='table table-bordered mt-5'>
      <thead>
        <tr>
          <th>Course Name</th>
          <th>Government Fee</th>
          {other_fees===true?<th>NRI Fee<select  className='form-control text-left' id="currency" name="currency"><option value={"Rupees"}>₹(Rupees)</option><option value={"Dollars"}>$(Dollars)</option></select></th>:""}
        </tr>
      </thead>
      <tbody>
      
     { courses_list_total.map((course,index)=>
      <tr  key={index}>
       
       <td>{course.course_name}<input className='course' type="hidden" id={"course_id"+(index+1)} value={course.course_id}/><input type="hidden" id={"course_name"+(index+1)} value={course.course_name}/> <input type="hidden" id={"college_course_id"+(index+1)} name={"college_course_id"+(index+1)} /></td>       
       <td >
          <input className='form-control govt_fee' type="number" name ={"govt_fee"+(index+1)} id={"govt_fee"+(index+1)} placeholder='Govt Fee' />
          

        </td>
        {other_fees===true? <td >
          <input className='form-control nri_fee' type="number" name={"nri_fee"+(index+1)} id={"nri_fee"+(index+1)} placeholder='NRI Fee' />
        
        </td>:""}
        
</tr>)}</tbody></table></div>
    }else if(college_type==="Deemed")
    {
      table_data_list=<div className='table_data'><table className='table table-bordered mt-5'>
      <thead>
        <tr>
          <th>Course Name</th>
          
          <th>Management Fee</th>
          <th>NRI Fee <select className='form-control text-left' id="currency" name="currency"><option value={0}>Choose Currency</option><option value={"Rupees"}>₹(Rupees)</option><option value={"Dollars"}>$(Dollars)</option></select></th>
        </tr>
      </thead>
      <tbody>
     { courses_list_total.map((course,index)=>
      <tr  key={index}>
        
        <td>{course.course_name}<input className='course' type="hidden" id={"course_id"+(index+1)} value={course.course_id}/><input type="hidden" id={"course_name"+(index+1)} value={course.course_name}/> <input type="hidden" id={"college_course_id"+(index+1)} name={"college_course_id"+(index+1)} /></td>        
        <td> 
          
          <input type="number" className='form-control management_fee'  name={"management_fee"+(index+1)} id={"management_fee"+(index+1)}  placeholder='Management Fee' />
          
          </td>
          <td>
            <input  type="number"className='form-control nri_fee' name={"nri_fee"+(index+1)} id={"nri_fee"+(index+1)} placeholder='NRI Fees'   />
            
          </td>
        </tr>)
    }</tbody></table></div>}
  }


  let year_select_list=<select></select>
  let  college_select_list=<select></select>
  let course_level_list=<select></select>
  let total_courses_list=<table></table>

 

   year_select_list=<select className='form-control' id="cutoff_year" name="cutoff_year" onChange={(e)=>{setYear(e.target.value)}} > <option value="0">Select year</option>{years_list} </select>
  
  
   if(parseInt(year)!==0){ 
  
    course_level_list =<div><label>Course Level</label><select className='form-control' id="course_level" name="cutoff_course_level"  onChange={(e)=>{setCourseLevel(e.target.value);loadCourses(college_id,e.target.value)}} defaultValue={0}><option value={0}> --Select Course Level--</option><option value={1}>UG</option><option value={2}>PG</option></select></div>
    }else
    {
      course_level_list=<div></div>
    }
    if(parseInt(year)!==0 && parseInt(course_level)!==0 ){
      college_select_list =  <div><label>Colleges</label><select className='form-control' id="cutoff_college" name="cutoff_college"  onChange={(e)=>{loadCourses(e.target.value,course_level)}}><option value="0">----------Select College--------------------</option>{colleges_list}</select></div>
    
   }
   else
   {
    college_select_list =<div></div>
   }

   if(parseInt(course_level)!==0 && parseInt(college_id)!==0)
   {
    
    total_courses_list=<div>{table_data_list}</div>
   }else
   {
    total_courses_list=<div></div>
   }
   let formlist=<div></div>
   var nri_button=<div></div>
   if(courseempty===false && courses_list_total.length>0){
   if(college_type==="Government")
   {
     nri_button=other_fees===false?<button className='btn btn-success mt-4' onClick={()=>setOtherFees(fees=>!fees)} > Add Other Fees</button>:<button className='btn btn-danger mt-4' onClick={()=>setOtherFees(fees=>!fees)} > Remove Other Fees</button>
   }else
   {
     nri_button=<div></div>
   }
    }



   const addFeesSubmit=(e)=>
   {
  
    var len= courses_list_total.length;
    var courseid=document.getElementsByClassName('course');
    var govt_fee=document.getElementsByClassName('govt_fee');
    var  management_fee=document.getElementsByClassName('management_fee');
    var  private_fee=document.getElementsByClassName('private_fee');
    var  nri_fee=document.getElementsByClassName('nri_fee');
    
    var token=localStorage.getItem('auth_token');
    var currency=document.getElementById('currency').value;
    
   var year=document.getElementById('cutoff_year').value;
    var course_level=document.getElementById('course_level').value;
   var y=0;
    for( var i=0;i<nri_fee.length;i++){
      if(nri_fee[i].value!==''){
        
       y=y+1;
       
      }
    }
  
var errorcounter=0;

    for(var i=0;i<courseid.length;i++){
    
      if(private_fee[i].value==='' && management_fee[i].value==='' && private_fee[i].value==='' && nri_fee[i].value===''){
      
        errorcounter =errorcounter+1;
                
    
      }
    }
console.log(errorcounter)
console.log(courseid.length)
    if(year==='0')
    {
      setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>  Select Year</div>);
    }else if(course_level==='0')
    {
      setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>  Select Course Level</div>);
    }
    else if(college_id==='0')
    {
      
      setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>  Select College</div>);
    }else if(y>=1 && currency==='0'){
        
      setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> Choose Currency</div>);
    }else if(courseid.length===errorcounter || isNaN(errorcounter)===true )
    {
        
      setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> Enter Atleast One Entry</div>);
       //setTimeout(function(){ window.location.reload() }, 1000);      
    }
    else{

      
      setError(<div></div>);
    }

    if(course_level===1){
    for(i=0;i<govt_fee.length;i++){

      if(govt_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',1);
        formData.append('year',year);
        formData.append('currency',"Rupees");
        formData.append('year1',govt_fee[i].value);
        formData.append('year2',govt_fee[i].value);
        formData.append('year3',govt_fee[i].value);
        formData.append('year4',govt_fee[i].value);
        formData.append('year5',govt_fee[i].value);
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        console.log(response)
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);

        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }

    for( var i=0;i<private_fee.length;i++){
      if(private_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',2);
        formData.append('year',year);
        formData.append('currency',"Rupees");
        formData.append('year1',private_fee[i].value);
        formData.append('year2',private_fee[i].value);
        formData.append('year3',private_fee[i].value);
        formData.append('year4',private_fee[i].value);
        formData.append('year5',private_fee[i].value);
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
          console.log(counter)
        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }

    for( var i=0;i<management_fee.length;i++){
      if(management_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',3);
        formData.append('year',year);
        formData.append('currency',"Rupees");
        formData.append('year1',management_fee[i].value);
        formData.append('year2',management_fee[i].value);
        formData.append('year3',management_fee[i].value);
        formData.append('year4',management_fee[i].value);
        formData.append('year5',management_fee[i].value);
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }
    
    for( var i=0;i<nri_fee.length;i++){
      if(nri_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',4);
        formData.append('year',year);
        formData.append('currency',currency);
        formData.append('year1',nri_fee[i].value);
        formData.append('year2',nri_fee[i].value);
        formData.append('year3',nri_fee[i].value);
        formData.append('year4',nri_fee[i].value);
        formData.append('year5',nri_fee[i].value);
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }
  }else
  {
    for( var i=0;i<govt_fee.length;i++){

      if(govt_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',1);
        formData.append('year',year);
        formData.append('currency',"Rupees");
        formData.append('year1',govt_fee[i].value);
        formData.append('year2',govt_fee[i].value);
        formData.append('year3',govt_fee[i].value);
       
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }

    for( var i=0;i<private_fee.length;i++){
      if(private_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',2);
        formData.append('year',year);
        formData.append('currency',"Rupees");
        formData.append('year1',private_fee[i].value);
        formData.append('year2',private_fee[i].value);
        formData.append('year3',private_fee[i].value);
        
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }

    for( var i=0;i<management_fee.length;i++){
      if(management_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',3);
        formData.append('year',year);
        formData.append('currency',"Rupees");
        formData.append('year1',management_fee[i].value);
        formData.append('year2',management_fee[i].value);
        formData.append('year3',management_fee[i].value);
        
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
        }else if(response.data.code===203){
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
         }else{
           setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
         }
      })
      }
    }
    
    for( var i=0;i<nri_fee.length;i++){
      if(nri_fee[i].value!==''){
        var formData = new FormData();
        formData.append('course_id',courseid[i].value);
        formData.append('college_id',college_id);
        formData.append('fee_category',4);
        formData.append('year',year);
        formData.append('currency',currency);
        formData.append('year1',nri_fee[i].value);
        formData.append('year2',nri_fee[i].value);
        formData.append('year3',nri_fee[i].value);
        
        formData.append('added_by',user);
        

      axios.post('https://api.prod.goocampus.in/api/admin/add_fee',formData,{ headers: { 'Authorization': 'Bearer '+ token } })
      .then((response)=>{
        if(response.data.code===200){
          setError(<div className="alert alert-success mt-5 w-100"><strong>Success!</strong>{response.data.message}</div>);
          
        }else if(response.data.code===203){
         setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong>{response.data.message}</div>);
        }else{
          setError(<div className="alert alert-danger mt-5 w-100"><strong>Error!</strong> {response.data.message} </div>);
        }
      })
      }
    }
  }
 console.log(counter)
    if(counter>0)
    {
      swal({
        title: "Success!",
        text: "Fees Added Successfully",
        icon: "success",
      });
    }

    
  
      

      
 
  
   }

  return(
    
    <div className='row'>
      <div className='col'>
        <input type="hidden" id="added_counter" value="0"/>
         {/* Breadcrumbs and add button container */}
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <nav area-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
              <li className="breadcrumb-item"><Link to="/add_fee">Add All Fees At Once</Link> </li>
            
            </ol>
          </nav>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <h4 className="text-center page_header">Add All Fees At Once </h4>
        </div>
        
      </div>
       {/*  Add new cutoff form  */}
      
       <div className="row mt-5 ml-5">
     
        <div className='col-md-2'>
            <label>Year</label>
            
                {year_select_list}
                
        </div>
        <div className='col-md-2'>
        
        {course_level_list}
        </div>
        <div className='col-md-5'>
          
              {college_select_list}
        </div>
       <div>{nri_button}</div>
       <form className='w-100'   autoComplete="off">
        <div className='col-md-12'>
        
           {total_courses_list}
         
        </div>
        {error}
        
          <div className='text-center'> {(courseempty!==true )?<button type="button" onClick={()=>addFeesSubmit()} className='btn btn-success mt-5'>Add Fees</button>:""}</div>
        </form>
        
       </div>
       
     
        
        {/* <form id="addCourseSubmit"  autoComplete="off">
          {formlist}
        </form> */}
        
 
    
     
      </div>
    </div>
    )
}

export default AddFeeBulk;