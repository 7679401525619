import moment from "moment";

export const formatDate = (
  date,
  showRange = true,
  format = dateFormats.DAY_MONTH_YEAR
) => {
  var publishedAt = date;

  if (date !== " " && date !== undefined) {
    if (showRange) {
      if (date.includes(" ")) {
        publishedAt = date.replace(" ", "T") + "+00:00";
      }

      var today = moment(new Date());
      var published = moment(new Date(getLocalDate(publishedAt)));

      var seconds = today.diff(published, "seconds");
      var minutes = today.diff(published, "minutes");
      var hours = today.diff(published, "hours");
      var days = today.diff(published, "days");

      if (seconds < 60) {
        return "Just now";
      } else if (seconds >= 60 && minutes < 60) {
        return minutes + (minutes === 1 ? " min " : " mins ") + "ago";
      } else if (minutes >= 60 && hours < 24) {
        return hours + (hours === 1 ? " hour " : " hours ") + "ago";
      } else if (hours >= 24 && hours < 120) {
        return days + (days === 1 ? " day " : " days ") + "ago";
      } else if (hours >= 120) {
        return moment(publishedAt).format(format);
      }
    } else {
      return moment(publishedAt).format(format);
    }
  } else {
    return null;
  }
};

export const dateFormats = {
  DAY_MONTH_YEAR: "DD MMM YYYY",
  YEAR_MONTH_DATE: "YYYY-MM-DD",
};

const getLocalDate = (dateTime) => {
  var dt = new Date(dateTime);
  var minutes = dt.getTimezoneOffset();
  dt = new Date(dt.getTime() + minutes * 60000);
  return dt;
};
