import React from 'react';
import './Cities.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewCity } from '../../actions/Cities';
import swal from 'sweetalert';
import $ from 'jquery';

class Cities extends React.Component {
  constructor(props){
    super(props);
    this.state = { state_id : this.props.match.params.state_id , state : this.props.match.params.state };
  }

  onAddCitySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new city?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_city_form'));
        this.props.onAddNewCity( formData);
      }
    });
  }

  render() {

    const { state, state_id } = this.state;

    return (
      <div className="row">
        <div className="col">

          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/states">States</Link></li>
                  <li className="breadcrumb-item"><Link to={ '/cities/' + state + '/' + state_id }>Cities</Link></li>
                  <li className="breadcrumb-item active" area-current="page">Add City</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add City of {state} </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new city form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
            <br/>
                <form id="add_city_form" onSubmit={this.onAddCitySubmit} autoComplete="off">

                    <div className="form-group">
                        <input type="text" name="city_code" placeholder="City Code" className="form-control" required/>
                    </div>

                    <div className="form-group">
                        <input type="text" name="city_name" placeholder="City Name" className="form-control" required/>
                    </div>
                    
                    <input type="hidden" name="city_state" defaultValue={state_id} />
                    
                    <div className="form-group text-center mt-1">
                    <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add City </button>
                    </div>

                </form>

            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate () {
    if(this.props.message)
    {
        $('.form-control').val('');
    }
  }

}

const mapStatesToProps = state => {
  return {
  	isLoading: state.admin.isLoading,
  }
};

const mapActionsRoProps = {
  onAddNewCity : addNewCity,
};

export default connect(mapStatesToProps, mapActionsRoProps) (Cities);
