import React from "react";
import "./Facilities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getFacilityDetails, updateFacility } from "../../actions/Facilities";
import swal from "sweetalert";
import { BASE_URL } from "../../utils/ApiService";

class Facilities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facility: { faci_icon: "" },
      facility_id: this.props.match.params.faci_id,
      images: { faci_icon: "" },
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetFacilityDetails(this.state.facility_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let facility = this.props.facility;
    let images = this.state.images;
    facility[name] = value;

    if (e.target.name === "faci_icon") {
      images[name] = URL.createObjectURL(e.target.files[0]);
      facility[name] = "";
    }

    this.setState({ facility: facility, images: images });
  };

  onUpdateFacilitySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this facility?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_facility_form")
        );
        this.props.onUpdateFacility(this.state.facility_id, formData);
      }
    });
  };

  render() {
    const { facility } = this.props;
    const { images } = this.state;
    let icon_preview = "";

    if (facility && facility.faci_icon) {
      icon_preview = (
        <img
          src={BASE_URL.IMAGE + facility.faci_icon}
          className="img-fluid img-thumbnail"
          alt="Icon"
        />
      );
    } else if (images.faci_icon) {
      icon_preview = (
        <img
          src={images.faci_icon}
          className="img-fluid img-thumbnail"
          alt="Icon"
        />
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/facilities">Facilities</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Facility
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Facility </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/facilities/add_facility">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={this.addNewFacility}
                >
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit facility form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="edit_facility_form"
                onSubmit={this.onUpdateFacilitySubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">Facility Name : </label>
                  <input
                    type="text"
                    name="faci_name"
                    placeholder="Facility Name"
                    value={facility.faci_name ? facility.faci_name : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                    <label className="small_text">Facility Icon : </label>
                    <input
                      type="file"
                      name="faci_icon"
                      placeholder="Facility Icon"
                      onChange={this.onChangeHandler}
                      className="form-control"
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                    {icon_preview}
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Facility
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    facility: state.facility.facility,
  };
};

const mapActionsRoProps = {
  onGetFacilityDetails: getFacilityDetails,
  onUpdateFacility: updateFacility,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Facilities);
