import React from "react";
import "./Routes.css";
import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import $ from "jquery";

import Dashboard from "../Dashboard";
import SrManageDashboard from "../SeniorManagmentDashboard";
import ProjectManagerDashboard from "../ProjectManagerDashboard";
import ManagerDashboard from "../ManagerDashboard";
import SeniorCounsellorDashboard from "../SeniorCouncellorDashboard";
import CounsellorDashboard from "../CounsellorDashboard";

import SuperAdmins from "../SuperAdmins";
import AddSuperAdmin from "../SuperAdmins/AddSuperAdmin";
import EditSuperAdmin from "../SuperAdmins/EditSuperAdmin";

import SeniorManagers from "../SeniorManagers";

import Users from "../Users";
import AddUser from "../Users/AddUser";
import EditUser from "../Users/EditUser";

import Admins from "../Admins";
import AddAdmin from "../Admins/AddAdmin";
import EditAdmin from "../Admins/EditAdmin";

import Callbacks from "../Callbacks";
import EditCallback from "../Callbacks/EditCallback";

import Events from "../Events";
import AddEvent from "../Events/AddEvent";
import EditEvent from "../Events/EditEvent";

import NewsUpdates from "../NewsUpdates";
import AddNews from "../NewsUpdates/AddNews";
import EditNews from "../NewsUpdates/EditNews";
import NewsCategories from "../NewsUpdates/NewsCategories";

import States from "../States";
import AddState from "../States/AddState";
import EditState from "../States/EditState";

import Cities from "../Cities";
import AddCity from "../Cities/AddCity";
import EditCity from "../Cities/EditCity";

import Streams from "../Streams";
import AddStream from "../Streams/AddStream";
import EditStream from "../Streams/EditStream";

import Courses from "../Courses";
import AddCourse from "../Courses/AddCourse";
import EditCourse from "../Courses/EditCourse";
import BulkUploadCourses from "../Courses/BulkUpload";

import Universities from "../Universities";
import AddUniversity from "../Universities/AddUniversity";
import EditUniversity from "../Universities/EditUniversity";
import BulkUploadUniversity from "../Universities/BulkUpload";

import Colleges from "../Colleges";
import AddCollege from "../Colleges/AddCollege";
import EditCollege from "../Colleges/EditCollege";
import BulkUploadCollege from "../Colleges/BulkUpload";

import CollegeCourses from "../CollegeCourses";
import AddCollegeCourse from "../CollegeCourses/AddCollegeCourse";
import EditCollegeCourse from "../CollegeCourses/EditCollegeCourse";
import BulkUploadCollegeCourses from "../CollegeCourses/BulkUpload";

import CourseCutoffs from "../CourseCutoffs";
import AddCourseCutoff from "../CourseCutoffs/AddCourseCutoff";
import BulkAddCutoff from "../CourseCutoffs/BulkAddCutoff";
import EditCourseCutoff from "../CourseCutoffs/EditCourseCutoff";
import BulkUploadCutoffs from "../CourseCutoffs/BulkUploadCutoffs";

import CourseFees from "../CourseFees";
import AddCourseFee from "../CourseFees/AddCourseFee";
import EditCourseFee from "../CourseFees/EditCourseFee";
import BulkUploadCourseFee from "../CourseFees/BulkUpload";
import AddFees from "../CourseFees/AddFees";

import Exams from "../Exams";
import AddExam from "../Exams/AddExam";
import EditExam from "../Exams/EditExam";

import Consultants from "../Consultants";
import AddConsultant from "../Consultants/AddConsultant";
import EditConsultant from "../Consultants/EditConsultant";

import ConsultantColleges from "../ConsultantColleges";
import AddConsultantCollege from "../ConsultantColleges/AddConsultantCollege";
import EditConsultantCollege from "../ConsultantColleges/EditConsultantCollege";

import MemberPlans from "../MemberPlans";
import AddMemberPlan from "../MemberPlans/AddMemberPlan";
import EditMemberPlan from "../MemberPlans/EditMemberPlan";
import PlanBenefits from "../MemberPlans/PlanBenefits";

import Coupons from "../Coupons";
import AddCoupon from "../Coupons/AddCoupon";
import EditCoupon from "../Coupons/EditCoupon";

import Facilities from "../Facilities";
import AddFacility from "../Facilities/AddFacility";
import EditFacility from "../Facilities/EditFacility";

import CollegeFacilities from "../CollegeFacilities";
import AddCollegeFacility from "../CollegeFacilities/AddCollegeFacility";

import Authoritites from "../Authorities";
import AddAuthority from "../Authorities/AddAuthority";
import EditAuthority from "../Authorities/EditAuthority";

import Videos from "../Videos";
import AddVideo from "../Videos/AddVideo";
import EditVideo from "../Videos/EditVideo";

import Banners from "../Banners";
import AddBanner from "../Banners/AddBanner";
import EditBanner from "../Banners/EditBanner";

import Notifications from "../Notifications";
import SendNotification from "../Notifications/SendNotification";

import Profile from "../Profile";
import { connect } from "react-redux";

class Routes extends React.Component {
  CloseMenu() {
    $("#admin_menu_navbar").collapse("hide");
  }

  getRedirectRoute = () => {
    const { admin_info } = this.props;
    switch (admin_info.role) {
      case 3:
        return "/dashboard/manager/senior";
      case 4:
        return "/dashboard/manager/project";
      case 5:
        return "/dashboard/manager";
      case 6:
        return "/dashboard/counsellor/senior";
      case 7:
        return "/dashboard/counsellor";
      default:
        return "/dashboard";
    }
  };

  render() {
    const { admin_info } = this.props;

    return (
      <Router>
        <nav className="navbar navbar-light" id="menu_navbar">
          <button
            className="navbar_btn navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#admin_menu_navbar"
            aria-controls="admin_menu_navbar"
            aria-expanded="false"
            aria-label="{{ __('Toggle navigation') }}"
          >
            <img
              src={process.env.PUBLIC_URL + "/menu_button.png"}
              alt="Menu"
              height="30"
            />
          </button>

          <div className="collapse navbar-collapse" id="admin_menu_navbar">
            <button
              type="button"
              className="close close_btn"
              onClick={this.CloseMenu}
            >
              <img
                src={process.env.PUBLIC_URL + "/close_button.png"}
                alt="Close"
                height="30"
              />
            </button>
            <ul className="navbar-nav sticky ml-auto pt-4 pb-5">
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/"
                >
                  {"Dashboard"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/users"
                >
                  {"Users"}
                </NavLink>
              </li>
              {admin_info.role == 1 && (
                <li>
                  <NavLink
                    className="nav-link"
                    activeClassName="link_active"
                    onClick={this.CloseMenu}
                    exact
                    to="/superadmins"
                  >
                    {"GC_Team"}
                  </NavLink>
                </li>
              )}
              {admin_info.role == 1 && (
                <>
                  {/* <li>
                    <NavLink
                      className="nav-link"
                      activeClassName="link_active"
                      onClick={this.CloseMenu}
                      exact
                      to="/admins"
                    >
                      {"GC_USERS"}
                    </NavLink>
                  </li> */}

                  {/* <li>
                    <NavLink
                      className="nav-link"
                      activeClassName="link_active"
                      onClick={this.CloseMenu}
                      exact
                      to="/senior_managers"
                    >
                      {"Sr Management"}
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      className="nav-link"
                      activeClassName="link_active"
                      onClick={this.CloseMenu}
                      exact
                      to="/notifications"
                    >
                      {"Notifications"}
                    </NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/callbacks"
                >
                  {"Callbacks"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/events"
                >
                  {"Events"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/news_updates"
                >
                  {"News Updates"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/videos"
                >
                  {"Videos"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/banners"
                >
                  {"App Banners"}
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/states"
                >
                  {"States"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/streams"
                >
                  {"Streams"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/universities"
                >
                  {"Universities"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/colleges"
                >
                  {"Colleges"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/cutoffs"
                >
                  {"Cutoffs"}
                </NavLink>
              </li>
              {/* <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/exams"> Exams </NavLink> </li> */}
              {/* <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/consultants"> Consultants </NavLink> </li> */}
              {admin_info.role == 1 && (
                <li>
                  <NavLink
                    className="nav-link"
                    activeClassName="link_active"
                    onClick={this.CloseMenu}
                    exact
                    to="/member_plans"
                  >
                    {"Member Plans"}
                  </NavLink>
                </li>
              )}
              {admin_info.role == 1 && (
                <li>
                  <NavLink
                    className="nav-link"
                    activeClassName="link_active"
                    onClick={this.CloseMenu}
                    exact
                    to="/coupons"
                  >
                    {"Coupons"}
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/add_fees"
                >
                  {"Add Fees"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/facilities"
                >
                  {"Facilities"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/authorities"
                >
                  {"Authorities"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="link_active"
                  onClick={this.CloseMenu}
                  exact
                  to="/profile"
                >
                  {"Profile"}
                </NavLink>
              </li>
              <br />
              <br />
              <br />
            </ul>
          </div>
        </nav>

        <Switch>
          <Route exact path="/">
            <Redirect exact to={this.getRedirectRoute()} />
          </Route>
          {/* {admin_info.role == 1 ||
            (admin_info.role == 2 && ( */}
          <Route exact path="/dashboard" component={Dashboard} />
          {/* ))}
          {admin_info.role == 3 && ( */}
          <Route
            exact
            path="/dashboard/manager/senior"
            component={SrManageDashboard}
          />
          {/* )}
          {admin_info.role == 4 && ( */}
          <Route
            exact
            path="/dashboard/manager/project"
            component={ProjectManagerDashboard}
          />
          {/* )}
          {admin_info.role == 5 && ( */}
          <Route exact path="/dashboard/manager" component={ManagerDashboard} />
          {/* )}
          {admin_info.role == 6 && ( */}
          <Route
            exact
            path="/dashboard/counsellor/senior"
            component={SeniorCounsellorDashboard}
          />
          {/* )}
          {admin_info.role == 7 && ( */}
          <Route
            exact
            path="/dashboard/counsellor"
            component={CounsellorDashboard}
          />
          {/* )} */}
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/add_user" component={AddUser} />
          <Route exact path="/users/edit_user/:user_id" component={EditUser} />

          {admin_info.role == 1 && (
            <Route exact path="/superadmins" component={SuperAdmins} />
          )}
          {admin_info.role == 1 && (
            <Route
              exact
              path="/superadmins/add_super_admin"
              component={AddSuperAdmin}
            />
          )}
          {admin_info.role == 1 && (
            <Route
              exact
              path="/superadmins/edit_super_admin/:super_admin_id"
              component={EditSuperAdmin}
            />
          )}

          {(admin_info.role == 1 || admin_info.role == 2) && (
            <Route exact path="/admins" component={Admins} />
          )}
          {(admin_info.role == 1 || admin_info.role == 2) && (
            <Route exact path="/admins/add_admin" component={AddAdmin} />
          )}
          {(admin_info.role == 1 || admin_info.role == 2) && (
            <Route
              exact
              path="/admins/edit_admin/:admin_id"
              component={EditAdmin}
            />
          )}
          {admin_info.role == 1 && (
            <Route exact path="/senior_managers" component={SeniorManagers} />
          )}
          {admin_info.role == 1 && (
            <Route
              exact
              path="/senior_managers/add_senior_manager"
              component={AddAdmin}
            />
          )}
          {admin_info.role == 1 && (
            <Route
              exact
              path="/senior_managers/edit_senior_manager/:senior_manager_id"
              component={EditAdmin}
            />
          )}

          {admin_info.role == 1 && (
            <Route exact path="/notifications" component={Notifications} />
          )}

          {admin_info.role == 1 && (
            <Route
              exact
              path="/notifications/send"
              component={SendNotification}
            />
          )}

          <Route exact path="/callbacks" component={Callbacks} />
          <Route
            exact
            path="/callbacks/edit_callback/:callback_id"
            component={EditCallback}
          />
          <Route exact path="/events" component={Events} />
          <Route exact path="/events/add_event" component={AddEvent} />
          <Route
            exact
            path="/events/edit_event/:event_id"
            component={EditEvent}
          />
          <Route exact path="/news_updates" component={NewsUpdates} />
          <Route exact path="/news_updates/add_news" component={AddNews} />
          <Route
            exact
            path="/news_updates/edit_news/:news_id"
            component={EditNews}
          />
          <Route
            exact
            path="/news_updates/news_categories"
            component={NewsCategories}
          />
          <Route exact path="/states" component={States} />
          <Route exact path="/states/add_state" component={AddState} />
          <Route
            exact
            path="/states/edit_state/:state_id"
            component={EditState}
          />
          <Route exact path="/cities/:state/:state_id" component={Cities} />
          <Route
            exact
            path="/cities/:state/:state_id/add_city"
            component={AddCity}
          />
          <Route
            exact
            path="/cities/:state/:state_id/edit_city/:city_id"
            component={EditCity}
          />
          <Route exact path="/streams" component={Streams} />
          <Route exact path="/streams/add_stream" component={AddStream} />
          <Route
            exact
            path="/streams/edit_stream/:stream_id"
            component={EditStream}
          />
          <Route exact path="/courses/:stream/:stream_id" component={Courses} />
          <Route
            exact
            path="/courses/:stream/:stream_id/add_course"
            component={AddCourse}
          />
          <Route
            exact
            path="/courses/:stream/:stream_id/edit_course/:course_id"
            component={EditCourse}
          />
          <Route
            exact
            path="/courses/:stream/:stream_id/bulk_upload"
            component={BulkUploadCourses}
          />
          <Route exact path="/universities" component={Universities} />
          <Route
            exact
            path="/universities/add_university"
            component={AddUniversity}
          />
          <Route
            exact
            path="/universities/:state_id/edit_university/:uni_id"
            component={EditUniversity}
          />
          <Route
            exact
            path="/universities/bulk_upload"
            component={BulkUploadUniversity}
          />
          <Route exact path="/colleges" component={Colleges} />
          <Route exact path="/colleges/add_college" component={AddCollege} />
          <Route
            exact
            path="/colleges/:state_id/edit_college/:college_id"
            component={EditCollege}
          />
          <Route
            exact
            path="/colleges/bulk_upload"
            component={BulkUploadCollege}
          />
          <Route exact path="/cutoffs" component={CourseCutoffs} />
          <Route exact path="/cutoffs/add_cutoff" component={AddCourseCutoff} />
          <Route
            exact
            path="/cutoffs/add_bulk_cutoff"
            component={BulkAddCutoff}
          />
          <Route
            exact
            path="/cutoffs/edit_cutoff/:cutoff_id"
            component={EditCourseCutoff}
          />
          <Route
            exact
            path="/cutoffs/bulksupload"
            component={BulkUploadCutoffs}
          />
          <Route
            exact
            path="/college_courses/:college/:college_id"
            component={CollegeCourses}
          />
          <Route
            exact
            path="/college_courses/:college/:college_id/add_col_course"
            component={AddCollegeCourse}
          />
          <Route
            exact
            path="/college_courses/:college/:college_id/:stream_id/edit_col_course/:col_course_id"
            component={EditCollegeCourse}
          />
          <Route
            exact
            path="/college_courses/:college/:college_id/bulk_upload"
            component={BulkUploadCollegeCourses}
          />
          <Route
            exact
            path="/course_fees/:college/:college_id/:course/:col_course_id"
            component={CourseFees}
          />
          <Route
            exact
            path="/course_fees/:college/:college_id/:course/:col_course_id/add_course_fee"
            component={AddCourseFee}
          />
          <Route
            exact
            path="/course_fees/:college/:college_id/:course/:col_course_id/edit_course_fee/:course_fee_id"
            component={EditCourseFee}
          />
          <Route
            exact
            path="/course_fees/:college/:college_id/:course/:col_course_id/bulk_upload"
            component={BulkUploadCourseFee}
          />
          <Route exact path="/add_fees/" component={AddFees} />
          <Route exact path="/exams" component={Exams} />
          <Route exact path="/exams/add_exam" component={AddExam} />
          <Route exact path="/exams/edit_exam/:exam_id" component={EditExam} />

          <Route exact path="/videos" component={Videos} />
          <Route exact path="/videos/add_video" component={AddVideo} />
          <Route
            exact
            path="/videos/edit_video/:video_id"
            component={EditVideo}
          />

          <Route exact path="/banners" component={Banners} />
          <Route exact path="/banners/add_banner" component={AddBanner} />
          <Route
            exact
            path="/banners/edit_banner/:banner_id"
            component={EditBanner}
          />

          <Route exact path="/consultants" component={Consultants} />
          <Route
            exact
            path="/consultants/add_consultant"
            component={AddConsultant}
          />
          <Route
            exact
            path="/consultants/edit_consultant/:cons_id"
            component={EditConsultant}
          />
          <Route
            exact
            path="/consultant_colleges/:consultant/:cons_id"
            component={ConsultantColleges}
          />
          <Route
            exact
            path="/consultant_colleges/:consultant/:cons_id/add_college"
            component={AddConsultantCollege}
          />
          <Route
            exact
            path="/consultant_colleges/:consultant/:cons_id/edit_college/:cons_col_id"
            component={EditConsultantCollege}
          />
          <Route exact path="/member_plans" component={MemberPlans} />
          <Route
            exact
            path="/member_plans/add_plan"
            component={AddMemberPlan}
          />
          <Route
            exact
            path="/member_plans/edit_plan/:plan_id"
            component={EditMemberPlan}
          />
          <Route
            exact
            path="/member_plans/plan_benefits"
            component={PlanBenefits}
          />
          <Route exact path="/coupons" component={Coupons} />
          <Route exact path="/coupons/add_coupon" component={AddCoupon} />
          <Route
            exaxt
            path="/coupons/edit_coupon/:coupon_id"
            component={EditCoupon}
          />
          <Route exact path="/facilities" component={Facilities} />
          <Route
            exact
            path="/facilities/add_facility"
            component={AddFacility}
          />
          <Route
            exaxt
            path="/facilities/edit_facility/:faci_id"
            component={EditFacility}
          />
          <Route
            exact
            path="/college_facilities/:college/:college_id"
            component={CollegeFacilities}
          />
          <Route
            exact
            path="/college_facilities/:college/:college_id/add_col_facility"
            component={AddCollegeFacility}
          />
          <Route exact path="/authorities" component={Authoritites} />
          <Route
            exact
            path="/authorities/add_authority"
            component={AddAuthority}
          />
          <Route
            exact
            path="/authorities/edit_authority/:authority_id"
            component={EditAuthority}
          />
          <Route exact path="/profile" component={Profile} />
          {/* Default route when route is not available */}
          <Redirect to={"/"} />
        </Switch>
      </Router>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    admin_info: state.admin.admin_info,
  };
};

export default connect(mapStatesToProps, null)(Routes);
