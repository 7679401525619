import { GET_PLANS_SUCCESS, PLAN_DETAILS_SUCCESS } from '../actions/MemberPlans';

const initialState = {
    member_plans : [],
    member_plan : {}
}

export default function Member_plan_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case PLAN_DETAILS_SUCCESS :
            return Object.assign({}, state, { member_plan : payload.member_plan });

        case GET_PLANS_SUCCESS :
            return Object.assign({}, state, { member_plans : payload.member_plans });

        default :
            return state;
    }
}
