import { GET_COL_COURSES_SUCCESS, GET_COL_COURSE_SUCCESS } from '../actions/CollegeCourses';

const initialState = {
    col_courses : [],
    col_course : {}
}

export default function College_course_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_COL_COURSE_SUCCESS :
            return Object.assign({}, state, { col_course : payload.col_course });

        case GET_COL_COURSES_SUCCESS :
            return Object.assign({}, state, { col_courses : payload.col_courses });

        default :
            return state;
    }
}
