import React from "react";
import "./Universities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewUniversity } from "../../actions/Universities";
import { getDropDownStates } from "../../actions/States";
import { getStateCities } from "../../actions/Cities";
import swal from "sweetalert";

class Universities extends React.Component {
  constructor(props) {
    super(props);
    this.state = { images: { uni_logo: "", uni_image: "" } };
    this.onAddUniversitySubmit = this.onAddUniversitySubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetDropDownStates();
  }

  onAddUniversitySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new university?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("add_uni_form"));
        this.props.onAddNewUniversity(formData);
      }
    });
  };

  onChangeHandler = (e) => {
    const { name } = e.target;
    let images = this.state.images;

    if (e.target.name === "uni_logo" || e.target.name === "uni_image") {
      images[name] = URL.createObjectURL(e.target.files[0]);
    }

    this.setState({ images: images });

    if (e.target.name === "uni_state") {
      this.props.ongetStateCities(e.target.value);
    }
  };

  render() {
    const { images } = this.state;
    let logo_preview = "";
    let banner_preview = "";

    if (images.uni_logo) {
      logo_preview = (
        <img
          src={images.uni_logo}
          className="img-fluid img-thumbnail"
          alt="Logo"
        />
      );
    }

    if (images.uni_image) {
      banner_preview = (
        <img
          src={images.uni_image}
          className="img-fluid img-thumbnail"
          alt="Banner"
        />
      );
    }

    let states_list = [];

    if (
      this.props.states &&
      this.props.states !== "" &&
      this.props.states.length > 0
    ) {
      states_list = this.props.states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.state_name}
        </option>
      ));
    }

    let cities_list = [];

    if (
      this.props.cities &&
      this.props.cities !== "" &&
      this.props.cities.length > 0
    ) {
      cities_list = this.props.cities.map((city) => (
        <option key={city.id} value={city.id}>
          {city.city_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/universities">Universities</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add University
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add University </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/universities/bulk_upload">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={this.bulkUploadUniversities}
                >
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Add new university form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 card p-3">
              <br />

              <form
                id="add_uni_form"
                onSubmit={this.onAddUniversitySubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">University Name * :</label>
                      <input
                        type="text"
                        name="uni_name"
                        placeholder="University Name"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">
                          University Code * :
                        </label>
                        <input
                          type="text"
                          name="uni_code"
                          placeholder="University Code"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">
                          Established Year * :
                        </label>
                        <input
                          type="number"
                          name="uni_established"
                          placeholder="Established Year"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">
                          University Type * :
                        </label>
                        <select
                          name="uni_type"
                          className="form-control"
                          required
                        >
                          <option value="">Select university type</option>
                          <option value="Central">Central</option>
                          <option value="Deemed">Deemed</option>
                          <option value="Government">Government</option>
                          <option value="Private">Private</option>
                          <option value="State">State</option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {/* <label className="small_text"> College Stream * : </label>
                        <select name="col_stream" className="form-control" required>
                          <option value="">Select stream</option>
                          {streams_list}
                        </select> */}
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> State * : </label>
                        <select
                          name="uni_state"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value="">Select state</option>
                          {states_list}
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">City * : </label>
                        <select
                          name="uni_city"
                          className="form-control"
                          required
                        >
                          <option value="">Select city</option>
                          {cities_list}
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Accreditations : </label>
                      <input
                        type="text"
                        name="uni_accrs"
                        placeholder="University Accreditations"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID : </label>
                      <input
                        type="email"
                        name="uni_email"
                        placeholder="University Email"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Phone Number * : </label>
                      <input
                        type="number"
                        name="uni_phone"
                        placeholder="University Phone"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">
                          Popular University :
                        </label>
                        <select name="is_popular" className="form-control">
                          <option value="0"> No </option>
                          <option value="1"> Yes </option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">Status * : </label>
                        <select
                          name="uni_status"
                          className="form-control"
                          required
                        >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Google Map Location :
                      </label>
                      <input
                        type="text"
                        name="uni_map"
                        placeholder="Google map address"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Website : </label>
                      <input
                        type="text"
                        name="uni_website"
                        placeholder="University Website"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Address : </label>
                      <textarea
                        name="uni_address"
                        rows="4"
                        placeholder="University Address"
                        className="form-control"
                      />
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">University Logo : </label>
                        <input
                          type="file"
                          name="uni_logo"
                          placeholder="University Logo"
                          onChange={this.onChangeHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {logo_preview}
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">
                          University Banner :
                        </label>
                        <input
                          type="file"
                          name="uni_image"
                          placeholder="University Banner"
                          onChange={this.onChangeHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {banner_preview}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add University
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    states: state.state.states,
    cities: state.city.cities,
  };
};

const mapActionsRoProps = {
  onGetDropDownStates: getDropDownStates,
  ongetStateCities: getStateCities,
  onAddNewUniversity: addNewUniversity,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Universities);
