import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_COL_FACILITIES_SUCCESS = "GET_COL_FACILITIES_SUCCESS";
export const GET_COL_FACILITY_SUCCESS = "GET_COL_FACILITY_SUCCESS";

export const getAllCollegeFacilities = (college_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_college_facilities/" + college_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(
            onGetCollegeFacilitiesSuccess(response.data.college_facilities)
          );
          dispatch(
            addPaginationValues(
              response.data.total_count,
              response.data.page_count,
              response.data.page_size
            )
          );
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const getCollegeFacilityDetails = (col_facility_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_college_facility_details/" + col_facility_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCollegeFacilitySuccess(response.data.college_facility));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const addNewCollegeFacility = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_college_facility", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCollegeFacility = (col_facility_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_college_facility/" + col_facility_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCollegeFacility = (college_id, col_facility_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_college_facility/" + col_facility_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllCollegeFacilities(college_id));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCollegeFacilitiesSuccess = (data) => {
  return {
    type: GET_COL_FACILITIES_SUCCESS,
    payload: {
      col_facilities: data,
    },
  };
};

export const onGetCollegeFacilitySuccess = (col_facility) => {
  return {
    type: GET_COL_FACILITY_SUCCESS,
    payload: {
      col_facility: col_facility,
    },
  };
};
