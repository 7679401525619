import axios from "axios";

export const BASE_URL = {
  STAGE: "http://127.0.0.1:8000/api/admin/",
  PROD: "https://api.prod.goocampus.in/api/admin/",
  IMAGE: "https://api.prod.goocampus.in/",
  TERMS_CONDITIONS: "https://www.goocampus.in/#/terms_conditions",
  PRIVACY_POLICY: "https://www.goocampus.in/#/privacy_policy",
  YOUTUBE_VIDEO:
    "https://www.youtube.com/embed/{0}?rel=0&autoplay=0&showinfo=0&controls=1",
  YOUTUBE: "https://www.youtube.com/watch?v=",
};

export const Axios = axios.create({
  baseURL: BASE_URL.PROD,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const setAuthToken = (token) => {
  if (token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete Axios.defaults.headers.common["Authorization"];
  }
};
