import React from "react";
import "./Colleges.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllColleges, deleteCollege } from "../../actions/Colleges";
import { getAllStreams } from "../../actions/Streams";

import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "../../actions/Login";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import $ from "jquery";
import { Axios, BASE_URL } from "../../utils/ApiService";

class Colleges extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activePage: 1 };
    this.deleteCollege = this.deleteCollege.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.onGetColleges("");
    this.props.onGetStreams("");
  }

  deleteCollege = (col_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this college permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteCollege(col_id);
      }
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $("#search_filter_form").serialize();
    params += "&page_no=" + pageNumber;
    this.props.onGetColleges("?" + params);
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if (e.target.name === "page_size_changer") {
      $("#page_size").val(e.target.value);
    }
    var params = $("#search_filter_form").serialize();

    this.props.onGetColleges("?" + params);
  };

  handleDownloadExcel = () => {
    swal({
      title: "Are you sure?",
      text: "To download all college courses with fees list?",
      buttons: ["Cancel", "Download"],
    }).then((willDownload) => {
      if (willDownload) {
        this.props.onLoadingTrue();
        Axios.get("/download_college_fees_excel")
          .then((response) => {
            this.props.onLoadingFalse();
            if (response.data.code === 200) {
              // Create anchor tag and download file
              const link = document.createElement("a");
              link.href =
                "https://www.goocampus.in/goocampus_new/storage/app/public/" +
                response.data.file;
              link.download = response.data.file;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              setTimeout(
                this.props.onDisplayMessage(response.data.message),
                3000
              );
            } else this.props.onDisplayError(response.data.error);
          })
          .catch((error) => {
            this.props.onLoadingFalse();
            this.props.onDisplayError(error.message);
          });
      }
    });
  };

  render() {
    const { colleges, states, total_count, page_count, page_size, streams } =
      this.props;

    let colleges_display = {};
    let states_list = [];
    let pagination_display = "";
    let stream_list = [];
    if (streams && streams !== "" && streams.length > 0) {
      stream_list = streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.str_name}
        </option>
      ));
    }

    // College states for filters
    if (states && states !== "" && states.length > 0) {
      states_list = states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.state_name}
        </option>
      ));
    }

    if (colleges && colleges !== "" && colleges.length > 0) {
      colleges_display = colleges.map((college) => (
        <tr key={college.id}>
          <td>{college.id}</td>
          <td>{college.col_name + ", (" + college.state_name + ")"}</td>
          <td>{college.col_code}</td>
          <td>{college.col_established}</td>
          <td>
            <Link
              to={"/college_courses/" + college.col_name + "/" + college.id}
              className="icon_button text-info"
              title="College courses"
            >
              <span className="small_text">
                <b> View ({college.total_courses})</b>
              </span>
            </Link>
          </td>
          <td>
            <Link
              to={"/college_facilities/" + college.col_name + "/" + college.id}
              className="icon_button text-info"
              title="College facilities"
            >
              <span className="small_text">
                <b> View ({college.total_facilities})</b>
              </span>
            </Link>
          </td>
          <td>
            {college.col_logo ? (
              <img
                src={BASE_URL.IMAGE + college.col_logo}
                height="30"
                alt="Logo"
              />
            ) : (
              ""
            )}
          </td>
          <td>{college.col_status === 1 ? "Active" : "Inactive"}</td>
          <td>
            {college.is_popular === 1 ? (
              <span className="fa fa-check text-success"></span>
            ) : (
              <span className="fa fa-times text-danger"></span>
            )}
          </td>
          {this.props.admin_info.role == 1 ? <td>{college.first_name}</td> : ""}
          {this.props.admin_info.role == 1 && (
            <td>
              <Link
                to={
                  "/colleges/" +
                  college.col_state +
                  "/edit_college/" +
                  college.id
                }
              >
                <button className="icon_button" title="Edit">
                  <i className="fa fa-edit text-primary"></i>
                </button>
              </Link>
            </td>
          )}
          {this.props.admin_info.role == 1 && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.deleteCollege(college.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      colleges_display = (
        <tr>
          <td colSpan="11" className="text-center">
            No colleges found.
          </td>
        </tr>
      );
    }

    // Show pagination only if values are more than 10
    if (total_count > 10) {
      pagination_display = (
        <div className="row pt-1">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
            <div className="form-group d-inline">
              <label className="small_text">Page size : </label>
              <select
                name="page_size_changer"
                className="form-control ml-2 w-auto d-inline"
                value={page_size}
                onChange={this.onApplyFilters}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <p className="small_text d-inline ml-2">
              Showing {page_count} of {total_count} colleges.
            </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
            <div className="pagination_container table-responsive">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={page_size}
                totalItemsCount={total_count}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      );
    } else {
      pagination_display = (
        <p className="small_text d-inline ml-2">
          Showing {page_count} of {total_count} colleges.
        </p>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Colleges
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Colleges </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {this.props.admin_info.role == 1 && (
                <button
                  type="button"
                  onClick={() => this.handleDownloadExcel()}
                  className="btn btn-primary btn-sm"
                >
                  Download Fees Excel <i className="ml-1 fa fa-download"></i>
                </button>
              )}
              <Link to="/colleges/bulk_upload">
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link to="/colleges/add_college">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select
                    name="sort_key"
                    className="form-control ml-2 w-auto d-inline"
                    onChange={this.onApplyFilters}
                  >
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Colleges A-Z</option>
                    <option value="4">Colleges Z-A</option>
                    <option value="5">States A-Z</option>
                    <option value="6">Popular</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <input
                  type="hidden"
                  name="page_size"
                  id="page_size"
                  value={page_size}
                />
                <select
                  name="state_id"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All states</option>
                  {states_list}
                </select>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <select
                  name="stream_id"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All Streams</option>
                  {stream_list}
                </select>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-right">
                <div className="input-group mb-3">
                  <input
                    type="search"
                    name="search_key"
                    className="filter_key form-control"
                    placeholder="Search by name, email, phone. city or state"
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <button
                      className="search_btn btn-primary px-3"
                      type="button"
                      onClick={this.onApplyFilters}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* Display all colleges table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">Id</th>
                <th width="47%">College</th>
                <th width="5%">Code</th>
                <th width="5%">Establish</th>
                <th width="10%">Courses</th>
                <th width="10%">Facilities</th>
                <th width="8%">Logo</th>
                <th width="5%">Status</th>
                <th width="5%">Popular</th>
                {this.props.admin_info.role == 1 && (
                  <th width="5%">Added_By</th>
                )}
                {this.props.admin_info.role == 1 && <th width="5%">Edit</th>}
                {this.props.admin_info.role == 1 && <th width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {/* Display all the colleges */}

              {colleges_display}
            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    colleges: state.college.colleges,
    states: state.state.states,
    streams: state.stream.streams,
    total_count: state.paginate.total_count,
    page_count: state.paginate.page_count,
    page_size: state.paginate.page_size,
  };
};

const mapActionsRoProps = {
  onGetColleges: getAllColleges,
  onGetStreams: getAllStreams,
  onDeleteCollege: deleteCollege,
  onLoadingTrue: onLoadingTrue,
  onLoadingFalse: onLoadingFalse,
  onDisplayMessage: onDisplayMessage,
  onDisplayError: onDisplayError,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Colleges);
