import { GET_BENEFITS_SUCCESS, BENEFIT_DETAILS_SUCCESS } from '../actions/PlanBenefits';

const initialState = {
    benefits : []
}

export default function Plan_benefit_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case BENEFIT_DETAILS_SUCCESS :
            return Object.assign({}, state, { member_plan : payload.member_plan });

        case GET_BENEFITS_SUCCESS :
            return Object.assign({}, state, { benefits : payload.benefits });

        default :
            return state;
    }
}
