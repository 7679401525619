import React from "react";
import "./ConsultantColleges.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewConsultantCollege } from "../../actions/ConsultantColleges";
import { getDropDownColleges } from "../../actions/Colleges";
import swal from "sweetalert";

class AddConsultantCollege extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultant: this.props.match.params.consultant,
      cons_id: this.props.match.params.cons_id,
    };
  }

  componentDidMount() {
    this.props.onGetDropDownColleges();
  }

  onAddConsultantCollegeSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new cons_college?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("add_cons_form"));
        this.props.onAddNewConsultantCollege(formData);
      }
    });
  };

  render() {
    const { consultant, cons_id } = this.state;
    const { colleges } = this.props;

    let colleges_list = [];

    if (colleges && colleges.length > 0) {
      colleges_list = colleges.map((college) => (
        <option key={college.id} value={college.id}>
          {college.col_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consultants">Consultants</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/consultant_colleges/" + consultant + "/" + cons_id}
                    >
                      Colleges
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add College
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header">
                Add Consultant College
              </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new cons_college form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="add_cons_form"
                onSubmit={this.onAddConsultantCollegeSubmit}
                autoComplete="off"
              >
                <input
                  type="hidden"
                  name="cons_id"
                  className="form-control"
                  defaultValue={cons_id}
                />

                <div className="form-group">
                  <select name="cons_college" className="form-control" required>
                    <option value=""> Select college </option>
                    {colleges_list}
                  </select>
                </div>

                <div className="form-group">
                  <select name="cons_status" className="form-control">
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add College
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    colleges: state.college.colleges,
  };
};

const mapActionsRoProps = {
  onGetDropDownColleges: getDropDownColleges,
  onAddNewConsultantCollege: addNewConsultantCollege,
};

export default connect(
  mapStatesToProps,
  mapActionsRoProps
)(AddConsultantCollege);
