import React from "react";
import "./Callbacks.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllCallbacks, deleteCallback } from "../../actions/Callbacks";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import $ from "jquery";
import { formatDate } from "../../utils/DateFormat";

class Callbacks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activePage: 1 };
    this.deleteCallback = this.deleteCallback.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.onGetCallbacks("");
  }

  deleteCallback = (cons_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this callback permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteCallback(cons_id);
      }
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $("#search_filter_form").serialize();
    params += "&page_no=" + pageNumber;
    this.props.onGetCallbacks("?" + params);
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if (e.target.name === "page_size_changer") {
      $("#page_size").val(e.target.value);
    }
    var params = $("#search_filter_form").serialize();
    this.props.onGetCallbacks("?" + params);
  };

  render() {
    const { callbacks, total_count, page_count, page_size } = this.props;
    let callbacks_display = {};
    let pagination_display = "";

    if (callbacks && callbacks !== "" && callbacks.length > 0) {
      callbacks_display = callbacks.map((callback) => (
        <tr key={callback.id}>
          <td>{callback.id}</td>
          <td>{callback.name}</td>
          <td>{callback.mobile}</td>
          <td>
            {callback.int_stream !== "" ? callback.int_stream : ""}
            {callback.int_course_level !== ""
              ? " - " + callback.int_course_level
              : ""}
          </td>
          <td>{callback.attended_by}</td>
          <td>
            <span title={callback.created_at}>
              {formatDate(callback.created_at)}
            </span>
          </td>
          {this.props.admin_info.role == 1 && (
            <td>
              <Link to={"/callbacks/edit_callback/" + callback.id}>
                <button className="icon_button" title="Edit">
                  <i className="fa fa-edit text-primary"></i>
                </button>
              </Link>
            </td>
          )}
          {this.props.admin_info.role == 1 && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.deleteCallback(callback.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      callbacks_display = (
        <tr>
          <td colSpan="8" className="text-center">
            No callbacks found.
          </td>
        </tr>
      );
    }

    // Show pagination only if values are more than 10
    if (total_count > 10) {
      pagination_display = (
        <div className="row pt-1">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
            <div className="form-group d-inline">
              <label className="small_text">Page size : </label>
              <select
                name="page_size_changer"
                className="form-control ml-2 w-auto d-inline"
                value={page_size}
                onChange={this.onApplyFilters}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <p className="small_text d-inline ml-2">
              Showing {page_count} of {total_count} callbacks.
            </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
            <div className="pagination_container table-responsive">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={page_size}
                totalItemsCount={total_count}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      );
    } else {
      pagination_display = (
        <p className="small_text d-inline ml-2">
          Showing {page_count} of {total_count} callbacks.
        </p>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Callbacks
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Callbacks </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/* filters, sort and search container */}
          <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select
                    name="sort_key"
                    className="form-control ml-2 w-auto d-inline"
                    onChange={this.onApplyFilters}
                  >
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Name A-Z</option>
                    <option value="4">Name Z-A</option>
                    <option value="5">Attended by A-Z</option>
                    <option value="6">Attended by Z-A</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input
                  type="hidden"
                  name="page_size"
                  id="page_size"
                  value={page_size}
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input
                    type="search"
                    name="search_key"
                    className="filter_key form-control"
                    placeholder="Search by name, email, mobile, attended by or country"
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <button
                      className="search_btn btn-primary px-3"
                      type="button"
                      onClick={this.onApplyFilters}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* Display all callbacks table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead width="100%">
              <tr>
                <th width="5%">Id</th>
                <th width="20%">Name</th>
                <th width="20%">Mobile</th>
                <th width="22%">Interested In</th>
                <th width="25%">Attended By</th>
                <th width="20%">Created</th>
                {this.props.admin_info.role == 1 && <th width="5%">Edit</th>}
                {this.props.admin_info.role == 1 && <th width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {/* Display all the callbacks */}
              {callbacks_display}
            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    admin_info: state.admin.admin_info,
    callbacks: state.callback.callbacks,
    total_count: state.paginate.total_count,
    page_count: state.paginate.page_count,
    page_size: state.paginate.page_size,
  };
};

const mapActionsToProps = {
  onGetCallbacks: getAllCallbacks,
  onDeleteCallback: deleteCallback,
};

export default connect(mapStatesToProps, mapActionsToProps)(Callbacks);
