import { GET_STATES_SUCCESS, GET_STATE_SUCCESS } from '../actions/States';

const initialState = {
    states : [],
    state : {}
}

export default function State_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_STATE_SUCCESS :
            return Object.assign({}, state, { state : payload.state });

        case GET_STATES_SUCCESS :
            return Object.assign({}, state, { states : payload.states });

        default :
            return state;
    }
}
