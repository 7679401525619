import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllNotifications,
  deleteNotification,
} from "../../actions/Notifications";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import $ from "jquery";
import { formatDate } from "../../utils/DateFormat";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activePage: 1 };
    this.deleteNotification = this.deleteNotification.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.onGetNotifications("");
  }

  deleteNotification = (notification_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this notification permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteNotification(notification_id);
      }
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $("#search_filter_form").serialize();
    params += "&page_no=" + pageNumber;
    this.props.onGetNotifications("?" + params);
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if (e.target.name === "page_size_changer") {
      $("#page_size").val(e.target.value);
    }
    var params = $("#search_filter_form").serialize();
    this.props.onGetNotifications("?" + params);
  };

  render() {
    const { notifications, total_count, page_count, page_size } = this.props;
    let notifications_display = {};
    let pagination_display = "";

    if (notifications && notifications !== "" && notifications.length > 0) {
      notifications_display = notifications.map((item) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.title}</td>
          <td>{item.message}</td>
          <td>{`${item.stream} - ${item.course_level}`}</td>
          <td>{item.sent_by}</td>
          <td>{formatDate(item.created_at)}</td>
          {this.props.admin_info.role == 1 && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.deleteNotification(item.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      notifications_display = (
        <tr>
          <td colSpan="7" className="text-center">
            No notifications send yet.
          </td>
        </tr>
      );
    }

    // Show pagination only if values are more than 10
    if (total_count > 10) {
      pagination_display = (
        <div className="row pt-1">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
            <div className="form-group d-inline">
              <label className="small_text">Page size : </label>
              <select
                name="page_size_changer"
                className="form-control ml-2 w-auto d-inline"
                value={page_size}
                onChange={this.onApplyFilters}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <p className="small_text d-inline ml-2">
              Showing {page_count} of {total_count} notifications.
            </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
            <div className="pagination_container table-responsive">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={page_size}
                totalItemsCount={total_count}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      );
    } else {
      pagination_display = (
        <p className="small_text d-inline ml-2">
          Showing {page_count} of {total_count} notifications.
        </p>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Notifications
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Notifications </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/notifications/send">
                <button type="button" className="btn btn-primary btn-sm">
                  Send Notification <i className="ml-1 fa fa-bell"></i>
                </button>
              </Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select
                    name="sort_key"
                    className="form-control ml-2 w-auto d-inline"
                    onChange={this.onApplyFilters}
                  >
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Title A-Z</option>
                    <option value="4">Title Z-A</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input
                  type="hidden"
                  name="page_size"
                  id="page_size"
                  value={page_size}
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input
                    type="search"
                    name="search_key"
                    className="filter_key form-control"
                    placeholder="Search by title or message"
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <button
                      className="search_btn btn-primary px-3"
                      type="button"
                      onClick={this.onApplyFilters}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* Display all notifications table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">ID</th>
                <th width="30%">Title</th>
                <th width="30%">Message</th>
                <th width="15%">Sent To</th>
                <th width="10%">Sent By</th>
                <th width="10%">Sent At</th>
                {this.props.admin_info.role == 1 && <th width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {/* Display all the notifications */}
              {notifications_display}
            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    notifications: state.notification.notifications,
    total_count: state.paginate.total_count,
    page_count: state.paginate.page_count,
    page_size: state.paginate.page_size,
  };
};

const mapActionsRoProps = {
  onGetNotifications: getAllNotifications,
  onDeleteNotification: deleteNotification,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Notifications);
