import { GET_DASHBOARD } from '../actions/Dashboard';

const initialState = {
    users : [],
    week_users : [],
    month_users : [],
    month_callbacks : [],
    plans : [],
    consultants : [],
    callbacks : []
};

export default function Admin_dashboard_reducer (state = initialState, { type, payload } ) {
    
    switch(type)
    {
        case GET_DASHBOARD :
            return Object.assign({}, state, { users : payload.users, week_users : payload.week_users, month_users : payload.month_users, month_callbacks : payload.month_callbacks, plans : payload.plans, consultants : payload.consultants, callbacks : payload.callbacks });
        default :
            return state;
    }
}
