import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAdminDetails,
  updateAdmin,
  changeAdminPassword,
} from "../../actions/Admins";
import { onDisplayError } from "../../actions/Login";
import swal from "sweetalert";
import $ from "jquery";

class EditAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { admin_id: this.props.match.params.admin_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetAdminDetails(this.state.admin_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let admin = this.props.admin;
    admin[name] = value;
    this.setState({ admin: admin });
  };

  onUpdateAdminSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this admin?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_admin_form"));
        this.props.onUpdateAdmin(this.state.admin_id, formData);
      }
    });
  };

  onChangePasswordSubmit = (e) => {
    e.preventDefault();
    if ($("#new_password").val().length < 8) {
      this.props.onDisplayErroMessage(
        "Password must have atleast 8 characters"
      );
    } else if ($("#new_password").val() !== $("#confirm_password").val()) {
      this.props.onDisplayErroMessage(
        "Confirm password must be same as password"
      );
    } else {
      swal({
        title: "Are you sure?",
        text: "To update new password?",
        buttons: ["Cancel", "Update"],
      }).then((willDelete) => {
        if (willDelete) {
          var formData = new FormData(
            document.getElementById("change_password_form")
          );
          this.props.onChangePassword(this.state.admin_id, formData);
        }
      });
    }
  };

  render() {
    const { admin } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admins">Admins</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Admin
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Admin </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/admins/add_admin">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit admin form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_admin_form"
                onSubmit={this.onUpdateAdminSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text"> First Name * : </label>
                        <input
                          type="text"
                          name="first_name"
                          placeholder="First Name *"
                          value={admin.first_name ? admin.first_name : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          autoFocus
                          required
                        />
                      </div>

                      <div className="col">
                        <label className="small_text"> Last Name * : </label>
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Last Name *"
                          value={admin.last_name ? admin.last_name : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID * : </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email ID *"
                        value={admin.email ? admin.email : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Mobile Number * : </label>
                      <input
                        type="number"
                        name="phone"
                        placeholder="Mobile Number *"
                        value={admin.phone ? admin.phone : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Admin Type * : </label>
                        <select
                          name="role"
                          value={admin.role ? admin.role : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value=""> Select admin type </option>
                          <option value="1"> SuperAdmin </option>
                          <option value="2"> Admin </option>
                          <option value="3">Sr Management</option>
                          <option value="4">Management</option>
                          <option value="5">Operations Manager</option>
                          <option value="6">Operations Executive</option>
                          <option value="7">Finance Executive</option>
                          <option value="8">Sr Counsellor</option>
                          <option value="9">Counsellor</option>
                          <option value="10">Data Entry Operator</option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status * : </label>
                        <select
                          name="status"
                          value={admin.status ? admin.status : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Admin
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>

          {/*  Change admin password form  */}
          <div className="row add_container form_container mt-5">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <h5 className="text-center page_header text-uppercase">
                Change Password
              </h5>

              <form
                id="change_password_form"
                onSubmit={this.onChangePasswordSubmit}
                autoComplete="off"
              >
                <div className="row mt-2">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> New Password : </label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      placeholder="New Password"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> Confirm Password : </label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Password
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin: state.adminUser.admin,
  };
};

const mapActionsRoProps = {
  onGetAdminDetails: getAdminDetails,
  onDisplayErroMessage: onDisplayError,
  onUpdateAdmin: updateAdmin,
  onChangePassword: changeAdminPassword,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditAdmin);
