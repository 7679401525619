import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_CALLBACKS_SUCCESS = "GET_CALLBACKS_SUCCESS";
export const GET_CALLBACK_SUCCESS = "GET_CALLBACK_SUCCESS";

export const getAllCallbacks = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_callbacks" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCallbacksSuccess(response.data.callbacks));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getCallbackDetails = (callback_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_callback_details/" + callback_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCallbackSuccess(response.data.callback));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const updateCallback = (callback_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_callback/" + callback_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCallback = (callback_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_callback/" + callback_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllCallbacks(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCallbacksSuccess = (data) => {
  return {
    type: GET_CALLBACKS_SUCCESS,
    payload: {
      callbacks: data,
    },
  };
};

export const onGetCallbackSuccess = (callback) => {
  return {
    type: GET_CALLBACK_SUCCESS,
    payload: {
      callback: callback,
    },
  };
};
