import React from 'react';
import './Coupons.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewCoupon } from '../../actions/Coupons';
import swal from 'sweetalert';

class Coupons extends React.Component {
  constructor(props){
    super(props);
    this.onAddCouponSubmit = this.onAddCouponSubmit.bind(this);
  }

  onAddCouponSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new coupon?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_coupon_form'));
        this.props.onAddNewCoupon(formData);
      }
    });
  }

  render() {

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/coupons">Coupons</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Coupon</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Coupon </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new coupon form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="add_coupon_form" onSubmit={this.onAddCouponSubmit} autoComplete="off">
                
                <div className="form-group">
                    <input type="text" name="coupon" placeholder="Coupon Code *" className="form-control" required/>
                </div>

                <div className="form-group">
                    <input type="number" name="discount" placeholder="Discount in % *" className="form-control" required/>
                </div>
                
                <div className="form-group">
                  <input type="number" name="min_amount" placeholder="Minimum Amount *" className="form-control" required/>
                </div>

                <div className="form-group">
                  <select name="status" className="form-control">
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>
        
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add Coupon </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewCoupon : addNewCoupon
};

export default connect(mapStatesToProps, mapActionsRoProps) (Coupons);
