import React from "react";
import "./Coupons.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCouponDetails, updateCoupon } from "../../actions/Coupons";
import swal from "sweetalert";

class Coupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { coupon_id: this.props.match.params.coupon_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetCouponDetails(this.state.coupon_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let coupon = this.props.coupon;
    coupon[name] = value;
    this.setState({ coupon: coupon });
  };

  onUpdateCouponSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this coupon?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_coupon_form")
        );
        this.props.onUpdateCoupon(this.state.coupon_id, formData);
      }
    });
  };

  render() {
    const { coupon } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/coupons">Coupons</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Coupon
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Coupon </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/coupons/add_coupon">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={this.addNewCoupon}
                >
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit coupon form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="edit_coupon_form"
                onSubmit={this.onUpdateCouponSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">Coupon Code * : </label>
                  <input
                    type="text"
                    name="coupon"
                    placeholder="Coupon Code *"
                    value={coupon.coupon ? coupon.coupon : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">Discount in % * : </label>
                  <input
                    type="number"
                    name="discount"
                    placeholder="Discount in % *"
                    value={coupon.discount ? coupon.discount : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">Minimum Amount * : </label>
                  <input
                    type="number"
                    name="min_amount"
                    placeholder="Minimum Amount *"
                    value={coupon.min_amount ? coupon.min_amount : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">Status : </label>
                  <select
                    name="status"
                    value={coupon.status ? coupon.status : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                  >
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Coupon
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    coupon: state.coupon.coupon,
  };
};

const mapActionsRoProps = {
  onGetCouponDetails: getCouponDetails,
  onUpdateCoupon: updateCoupon,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Coupons);
