import { GET_EVENTS_SUCCESS, EVENT_DETAILS_SUCCESS, GET_CATEGORIES_SUCCESS } from '../actions/Events';

const initialState = {
    events : [],
    event : {},
    categories : []
}

export default function Event_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case EVENT_DETAILS_SUCCESS :
            return Object.assign({}, state, { event : payload.event });

        case GET_EVENTS_SUCCESS :
            return Object.assign({}, state, { events : payload.events });

        case GET_CATEGORIES_SUCCESS :
            return Object.assign({}, state, { categories : payload.categories });

        default :
            return state;
    }
}
