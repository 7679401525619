import React from "react";
import "./Authorities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAuthorityDetails,
  updateAuthority,
} from "../../actions/Authorities";
import { getDropDownStates } from "../../actions/States";
import swal from "sweetalert";

class Authorities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authority: {},
      authority_id: this.props.match.params.authority_id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetAuthorityDetails(this.state.authority_id);
    this.props.onGetDropDownStates();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let authority = this.props.authority;
    authority[name] = value;
    this.setState({ authority: authority });
  };

  onUpdateAuthoritySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this authority?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_authority_form")
        );
        this.props.onUpdateAuthority(this.state.authority_id, formData);
      }
    });
  };

  render() {
    const { authority, states } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/authorities">Authorities</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Authority
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Authority </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/authorities/add_authority"}>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={this.addNewAuthority}
                >
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit authority form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_authority_form"
                onSubmit={this.onUpdateAuthoritySubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Authority Name : </label>
                      <input
                        type="text"
                        name="author_name"
                        placeholder="Authority Name"
                        value={
                          authority.author_name ? authority.author_name : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Authority Code : </label>
                      <input
                        type="text"
                        name="author_code"
                        placeholder="Authority Code"
                        value={
                          authority.author_code ? authority.author_code : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Authority Email : </label>
                      <input
                        type="email"
                        name="author_email"
                        placeholder="Authority Email"
                        value={
                          authority.author_email ? authority.author_email : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Authority Contact :</label>
                      <input
                        type="text"
                        name="author_contact"
                        placeholder="Authority Contact"
                        value={
                          authority.author_contact
                            ? authority.author_contact
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Authority State : </label>
                      <select
                        name="author_state"
                        value={
                          authority.author_state ? authority.author_state : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value=""> Select state</option>
                        {states && states.length > 0
                          ? states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.state_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Authority Website :</label>
                      <input
                        type="text"
                        name="author_website"
                        placeholder="Authority Website"
                        value={
                          authority.author_website
                            ? authority.author_website
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Authority Address :</label>
                      <textarea
                        name="author_address"
                        rows="4"
                        placeholder="Authority Address"
                        value={
                          authority.author_address
                            ? authority.author_address
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Authority
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    authority: state.authority.authority,
    states: state.state.states,
  };
};

const mapActionsRoProps = {
  onGetDropDownStates: getDropDownStates,
  onGetAuthorityDetails: getAuthorityDetails,
  onUpdateAuthority: updateAuthority,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Authorities);
