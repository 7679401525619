import React from "react";
import "./Users.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewUser } from "../../actions/Users";
import { onDisplayError } from "../../actions/Login";
import { getDropDownStates } from "../../actions/States";
import { getStateAuthorities } from "../../actions/Authorities";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import { getDropDownMemberPlans } from "../../actions/MemberPlans";
import swal from "sweetalert";
import $ from "jquery";

class AddUser extends React.Component {
  componentDidMount() {
    this.props.onGetDropDownStates();
    this.props.onGetDropDownAuthorities();
    this.props.onGetDropDownStreams();
    this.props.onGetDropDownCourseLevels();
    this.props.onGetDropDownMemberPlans();
  }

  onAddUserSubmit = (e) => {
    e.preventDefault();

    if ($("#password").val().length < 8) {
      this.props.onDisplayErroMessage(
        "Password must have atleast 8 characters"
      );
    } else if ($("#password").val() !== $("#password_confirm").val()) {
      this.props.onDisplayErroMessage(
        "Confirm password must be same as password"
      );
    } else {
      swal({
        title: "Are you sure?",
        text: "To add this new user?",
        buttons: ["Cancel", "Add"],
      }).then((willDelete) => {
        if (willDelete) {
          var formData = new FormData(document.getElementById("add_user_form"));
          this.props.onAddNewUser(formData);
        }
      });
    }
  };

  render() {
    const { states, authorities, streams, levels, plans } = this.props;
    let states_list = [];
    let authorities_list = [];
    let streams_list = [];
    let levels_list = [];
    let plans_list = [];

    if (states && states !== "" && states.length > 0) {
      states_list = states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.state_name}
        </option>
      ));
    }

    if (authorities && authorities !== "" && authorities.length > 0) {
      authorities_list = authorities.map((author) => (
        <option key={author.id} value={author.id}>
          {author.state_name !== ""
            ? author.state_name + " - " + author.author_name
            : author.author_name}
        </option>
      ));
    }

    if (streams && streams !== "" && streams.length > 0) {
      streams_list = streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.name}
        </option>
      ));
    }

    if (levels && levels !== "" && levels.length > 0) {
      levels_list = levels.map((level) => (
        <option key={level.id} value={level.id}>
          {level.level_name}
        </option>
      ));
    }

    if (plans && plans !== "" && plans.length > 0) {
      plans_list = plans.map((plan) => (
        <option key={plan.id} value={plan.id}>
          {plan.plan_name + " => " + plan.plan_price}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/users">Users</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add User
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add User </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new user form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="add_user_form"
                onSubmit={this.onAddUserSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <input
                          type="text"
                          name="fname"
                          placeholder="First Name *"
                          className="form-control"
                          required
                          autoFocus
                        />
                      </div>

                      <div className="col">
                        <input
                          type="text"
                          name="lname"
                          placeholder="Last Name *"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email ID *"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="number"
                        name="mobile"
                        placeholder="Mobile Number *"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <select name="state" className="form-control">
                        <option value="">Select State</option>
                        {states_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="city"
                        placeholder="City Name"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Interested Authorities
                      </label>
                      <select
                        name="int_couns_authors[]"
                        multiple
                        className="form-control"
                      >
                        {authorities_list}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Interested Streams </label>
                      <select
                        name="int_streams[]"
                        multiple
                        className="form-control"
                      >
                        {streams_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <select name="int_course_level" className="form-control">
                        <option value="">Interested Course Level</option>
                        {levels_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <select name="user_plan" className="form-control">
                        <option value=""> Select Membership Plan </option>
                        {plans_list}
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <select
                          name="user_type"
                          className="form-control"
                          required
                        >
                          <option value=""> Select User Type </option>
                          <option value="0"> Unpaid </option>
                          <option value="1"> Paid </option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col form-group">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="col">
                        <input
                          type="password"
                          id="password_confirm"
                          name="password_confirmation"
                          placeholder="Confirm Password"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add User
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    states: state.state.states,
    authorities: state.authority.authorities,
    streams: state.stream.streams,
    levels: state.course.levels,
    plans: state.plan.member_plans,
  };
};

const mapActionsRoProps = {
  onGetDropDownStates: getDropDownStates,
  onGetDropDownAuthorities: getStateAuthorities,
  onGetDropDownStreams: getDropDownStreams,
  onGetDropDownCourseLevels: getDropDownCourseLevels,
  onGetDropDownMemberPlans: getDropDownMemberPlans,
  onAddNewUser: addNewUser,
  onDisplayErroMessage: onDisplayError,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddUser);
