import React from "react";
import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import Admin from "./components/Admin";
import SeniorManagement from "./components/SeniorManagement";
import ProjectManager from "./components/ProjectManager";
import SeniorCounsellor from "./components/SeniorCouncellor";
import Counsellor from "./components/Counsellor";
import Manager from "./components/Manager";
import { connect } from "react-redux";
import AuthRoutes from "./components/Routes";
import Login from "./components/Login";
import { setAuthToken } from "./utils/ApiService";
import { setCurrentUser } from "./actions/Login";

// import $ from 'jquery';

// Disable right click and F12 buttons from inspecting elements
// document.addEventListener('contextmenu', event => event.preventDefault());

// $(document).keydown(function (event) {
//     if (event.keyCode === 123) { // Prevent F12
//         return false;
//     } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) { // Prevent Ctrl+Shift+I
//         return false;
//     }
// });

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isScreenLoading: true };
  }
  componentDidMount() {
    const authToken = localStorage.getItem("auth_token");
    const authUser = JSON.parse(localStorage.getItem("auth_user"));

    if (!!authToken && !!authUser) {
      setAuthToken(authToken);
      this.props.setAuthUser(authUser);
    }
    this.setState({ isScreenLoading: false });
  }

  render() {
    const { isLoading, isAuth } = this.props;

    if (this.state.isScreenLoading) return null; // To avoid screen load before auth token and user set

    return (
      <div className="container-fluid" id="main_container">
        {/* Loading icon display if page is loading */}
        {isLoading && (
          <div className="loader_container">
            <img
              src={process.env.PUBLIC_URL + "/loading_icon.gif"}
              height="100"
              className="img-fluid loader_image"
              alt="Loading . . ."
            />
          </div>
        )}

        {isAuth ? (
          <Admin />
        ) : (
          <Router>
            <Switch>
              <Route path="/" exact component={Login} />
              <Redirect to={"/"} />
            </Switch>
          </Router>
        )}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    isAuth: state.admin.isAuth,
  };
};

const mapActionsToProps = {
  setAuthUser: setCurrentUser,
};

export default connect(mapStatesToProps, mapActionsToProps)(App);
