import React from "react";
import "./CourseFees.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDropDownFeeCategories,
  addNewCourseFee,
} from "../../actions/CourseFees";
import swal from "sweetalert";

class AddCourseFee extends React.Component {
  constructor(props) {
    super(props);
    const { college, college_id, col_course_id, course } = props.match.params;
    this.state = {
      college: college,
      college_id: college_id,
      col_course_id: col_course_id,
      course: course,
    };
  }

  componentDidMount() {
    this.props.getDropDownFeeCategories();
  }

  onAddCourseFeeSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new course fee?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_course_fee_form")
        );

        this.props.onAddNewCourseFee(formData);
      }
    });
  };

  render() {
    let this_year = new Date().getFullYear();
    let all_years = [];
    for (let x = 0; x <= 2; x++) {
      all_years.push(this_year - x);
    }

    let years_list = [];
    if (all_years) {
      years_list = all_years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ));
    }

    const { college, college_id, col_course_id, course } = this.state;
    const { categories, admin_info } = this.props;

    let categories_list = [];

    if (categories && categories !== "" && categories.length > 0) {
      categories_list = categories.map((category) => (
        <option key={category.id} value={category.id}>
          {category.category}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/college_courses/" + college + "/" + college_id}>
                      Courses
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/course_fees/" +
                        college +
                        "/" +
                        college_id +
                        "/" +
                        course +
                        "/" +
                        col_course_id
                      }
                    >
                      Fees
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Fees
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Fees </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={
                  "/course_fees/" +
                  college +
                  "/" +
                  college_id +
                  "/" +
                  course +
                  "/" +
                  col_course_id +
                  "/bulk_upload"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          <h6 className="text-center mt-0 page_header text-capitalize">
            {college} [{course}]
          </h6>

          {/*  Add new course_fee form  */}
          <div className="row add_container form_container mt-4">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card pt-4">
              <form
                id="add_course_fee_form"
                onSubmit={this.onAddCourseFeeSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input
                        type="hidden"
                        name="added_by"
                        value={admin_info.id}
                      />
                      <select name="year" className="form-control" required>
                        {years_list}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <select
                        name="fee_category"
                        className="form-control"
                        required
                      >
                        {categories_list}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <select name="currency" className="form-control" required>
                        <option value="Rupees">Rupees</option>
                        <option value="Dollars">Dollars</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input
                        type="number"
                        min="0"
                        name="year1"
                        placeholder="1st year"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <input
                        type="number"
                        min="0"
                        name="year2"
                        placeholder="2nd year"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <input
                        type="number"
                        min="0"
                        name="year3"
                        placeholder="3rd year"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      {course == "M.B.B.S." ? (
                        <input
                          type="number"
                          min="0"
                          name="year4"
                          placeholder="4th year"
                          className="form-control"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      {course == "M.B.B.S." ? (
                        <input
                          type="number"
                          min="0"
                          name="year5"
                          placeholder="5th year"
                          className="form-control"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  name="college_course"
                  defaultValue={col_course_id}
                />

                <div className="form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Course Fee
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    categories: state.course_fee.categories,
    admin_info: state.admin.admin_info,
  };
};

const mapActionsRoProps = {
  getDropDownFeeCategories: getDropDownFeeCategories,
  onAddNewCourseFee: addNewCourseFee,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddCourseFee);
