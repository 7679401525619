import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_COURSE_FEES_SUCCESS = "GET_COURSE_FEES_SUCCESS";
export const GET_FEE_SUCCESS = "GET_FEE_SUCCESS";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const getAllCourseFees = (col_course_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_college_course_fees/" + col_course_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(
            onGetCourseFeesSuccess(
              response.data.college_course.fees,
              response.data.years,
              response.data.categories
            )
          );
          dispatch(
            addPaginationValues(
              response.data.total_count,
              response.data.page_count,
              response.data.page_size
            )
          );
        } else dispatch(onDisplayError(response.data.error));
      }
    );
  };
};

export const getCourseFeeDetails = (course_fee_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_course_fee_details/" + course_fee_id).then(
      (response) => {
        dispatch(onLoadingFalse());

        if (response.data.code === 200)
          dispatch(onGetCourseFeeSuccess(response.data.course_fee));
        else dispatch(onDisplayError(response.data.error));
      }
    );
  };
};

// Bulk upload api call
export const bulkUploadCourseFees = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/bulk_upload_course_fees", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200)
          dispatch(onDisplayMessage(response.data.message));
        else dispatch(onDisplayError(response.data.error));
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const addNewCourseFee = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/add_new_course_fee", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200)
          dispatch(onDisplayMessage(response.data.message));
        else dispatch(onDisplayError(response.data.error));
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCourseFee = (course_fee_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/update_course_fee/" + course_fee_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200)
          dispatch(onDisplayMessage(response.data.message));
        else dispatch(onDisplayError(response.data.error));
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCourseFee = (col_course_id, course_fee_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.delete("/delete_course_fee/" + course_fee_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
          dispatch(getAllCourseFees(col_course_id));
        } else dispatch(onDisplayError(response.data.error));
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const getDropDownFeeCategories = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_fee_categories")
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200)
          dispatch(onGetCategoriesSuccess(response.data.categories));
        else dispatch(onDisplayError(response.data.error));
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCourseFeesSuccess = (course_fees, years, categories) => {
  return {
    type: GET_COURSE_FEES_SUCCESS,
    payload: {
      course_fees: course_fees,
      years: years,
      categories: categories,
    },
  };
};

export const onGetCourseFeeSuccess = (course_fee) => {
  return {
    type: GET_FEE_SUCCESS,
    payload: {
      course_fee: course_fee,
    },
  };
};

export const onGetCategoriesSuccess = (data) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: {
      categories: data,
    },
  };
};
