import { GET_USERS_SUCCESS, GET_USER_SUCCESS } from '../actions/Users';

const initialState = {
    users : [],
    user : {}
}

export default function User_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_USER_SUCCESS :
            return Object.assign({}, state, { user : payload.user });

        case GET_USERS_SUCCESS :
            return Object.assign({}, state, { users : payload.users });

        default :
            return state;
    }
}
