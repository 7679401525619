import { GET_AUTHORITIES_SUCCESS, GET_AUTHORITY_SUCCESS } from '../actions/Authorities';

const initialState = {
    authorities : [],
    authority : {}
}

export default function Authority_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_AUTHORITY_SUCCESS :
            return Object.assign({}, state, { authority : payload.authority });

        case GET_AUTHORITIES_SUCCESS :
            return Object.assign({}, state, { authorities : payload.authorities });

        default :
            return state;
    }
}
