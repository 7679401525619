import React from 'react';
import './Consultants.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewConsultant } from '../../actions/Consultants';
import swal from 'sweetalert';

class AddConsultant extends React.Component {

  onAddConsultantSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new consultant?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_cons_form'));
        this.props.onAddNewConsultant(formData);
      }
    });
  }

  render() {

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/consultants">Consultants</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Consultant</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Consultant </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">

            </div>
          </div>
          
          {/*  Add new consultant form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_cons_form" onSubmit={this.onAddConsultantSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" name="cons_name" placeholder="Consultant Name" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <input type="email" name="cons_email" placeholder="Consultant Email" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <input type="number" name="cons_mobile" placeholder="Consultant Mobile" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <textarea name="cons_address" rows="4" placeholder="Consultant Address" className="form-control" required/>
                    </div>

                    <div className="row">
                      <div className="col">
                        <input type="number" name="com_percentage" placeholder="Commission Percentage" className="form-control" required/>
                      </div>

                      <div className="col">
                        <input type="number" name="com_amount" placeholder="Commission Amount" className="form-control" required/>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" name="cons_company" placeholder="Consultant Company" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <textarea name="company_address" rows="4" placeholder="Company Address" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <input type="number" name="company_established" placeholder="Company Established" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <select name="cons_verified" className="form-control">
                        <option value="1"> Verified </option>
                        <option value="0"> Not verified </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <select name="cons_status" className="form-control">
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add Consultant </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewConsultant : addNewConsultant
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddConsultant);