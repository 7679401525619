import { GET_COLLEGES_SUCCESS, GET_COLLEGE_SUCCESS } from '../actions/Colleges';

const initialState = {
    colleges : [],
    college : {}
}

export default function College_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_COLLEGE_SUCCESS :
            return Object.assign({}, state, { college : payload.college });

        case GET_COLLEGES_SUCCESS :
            return Object.assign({}, state, { colleges : payload.colleges });

        default :
            return state;
    }
}
