import React from "react";
import "./Events.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getEventDetails, updateEvent } from "../../actions/Events";
import { getDropDownAuthorities } from "../../actions/Authorities";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";

class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { event_id: this.props.match.params.event_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetDropDownAuthorities();
    this.props.onGetEventDetails(this.state.event_id);
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let event = this.props.event;
    event[name] = value;

    this.setState({ event: event });
  };

  onUpdateEventSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this event?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_event_form"));
        var event_streams = document.getElementsByClassName("event_streams");
        var streams = new Array();

        for (let i = 0; i < event_streams.length; i++) {
          if (event_streams[i].checked) {
            streams.push(event_streams[i].value);
          }
        }

        formData.append("event_streams", streams.toString());
        this.props.onUpdateEvent(this.state.event_id, formData);
      }
    });
  };

  _handleFormatDate = (date) => {
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  _handleStartDateChange = (date) => {
    var formattedDate = this._handleFormatDate(date);
    this.setState({ event_starts: formattedDate, event_ends: "" });
  };

  _handleEndDateChange = (date) => {
    var formattedDate = this._handleFormatDate(date);
    this.setState({ event_ends: formattedDate });
  };

  render() {
    const { authorities, streams, levels, event } = this.props;
    // const { event_starts, event_ends } = this.state;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/events">Events</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Event
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Event </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/events/add_event">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit event form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_event_form"
                onSubmit={this.onUpdateEventSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Event Name : </label>
                      <textarea
                        name="event_name"
                        rows="2"
                        placeholder="Event Name"
                        value={event.event_name ? event.event_name : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Event Details : </label>
                      <textarea
                        name="event_details"
                        rows="6"
                        placeholder="Event Details"
                        value={event.event_details ? event.event_details : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Event start date : </label>
                      <input
                        type="date"
                        name="event_starts"
                        placeholder="Event Start Date"
                        value={event.event_starts ? event.event_starts : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                      {/* <Calendar onChange={this._handleStartDateChange} value={event_starts ? new Date(event_starts) : new Date()} minDate={new Date(event_starts)} /> */}
                      {/* <Calendar onChange={this._handleStartDateChange} value={event_starts ? new Date(event.event_starts) : new Date()} /> */}
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">
                        Counselling authority :
                      </label>
                      <select
                        name="event_authority"
                        value={
                          event.event_authority ? event.event_authority : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value=""> Select counselling authority</option>
                        {authorities && authorities.length > 0
                          ? authorities.map((author) => (
                              <option key={author.id} value={author.id}>
                                {author.state_name !== ""
                                  ? author.state_name +
                                    " - " +
                                    author.author_name
                                  : author.author_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Select streams </label>
                      <div className="row">
                        {streams && streams
                          ? streams.map((stream) => (
                              <div
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                                key={stream.id}
                              >
                                <input
                                  type="checkbox"
                                  className="event_streams"
                                  defaultValue={stream.id}
                                  defaultChecked={
                                    event.event_streams
                                      ? event.event_streams.includes(
                                          stream.id.toString()
                                        )
                                      : false
                                  }
                                />
                                <span className="ml-1 text-blue">
                                  {stream.name}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course level : </label>
                      <select
                        name="event_level"
                        value={event.event_level ? event.event_level : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value=""> Select course level</option>
                        {levels && levels.length > 0
                          ? levels.map((level) => (
                              <option key={level.id} value={level.id}>
                                {level.level_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Event status : </label>
                      <select
                        name="event_status"
                        value={event.event_status ? event.event_status : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Event end date : </label>
                      <input
                        type="date"
                        name="event_ends"
                        placeholder="Event End Date"
                        value={event.event_ends ? event.event_ends : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                      {/* <Calendar onChange={this._handleEndDateChange} value={event_ends ? new Date(event_ends) : new Date()} minDate={new Date(event_starts)}  /> */}
                      {/* <Calendar onChange={this._handleEndDateChange} value={event_ends ? new Date(event.event_ends) : new Date()} minDate={new Date(event_starts)} /> */}
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Event
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    authorities: state.authority.authorities,
    streams: state.stream.streams,
    levels: state.course.levels,
    event: state.event.event,
  };
};

const mapActionsRoProps = {
  onGetDropDownAuthorities: getDropDownAuthorities,
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onGetEventDetails: getEventDetails,
  onUpdateEvent: updateEvent,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditEvent);
