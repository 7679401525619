import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";
import { onGetCountriesSuccess } from "./Countries";

export const GET_EXAMS_SUCCESS = "GET_EXAMS_SUCCESS";
export const GET_EXAM_SUCCESS = "GET_EXAM_SUCCESS";

export const getAllExams = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_exams" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetExamsSuccess(response.data.exams));
        dispatch(onGetCountriesSuccess(response.data.countries));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getExamDetails = (exam_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_exam_details/" + exam_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetExamSuccess(response.data.exam));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewExam = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/add_new_exam", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateExam = (exam_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_exam/" + exam_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteExam = (exam_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.delete("/delete_exam/" + exam_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllExams(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetExamsSuccess = (data) => {
  return {
    type: GET_EXAMS_SUCCESS,
    payload: {
      exams: data,
    },
  };
};

export const onGetExamSuccess = (exam) => {
  return {
    type: GET_EXAM_SUCCESS,
    payload: {
      exam: exam,
    },
  };
};
