import React from "react";
import "./MemberPlans.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllMemberPlans, deleteMemberPlan } from "../../actions/MemberPlans";
import swal from "sweetalert";

class MemberPlans extends React.Component {
  constructor(props) {
    super(props);
    this.deleteMemberPlan = this.deleteMemberPlan.bind(this);
  }

  componentDidMount() {
    this.props.onGetMemberPlans();
  }

  deleteMemberPlan = (plan_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this plan permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteMemberPlan(plan_id);
      }
    });
  };

  render() {
    const { member_plans } = this.props;
    let member_plans_display = {};

    if (member_plans && member_plans !== "" && member_plans.length > 0) {
      member_plans_display = member_plans.map((plan) => (
        <tr key={plan.id}>
          <td>{plan.id}</td>
          <td>{plan.plan_name}</td>
          <td>{plan.plan_price}</td>
          <td>{plan.offer_price}</td>
          <td>
            {plan.is_popular === "1" ? (
              <i className="fa fa-check text-success"></i>
            ) : (
              <i className="fa fa-times text-danger"></i>
            )}
          </td>
          <td>{plan.plan_status === "1" ? "Active" : "Inactive"}</td>
          <td>
            <Link to={"/member_plans/edit_plan/" + plan.id}>
              <button className="icon_button" title="Edit">
                <i className="fa fa-edit text-primary"></i>
              </button>
            </Link>
          </td>
          {this.props.admin_info.role == 1 && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.deleteMemberPlan(plan.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      member_plans_display = (
        <tr>
          <td colSpan="6" className="text-center">
            No plans found.
          </td>
        </tr>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Plans
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Membership Plans </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/member_plans/plan_benefits">
                <button type="button" className="btn btn-primary btn-sm">
                  Benefits <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link to="/member_plans/add_plan">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/* Display all member_plans table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">Id</th>
                <th width="45%">Plan</th>
                <th width="15%">Price</th>
                <th width="15%">Offer</th>
                <th width="10%">Popular</th>
                <th width="10%">Status</th>
                <th width="5%">Edit</th>
                {this.props.admin_info.role == 1 && <th width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {/* Display all the member_plans */}
              {member_plans_display}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    member_plans: state.plan.member_plans,
  };
};

const mapActionsRoProps = {
  onGetMemberPlans: getAllMemberPlans,
  onDeleteMemberPlan: deleteMemberPlan,
};

export default connect(mapStatesToProps, mapActionsRoProps)(MemberPlans);
