import { GET_CONSULTANTS_SUCCESS, GET_CONSULTANT_SUCCESS } from '../actions/Consultants';

const initialState = {
    consultants : [],
    consultant : {}
}

export default function Consultant_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_CONSULTANT_SUCCESS :
            return Object.assign({}, state, { consultant : payload.consultant });

        case GET_CONSULTANTS_SUCCESS :
            return Object.assign({}, state, { consultants : payload.consultants });

        default :
            return state;
    }
}
