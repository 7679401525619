import React from 'react';
import './Facilities.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewFacility } from '../../actions/Facilities';
import swal from 'sweetalert';

class Facilities extends React.Component {
  constructor(props){
    super(props);
    this.state = { images : { faci_icon : '', faci_banner : ''} };
    this.onAddFacilitySubmit = this.onAddFacilitySubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    let images = this.state.images;
    images[e.target.name] = URL.createObjectURL(e.target.files[0]);
    this.setState({ images : images });
  }

  onAddFacilitySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new facility?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_facility_form'));
        this.props.onAddNewFacility(formData);
      }
    });
  }

  render() {

    const { images } = this.state;
    let ifaci_preview = '';

    if(images.faci_icon){
      ifaci_preview = <img src={images.faci_icon} className="img-fluid img-thumbnail" alt="Icon"/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/facilities">Facilities</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Facility</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Facility </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new facility form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="add_facility_form" onSubmit={this.onAddFacilitySubmit} autoComplete="off">

                <div className="form-group">
                    <input type="text" name="faci_name" placeholder="Facility Name *" className="form-control" required/>
                </div>

                <div className="row form-group">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                    <label className="small_text">Facility Icon * : </label>
                    <input type="file" name="faci_icon" onChange={this.onChangeHandler} className="form-control"/>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {ifaci_preview}
                    </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add Facility </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewFacility : addNewFacility
};

export default connect(mapStatesToProps, mapActionsRoProps) (Facilities);
