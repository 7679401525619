import React from "react";
import "./Streams.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getStreamDetails, updateStream } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";

class EditStream extends React.Component {
  constructor(props) {
    super(props);
    const { stream } = this.props;
    this.state = {
      stream: stream,
      stream_id: this.props.match.params.stream_id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetStreamDetails(this.state.stream_id);
    this.props.onGetCourseLevels();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let stream = this.props.stream;
    stream[name] = value;
    this.setState({ stream: stream });
  };

  onUpdateStreamSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this stream?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_stream_form")
        );
        var course_levels = document.getElementsByClassName("course_levels");
        var levels = new Array();

        for (let i = 0; i < course_levels.length; i++) {
          if (course_levels[i].checked) {
            levels.push(course_levels[i].value);
          }
        }

        formData.append("course_levels", levels.toString());
        this.props.onUpdateStream(this.state.stream_id, formData);
      }
    });
  };

  render() {
    const { stream, levels } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/streams">Streams</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Stream
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Stream </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/streams/add_stream">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit stream form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
              <br />

              <form
                id="edit_stream_form"
                onSubmit={this.onUpdateStreamSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">Stream Name : </label>
                  <input
                    type="text"
                    name="str_name"
                    placeholder="Stream Name"
                    value={stream.str_name ? stream.str_name : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">Status : </label>
                  <select
                    name="str_status"
                    value={stream.str_status ? stream.str_status : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                  >
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="small_text"> Select course levels</label>
                  {levels && levels
                    ? levels.map((level) => (
                        <div key={level.id}>
                          <input
                            type="checkbox"
                            className="course_levels"
                            name="course_levels[]"
                            defaultValue={level.id}
                            defaultChecked={
                              stream.course_levels
                                ? stream.course_levels.includes(
                                    level.id.toString()
                                  )
                                : false
                            }
                          />
                          <span className="ml-1 text-blue">
                            {level.level_name}
                          </span>
                        </div>
                      ))
                    : null}
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Stream
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    stream: state.stream.stream,
    levels: state.course.levels,
  };
};

const mapActionsRoProps = {
  onGetStreamDetails: getStreamDetails,
  onGetCourseLevels: getDropDownCourseLevels,
  onUpdateStream: updateStream,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditStream);
