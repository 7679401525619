import { GET_STREAMS_SUCCESS, GET_STREAM_SUCCESS } from '../actions/Streams';

const initialState = {
    streams : [],
    stream : {}
}

export default function Stream_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_STREAM_SUCCESS :
            return Object.assign({}, state, { stream : payload.stream });

        case GET_STREAMS_SUCCESS :
            return Object.assign({}, state, { streams : payload.streams });

        default :
            return state;
    }
}
