import React from "react";
import "./CollegeFacilities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewCollegeFacility } from "../../actions/CollegeFacilities";
import { getDropDownFacilities } from "../../actions/Facilities";
import swal from "sweetalert";

class AddCollegeFacility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      college_id: this.props.match.params.college_id,
      college: this.props.match.params.college,
    };
  }

  componentDidMount() {
    this.props.onGetDropDownFacilities();
  }

  onAddCollegeFacilitySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new college facility?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_col_facility_form")
        );
        this.props.onAddNewCollegeFacility(formData);
      }
    });
  };

  render() {
    const { facilities } = this.props;
    const { college, college_id } = this.state;

    let facilities_list = [];

    if (facilities && facilities !== "" && facilities.length > 0) {
      facilities_list = facilities.map((facility) => (
        <option key={facility.id} value={facility.id}>
          {facility.faci_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/college_facilities/" + college + "/" + college_id}
                    >
                      Facilities
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Facility
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Facility </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/* <Link to={'/college_facilities/' + college + '/' + college_id + '/bulk_upload/'}><button type="button" className="btn btn-primary btn-sm">Bulk Upload <i className="ml-1 fa fa-plus"></i></button></Link> */}
            </div>
          </div>

          <h6 className="text-center mt-0 page_header text-capitalize">
            Add facility in {college}
          </h6>

          {/*  Add new col_facility form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="add_col_facility_form"
                onSubmit={this.onAddCollegeFacilitySubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <select name="facility_id" className="form-control" required>
                    <option value="">Select facility</option>
                    {facilities_list}
                  </select>
                </div>

                <input
                  type="hidden"
                  name="college_id"
                  defaultValue={college_id}
                  className="form-control"
                />

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add College Facility
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    facilities: state.facility.facilities,
  };
};

const mapActionsRoProps = {
  onGetDropDownFacilities: getDropDownFacilities,
  onAddNewCollegeFacility: addNewCollegeFacility,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddCollegeFacility);
