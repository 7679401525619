import { GET_FACILITIES_SUCCESS, GET_FACILITY_SUCCESS } from '../actions/Facilities';

const initialState = {
    facilities : [],
    facility : {}
}

export default function Facility_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_FACILITY_SUCCESS :
            return Object.assign({}, state, { facility : payload.facility });

        case GET_FACILITIES_SUCCESS :
            return Object.assign({}, state, { facilities : payload.facilities });

        default :
            return state;
    }
}
