import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_AUTHORITIES_SUCCESS = "GET_AUTHORITIES_SUCCESS";
export const GET_AUTHORITY_SUCCESS = "GET_AUTHORITY_SUCCESS";

export const getAllAuthorities = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_authorities?" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetAuthoritiesSuccess(response.data.authorities));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getDropDownAuthorities = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_authorities").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetAuthoritiesSuccess(response.data.authorities));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getStateAuthorities = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_state_authorities").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetAuthoritiesSuccess(response.data.authorities));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getAuthorityDetails = (authority_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_authority_details/" + authority_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetAuthoritySuccess(response.data.authority));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const addNewAuthority = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_authority", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateAuthority = (authority_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/update_authority/" + authority_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteAuthority = (country_id, authority_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_authority/" + authority_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllAuthorities(country_id));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetAuthoritiesSuccess = (authorities) => {
  return {
    type: GET_AUTHORITIES_SUCCESS,
    payload: {
      authorities: authorities,
    },
  };
};

export const onGetAuthoritySuccess = (data) => {
  return {
    type: GET_AUTHORITY_SUCCESS,
    payload: {
      authority: data,
    },
  };
};
