import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";

export const GET_BENEFITS_SUCCESS = "GET_BENEFITS_SUCCESS";
export const BENEFIT_DETAILS_SUCCESS = "BENEFIT_DETAILS_SUCCESS";

export const getAllPlanBenefits = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_all_benefits").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetPlanBenefitsSuccess(response.data.benefits));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getDropDownPlanBenefits = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_dropdown_benefits").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetPlanBenefitsSuccess(response.data.benefits));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewBenefit = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_benefit", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
          dispatch(getAllPlanBenefits());
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateBenefit = (benefit_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_benefit/" + benefit_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
          dispatch(getAllPlanBenefits());
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteBenefit = (benefit_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_benefit/" + benefit_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllPlanBenefits(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetPlanBenefitsSuccess = (data) => {
  return {
    type: GET_BENEFITS_SUCCESS,
    payload: {
      benefits: data,
    },
  };
};
