import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_SENIOR_MANAGERS_SUCCESS = "GET_SENIOR_MANAGERS_SUCCESS";
export const GET_SENIOR_MANAGER_SUCCESS = "GET_SENIOR_MANAGER_SUCCESS";

export const getAllSeniorManagers = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_senior_mangers" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetSeniorManagersSuccess(response.data.senior_managers));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const onGetSeniorManagersSuccess = (admins) => {
  return {
    type: GET_SENIOR_MANAGERS_SUCCESS,
    payload: {
      admins: admins,
    },
  };
};

export const getSeniorManagerDetails = (super_admin_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_superadmin_details/" + super_admin_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetSeniorManagerSuccess(response.data.superadmin));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const onGetSeniorManagerSuccess = (admin) => {
  return {
    type: GET_SENIOR_MANAGER_SUCCESS,
    payload: {
      admin: admin,
    },
  };
};

export const addNewSuperAdmin = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_role_type_user", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateSuperAdmin = (super_admin_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_superadmin/" + super_admin_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const changeSuperAdminPassword = (super_admin_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/change_admin_password/" + super_admin_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteSeniorManager = (superadmin_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_superadmin/" + superadmin_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllSeniorManagers(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};
