import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";
import { onGetStatesSuccess } from "./States";

export const GET_COLLEGES_SUCCESS = "GET_COLLEGES_SUCCESS";
export const GET_COLLEGE_SUCCESS = "GET_COLLEGE_SUCCESS";

export const getAllColleges = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_colleges" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCollegesSuccess(response.data.colleges));
        dispatch(onGetStatesSuccess(response.data.states));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getDropDownColleges = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_colleges").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCollegesSuccess(response.data.colleges));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getCountryColleges = (country_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_country_colleges/" + country_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCollegesSuccess(response.data.colleges));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getCollegeDetails = (college_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_college_details/" + college_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCollegeSuccess(response.data.college));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

// Bulk upload api call
export const bulkUploadColleges = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return (
      Axios.post("/import_colleges", formData)
        //return Axios.post('/bulk_upload_colleges', formData)
        .then((response) => {
          console.log(response);
          dispatch(onLoadingFalse());
          if (response.data.code === 200) {
            dispatch(onDisplayMessage(response.data.message));
          } else {
            dispatch(onDisplayError(response.data.message));
          }
        })
        .catch((error) => {
          dispatch(onLoadingFalse());
          dispatch(onDisplayError("Failed, Try again later"));
        })
    );
  };
};

// Bulk upload college images api call
export const uploadCollegeImages = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/upload_college_images", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const addNewCollege = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_college", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCollege = (college_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_college/" + college_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCollege = (college_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_college/" + college_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllColleges(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCollegesSuccess = (data) => {
  return {
    type: GET_COLLEGES_SUCCESS,
    payload: {
      colleges: data,
    },
  };
};

export const onGetCollegeSuccess = (data) => {
  return {
    type: GET_COLLEGE_SUCCESS,
    payload: {
      college: data,
    },
  };
};
