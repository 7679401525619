import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewAdmin } from '../../actions/Admins';
import { onDisplayError } from '../../actions/Login';
import swal from 'sweetalert';
import $ from 'jquery';

class AddAdmin extends React.Component {

 
  onAddAdminSubmit = (e) => {
    e.preventDefault();

    if($('#password').val().length < 8)
    {
      this.props.onDisplayErroMessage('Password must have atleast 8 characters');
    }
    else if($('#password').val() !== $('#password_confirm').val())
    {
      this.props.onDisplayErroMessage('Confirm password must be same as password');
    }
    else
    {
      swal({
        title: "Are you sure?",
        text: "To add this new admin?",
        buttons: ['Cancel', 'Add'],
      })
      .then(willDelete => {
        if (willDelete) {
          var formData = new FormData(document.getElementById('add_admin_form'));
          this.props.onAddNewAdmin(formData);
        }
      });
    }
  }

  render() {
  const {admin_info}=this.props;
     return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/admins">Admins</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Admin</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Admin </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">

            </div>
          </div>
          
          {/*  Add new admin form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_admin_form" onSubmit={this.onAddAdminSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text"> First Name * : </label>
                        <input type="text" name="first_name" placeholder="First Name *" className="form-control" required autoFocus/>
                      </div>

                      <div className="col">
                        <label className="small_text"> Last Name * : </label>
                        <input type="text" name="last_name" placeholder="Last Name *" className="form-control" required/>
                      </div>
                    </div>

                    <div className="form-group">
                        <label className="small_text"> Email ID * : </label>
                      <input type="email" name="email" placeholder="Email ID *" className="form-control" required/>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                        <label className="small_text"> Mobile Number * : </label>
                      <input type="number" name="phone" placeholder="Mobile Number *" className="form-control" required/>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Admin Type * : </label>
                        <select name="role" className="form-control" required>
                          <option value=""> Select admin type </option>
                          <option value="1"> SuperAdmin </option>
                          <option value="2"> Admin </option>
                          <option value="3">Sr Management</option>
                          <option value="4">Management</option>
                          <option value="5">Operations Manager</option>
                          <option value="6">Operations Executive</option>
                          <option value="7">Finance Executive</option>
                          <option value="8">Sr Counsellor</option>
                          <option value="9">Counsellor</option>
                          <option value="10">Data Entry Operator</option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status * : </label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <input type="hidden" name="is_admin" defaultValue={'1'} /> */}
                <input type="hidden" name="added_by" defaultValue={this.props.admin_info.id} />

                <hr className="my-3" />

                <div className="row">
                  <div className="col form-group">
                    <label className="small_text"> Password * : </label>
                    <input type="password" id="password" name="password" placeholder="Password" className="form-control" required/>
                  </div>

                  <div className="col">
                    <label className="small_text"> Confirm Password * : </label>
                    <input type="password" id="password_confirm" name="password_confirmation" placeholder="Confirm Password" className="form-control" required/>
                  </div>
                </div>

                <div className="form-group text-center mt-3">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add Admin </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading: state.admin.isLoading,
        admin_info: state.admin.admin_info,
    }
};

const mapActionsRoProps = {
    onAddNewAdmin : addNewAdmin,
    onDisplayErroMessage : onDisplayError
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddAdmin);