import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";

export const getAllStateCities = (country_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_country_cities/" + country_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCitiesSuccess(response.data.cities));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getStateCities = (state_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_state_cities_dropdown/" + state_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCitiesSuccess(response.data.cities, 0, 0));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const getCityDetails = (city_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_city_details/" + city_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetCitySuccess(response.data.city));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewCity = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_city", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCity = (city_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    console.log(formData);

    return Axios.post("/update_city/" + city_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCity = (country_id, city_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_city/" + city_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllStateCities(country_id));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCitiesSuccess = (
  cities,
  total_count,
  page_count,
  page_size
) => {
  return {
    type: GET_CITIES_SUCCESS,
    payload: {
      cities: cities,
      total_count: total_count,
      page_count: page_count,
      page_size: page_size,
    },
  };
};

export const onGetCitySuccess = (data) => {
  return {
    type: GET_CITY_SUCCESS,
    payload: {
      city: data,
    },
  };
};
