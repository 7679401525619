import { GET_CATEGORIES_SUCCESS, CATEGORY_DETAILS_SUCCESS } from '../actions/NewsCategories';

const initialState = {
    categories : []
}

export default function News_category_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case CATEGORY_DETAILS_SUCCESS :
            return Object.assign({}, state, { member_plan : payload.member_plan });

        case GET_CATEGORIES_SUCCESS :
            return Object.assign({}, state, { categories : payload.categories });

        default :
            return state;
    }
}
