import React from "react";
import "./CourseFees.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateCourseFee } from "../../actions/CourseFees";
import swal from "sweetalert";
import axios from "axios";
class EditCourseFee extends React.Component {
  constructor(props) {
    super(props);
    const { college, college_id, course, col_course_id, course_fee_id } =
      props.match.params;

    this.state = {
      college: college,
      college_id: college_id,
      col_course_id: col_course_id,
      course: course,
      course_fee_id: course_fee_id,
      course_fee: [],
      categories: [],
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    let token = localStorage.getItem("auth_token");

    axios
      .get(
        `https://api.prod.goocampus.in/api/admin/get_course_fee_details/${this.state.course_fee_id}`,
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        // If request is good...

        this.setState({ course_fee: response.data.course_fee });
      })
      .catch((error) => {
        console.log("error " + error);
      });

    axios
      .get(`https://api.prod.goocampus.in/api/admin/get_fee_categories`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        this.setState({ categories: response.data.categories });
      });
  }

  onChangeHandler = (e) => {
    // const { name, value } = e.target;
    // let course_fee = this.props.course_fee;
    // course_fee[name] = value;
    // this.setState({ course_fee : course_fee});
  };

  onUpdateCourseFeeSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this course fee?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        let token = localStorage.getItem("auth_token");
        var formData = new FormData(
          document.getElementById("edit_course_fee_form")
        );
        // this.props.onUpdateCourseFee(this.state.course_fee_id, formData)
        axios
          .post(
            `https://api.prod.goocampus.in/api/admin/update_course_fee/${this.state.course_fee_id}`,
            formData,
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((response) => {
            swal({
              title: " Update Done !",
              text: response.data.message,
              icon: "success",
            });
          });
      }
    });
  };

  render() {
    let this_year = new Date().getFullYear();
    let all_years = [];
    for (let x = 0; x <= 2; x++) {
      all_years.push(this_year - x);
    }

    let years_list = [];
    if (all_years) {
      years_list = all_years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ));
    }

    const {
      college,
      college_id,
      col_course_id,
      course,
      course_fee,
      categories,
    } = this.state;

    let categories_list = [];

    if (categories && categories !== "" && categories.length > 0) {
      categories_list = categories.map((category) => (
        <option key={category.id} value={category.id}>
          {category.category}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/college_courses/" + college + "/" + college_id}>
                      Courses
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/course_fees/" +
                        college +
                        "/" +
                        college_id +
                        "/" +
                        course +
                        "/" +
                        col_course_id
                      }
                    >
                      Fees
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Fees
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit fees </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={
                  "/course_fees/" +
                  college +
                  "/" +
                  college_id +
                  "/" +
                  course +
                  "/" +
                  col_course_id +
                  "/bulk_upload"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link
                to={
                  "/course_fees/" +
                  college +
                  "/" +
                  college_id +
                  "/" +
                  course +
                  "/" +
                  col_course_id +
                  "/add_course_fee"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          <h6 className="text-center mt-0 page_header text-capitalize">
            {college} [{course}]
          </h6>

          {/*  Edit course_fee form  */}
          <div className="row edit_container form_container mt-4">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card pt-4">
              <form
                id="edit_course_fee_form"
                onSubmit={this.onUpdateCourseFeeSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Year : </label>
                      <select
                        name="year"
                        className="form-control"
                        defaultValue={course_fee.year ? course_fee.year : ""}
                        onChange={this.onChangeHandler}
                        required
                      >
                        {years_list}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Category : </label>
                      <select
                        name="fee_category"
                        className="form-control"
                        defaultValue={
                          course_fee.fee_category ? course_fee.fee_category : ""
                        }
                        onChange={this.onChangeHandler}
                        required
                      >
                        {categories_list}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Currency : </label>
                      <select
                        name="currency"
                        className="form-control"
                        defaultValue={
                          course_fee.currency ? course_fee.currency : ""
                        }
                        onChange={this.onChangeHandler}
                        required
                      >
                        <option value="Rupees">Rupees</option>
                        <option value="Dollars">Dollars</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> 1st year : </label>
                      <input
                        type="number"
                        min="0"
                        name="year1"
                        placeholder="1st year"
                        className="form-control"
                        defaultValue={course_fee.year1 ? course_fee.year1 : ""}
                        onChange={this.onChangeHandler}
                        required
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> 2nd year : </label>
                      <input
                        type="number"
                        min="0"
                        name="year2"
                        placeholder="2nd year"
                        className="form-control"
                        defaultValue={course_fee.year2 ? course_fee.year2 : ""}
                        onChange={this.onChangeHandler}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> 3rd year : </label>
                      <input
                        type="number"
                        min="0"
                        name="year3"
                        placeholder="3rd year"
                        className="form-control"
                        defaultValue={course_fee.year3 ? course_fee.year3 : ""}
                        onChange={this.onChangeHandler}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> 4th year : </label>
                      <input
                        type="number"
                        min="0"
                        name="year4"
                        placeholder="4th year"
                        className="form-control"
                        defaultValue={course_fee.year4 ? course_fee.year4 : ""}
                        onChange={this.onChangeHandler}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> 5th year : </label>
                      <input
                        type="number"
                        min="0"
                        name="year5"
                        placeholder="5th year"
                        className="form-control"
                        defaultValue={course_fee.year5 ? course_fee.year5 : ""}
                        onChange={this.onChangeHandler}
                      />
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  name="college_course"
                  defaultValue={col_course_id}
                />

                <div className="form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update CourseFee
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCourseFee;
