import { Axios, setAuthToken } from "../utils/ApiService";

export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";
export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";
export const ADMIN_LOGOUT_FAILURE = "ADMIN_LOGOUT_FAILURE";
export const DISPLAY_MESSAGE = "DISPLAY_MESSAGE";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

export const onAdminLogin = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/login", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onLoginSuccess(response.data.message));
          dispatch(setCurrentUser(response.data.admin_info));
          localStorage.setItem("auth_token", response.data.auth_token);
          localStorage.setItem(
            "auth_user",
            JSON.stringify(response.data.admin_info)
          );
          setAuthToken(response.data.auth_token);
        } else {
          dispatch(onLoginFaiure(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onLoginFaiure("Failed, Try again later"));
      });
  };
};

export const setCurrentUser = (admin_info) => {
  return {
    type: SET_CURRENT_USER,
    payload: {
      admin_info: admin_info,
    },
  };
};

export const onLoginSuccess = (message) => {
  return {
    type: ADMIN_LOGIN_SUCCESS,
    payload: {
      message: message,
    },
  };
};

export const onLoginFaiure = (error) => {
  return {
    type: ADMIN_LOGIN_FAILURE,
    payload: {
      error: error,
    },
  };
};

export const onLoadingTrue = () => {
  return {
    type: LOADING_TRUE,
  };
};

export const onLoadingFalse = () => {
  return {
    type: LOADING_FALSE,
  };
};

export const onDisplayMessage = (message) => {
  return {
    type: DISPLAY_MESSAGE,
    payload: {
      message: message,
    },
  };
};

export const onDisplayError = (error) => {
  return {
    type: DISPLAY_ERROR,
    payload: {
      error: error,
    },
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const onAdminLogout = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    dispatch(onLogoutSuccess("Logged out"));
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_user");
    setAuthToken("");
    dispatch(onLoadingFalse());

    // return Axios.post('/logout', null)
    // .then(response => {
    // dispatch(onLoadingFalse());
    // if(response.data.code === 200)
    // {
    // dispatch(onLogoutSuccess(response.message));
    // localStorage.removeItem('auth_token');
    // localStorage.removeItem('auth_user');
    // setAuthToken('');
    // }
    // else
    // {
    //     dispatch(onLogoutFailure('Failed, Try again later'));
    // }
    // })
    // .catch(error => {
    //     dispatch(onLoadingFalse());
    //     dispatch(onLogoutFailure('Failed, Try again later'));
    // });
  };
};

export const updateProfile = (admin_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/update_profile/" + admin_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
          dispatch(setCurrentUser(response.data.admin_info));
          localStorage.setItem(
            "auth_user",
            JSON.stringify(response.data.admin_info)
          );
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((err) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, try again later"));
      });
  };
};

export const changePassword = (admin_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/change_password/" + admin_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((err) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, try again later"));
      });
  };
};

export const onLogoutSuccess = (message) => {
  return {
    type: ADMIN_LOGOUT_SUCCESS,
    payload: {
      message: message,
    },
  };
};

export const onLogoutFailure = (error) => {
  return {
    type: ADMIN_LOGOUT_FAILURE,
    payload: {
      error: error,
    },
  };
};
