import { GET_CITIES_SUCCESS, GET_CITY_SUCCESS } from '../actions/Cities';

const initialState = {
    cities : [],
    city : {}
}

export default function City_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_CITY_SUCCESS :
            return Object.assign({}, state, { city : payload.city });

        case GET_CITIES_SUCCESS :
            return Object.assign({}, state, { cities : payload.cities });

        default :
            return state;
    }
}
