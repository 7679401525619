import React from "react";
import "./MemberPlans.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewMemberPlan } from "../../actions/MemberPlans";
import { getDropDownPlanBenefits } from "../../actions/PlanBenefits";
import swal from "sweetalert";

class AddMemberPlan extends React.Component {
  componentDidMount = () => {
    this.props.onGetPlanbenefits();
  };

  onAddMemberPlanSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new plan?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_member_plan_form")
        );
        this.props.onAddNewMemberPlan(formData);
      }
    });
  };

  render() {
    const { benefits } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/member_plans">Plans</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Plan
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Plan </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new member_plan form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-12 card p-3">
              <br />

              <form
                id="add_member_plan_form"
                onSubmit={this.onAddMemberPlanSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12">
                    <div className="form-group">
                      <label className="small_text"> Plan name * : </label>
                      <input
                        type="text"
                        name="plan_name"
                        placeholder="Plan Name *"
                        className="form-control"
                        required
                        autoFocus
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Plan Price * : </label>
                      <input
                        type="number"
                        min="0"
                        max="999999"
                        name="plan_price"
                        placeholder="Plan Price *"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Offer Price : </label>
                      <input
                        type="number"
                        min="0"
                        max="999999"
                        name="offer_price"
                        placeholder="Offer Price"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12">
                    <div className="form-group">
                      <label className="small_text"> Offer Name : </label>
                      <input
                        type="text"
                        name="offer_name"
                        placeholder="Offer Name"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Is popular * : </label>
                      <select
                        name="is_popular"
                        className="form-control"
                        required
                      >
                        <option value="0"> No </option>
                        <option value="1"> Yes </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Status * : </label>
                      <select
                        name="plan_status"
                        className="form-control"
                        required
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr />

                <h4 className="text-center"> Benefits </h4>

                <div className="form-group">
                  <div className="row">
                    {benefits && benefits.length > 0
                      ? benefits.map((benefit) => (
                          <div
                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                            key={benefit.id}
                          >
                            <input
                              type="checkbox"
                              name="plan_benefits[]"
                              defaultValue={benefit.id}
                            />
                            <span className="text-blue ml-1">
                              {benefit.benefit}
                            </span>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Plan
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    benefits: state.benefit.benefits,
  };
};

const mapActionsRoProps = {
  onGetPlanbenefits: getDropDownPlanBenefits,
  onAddNewMemberPlan: addNewMemberPlan,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddMemberPlan);
