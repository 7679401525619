import React from "react";
import "./Authorities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewAuthority } from "../../actions/Authorities";
import { getDropDownStates } from "../../actions/States";
import swal from "sweetalert";
import $ from "jquery";

class Authorities extends React.Component {
  componentDidMount = () => {
    this.props.onGetDropDownStates();
  };

  onAddAuthoritySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new authority?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_authority_form")
        );
        this.props.onAddNewAuthority(formData);
      }
    });
  };

  render() {
    const { states } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/authorities">Authorities</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Authority
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Authority </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new authority form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />
              <form
                id="add_authority_form"
                onSubmit={this.onAddAuthoritySubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="author_name"
                        placeholder="Authority Name"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="author_code"
                        placeholder="Authority Code"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        name="author_email"
                        placeholder="Authority Email"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="author_contact"
                        placeholder="Authority Contact"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <select name="author_state" className="form-control">
                        <option value=""> Select state</option>
                        {states && states.length > 0
                          ? states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.state_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="author_website"
                        placeholder="Authority Website"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        name="author_address"
                        rows="4"
                        placeholder="Authority Address"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Authority
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.message) {
      $(".form-control").val("");
    }
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    states: state.state.states,
  };
};

const mapActionsRoProps = {
  onGetDropDownStates: getDropDownStates,
  onAddNewAuthority: addNewAuthority,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Authorities);
