import { GET_COUNTRIES_SUCCESS, GET_COUNTRY_SUCCESS } from '../actions/Countries';

const initialState = {
    countries : [],
    country : {}
}

export default function Country_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_COUNTRY_SUCCESS :
            return Object.assign({}, state, { country : payload.country });

        case GET_COUNTRIES_SUCCESS :
            return Object.assign({}, state, { countries : payload.countries });

        default :
            return state;
    }
}
