import React from 'react';
import './CourseFees.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bulkUploadCourseFees } from '../../actions/CourseFees';
import swal from 'sweetalert';
const SampleExcel = require('./course_fees_bulk_excel.xlsx');

class BulkUpload extends React.Component {
  constructor(props){
    super(props);
    const {college, college_id, course, col_course_id} = props.match.params;
    this.state = { college : college, college_id : college_id, course : course, col_course_id : col_course_id };
  }

  onBulkUploadSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To upload course fees excel?",
      buttons: ['Cancel', 'Upload'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('fees_bulk_upload_form'));
        this.props.onBulkUploadCourseFees(formData);
      }
    });
  }

  render() {
  
    const { college, college_id, course, col_course_id } = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/colleges">Colleges</Link> </li>
                  <li className="breadcrumb-item"><Link to={'/college_courses/' + college + '/' + college_id}>Courses</Link></li>
                  <li className="breadcrumb-item"><Link to={'/course_fees/' + college + '/' + college_id + '/' + course + '/' + col_course_id}>Fees</Link></li>
                  <li className="breadcrumb-item active" area-current="page">Bulk Upload</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Bulk Upload </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <a href={SampleExcel} download="fees_sample_excel"><button type="button" className="btn btn-primary btn-sm">Download sample <i className="ml-1 fa fa-download"></i></button></a>
              <Link to={ '/course_fees/' + college + '/' + college_id + '/' + course + '/' + col_course_id + '/add_course_fee' }><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
             
            {/* BulkUpload bulk upload form  */}
            <div className="row bulk_container form_container">
                <div className="col"></div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
                <br/>

                    <form id="fees_bulk_upload_form" onSubmit={this.onBulkUploadSubmit} autoComplete="off">

                        <div className="form-group">
                        <label className="small_text"> Fees Excel File : </label>
                        <input type="file" name="course_fees_excel" className="form-control" placeholder="Select Excel File" required/>
                        </div>

                        <div className="form-group text-center mt-1">
                        <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Upload </button>
                        </div>

                    </form>

                </div>
                <div className="col"></div>
            </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading,
  }
};

const mapActionsRoProps = {
    onBulkUploadCourseFees : bulkUploadCourseFees
};

export default connect(mapStatesToProps, mapActionsRoProps) (BulkUpload);
