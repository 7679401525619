import React from "react";
import "./Callbacks.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCallbackDetails, updateCallback } from "../../actions/Callbacks";
import { getDropDownColleges } from "../../actions/Colleges";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";

class EditCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = { callback_id: this.props.match.params.callback_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetCallbackDetails(this.state.callback_id);
    this.props.onGetDropDownColleges();
    this.props.onGetDropDownStreams();
    this.props.onGetDropDownCourseLevels();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let callback = this.props.callback;
    callback[name] = value;
    this.setState({ callback: callback });
  };

  onUpdateCallbackSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this callback?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_cons_form"));
        this.props.onUpdateCallback(this.state.callback_id, formData);
      }
    });
  };

  render() {
    const { callback, colleges, streams, levels } = this.props;
    let colleges_list = [];
    let streams_list = [];
    let levels_list = [];

    if (colleges && colleges !== "" && colleges.length > 0) {
      colleges_list = colleges.map((college) => (
        <option key={college.id} value={college.id}>
          {college.col_name}
        </option>
      ));
    }

    if (streams && streams !== "" && streams.length > 0) {
      streams_list = streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.name}
        </option>
      ));
    }

    if (levels && levels !== "" && levels.length > 0) {
      levels_list = levels.map((level) => (
        <option key={level.id} value={level.id}>
          {level.level_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/callbacks">Callbacks</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Callback
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Callback </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Edit callback form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_cons_form"
                onSubmit={this.onUpdateCallbackSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Name : </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Callback Name"
                        value={callback.name ? callback.name : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email : </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Callback Email"
                        value={callback.email ? callback.email : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Mobile : </label>
                      <input
                        type="number"
                        name="mobile"
                        placeholder="Callback Mobile"
                        value={callback.mobile ? callback.mobile : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Interested Date Time :
                      </label>
                      <input
                        type="text"
                        name="int_date_time"
                        placeholder="Callback Time"
                        value={
                          callback.int_date_time ? callback.int_date_time : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Interested College :</label>
                      <select
                        name="int_college"
                        value={callback.int_college ? callback.int_college : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="">Select college</option>
                        {colleges_list}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">Interested Stream :</label>
                      <select
                        name="int_stream"
                        value={callback.int_stream ? callback.int_stream : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="">Select stream</option>
                        {streams_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Interested Course Level :
                      </label>
                      <select
                        name="int_course_level"
                        value={
                          callback.int_course_level
                            ? callback.int_course_level
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="">Select course level</option>
                        {levels_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Message : </label>
                      <textarea
                        name="message"
                        rows="4"
                        placeholder="Callback Message"
                        value={callback.message ? callback.message : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Attended By : </label>
                      <input
                        type="text"
                        name="attended_by"
                        placeholder="Attended By"
                        value={callback.attended_by ? callback.attended_by : ""}
                        onChange={this.onChangeHandler}
                        className="form-control text-capitalize"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Callback
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    colleges: state.college.colleges,
    streams: state.stream.streams,
    levels: state.course.levels,
    callback: state.callback.callback,
  };
};

const mapActionsRoProps = {
  onGetCallbackDetails: getCallbackDetails,
  onGetDropDownColleges: getDropDownColleges,
  onGetDropDownStreams: getDropDownStreams,
  onGetDropDownCourseLevels: getDropDownCourseLevels,
  onUpdateCallback: updateCallback,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditCallback);
