import React from "react";
import "./Consultants.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getConsultantDetails,
  updateConsultant,
} from "../../actions/Consultants";
import swal from "sweetalert";

class EditConsultant extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cons_id: this.props.match.params.cons_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetConsultantDetails(this.state.cons_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let consultant = this.props.consultant;
    consultant[name] = value;
    this.setState({ consultant: consultant });
  };

  onUpdateConsultantSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this consultant?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_cons_form"));
        this.props.onUpdateConsultant(this.state.cons_id, formData);
      }
    });
  };

  render() {
    const { consultant } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consultants">Consultants</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Consultant
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Consultant </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/consultants/add_consultant">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit consultant form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_cons_form"
                onSubmit={this.onUpdateConsultantSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">Consultant Name : </label>
                      <input
                        type="text"
                        name="cons_name"
                        placeholder="Consultant Name"
                        value={consultant.cons_name ? consultant.cons_name : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Consultant Email : </label>
                      <input
                        type="email"
                        name="cons_email"
                        placeholder="Consultant Email"
                        value={
                          consultant.cons_email ? consultant.cons_email : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Consultant Mobile : </label>
                      <input
                        type="number"
                        name="cons_mobile"
                        placeholder="Consultant Mobile"
                        value={
                          consultant.cons_mobile ? consultant.cons_mobile : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Consultant Address :</label>
                      <textarea
                        name="cons_address"
                        rows="4"
                        placeholder="Consultant Address"
                        value={
                          consultant.cons_address ? consultant.cons_address : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row">
                      <div className="col">
                        <label className="small_text">
                          Commission Percentage :
                        </label>
                        <input
                          type="number"
                          name="com_percentage"
                          placeholder="Commission Percentage"
                          value={
                            consultant.com_percentage
                              ? consultant.com_percentage
                              : ""
                          }
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="col">
                        <label className="small_text">Amount : </label>
                        <input
                          type="number"
                          name="com_amount"
                          placeholder="Commission Amount"
                          value={
                            consultant.com_amount ? consultant.com_amount : ""
                          }
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">Consultant Company :</label>
                      <input
                        type="text"
                        name="cons_company"
                        placeholder="Consultant Company"
                        value={
                          consultant.cons_company ? consultant.cons_company : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Company Address : </label>
                      <textarea
                        name="company_address"
                        rows="4"
                        placeholder="Company Address"
                        value={
                          consultant.company_address
                            ? consultant.company_address
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Company Established :
                      </label>
                      <input
                        type="number"
                        name="company_established"
                        placeholder="Company Established"
                        value={
                          consultant.company_established
                            ? consultant.company_established
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Consultant Verified :
                      </label>
                      <select
                        name="cons_verified"
                        value={
                          consultant.cons_verified
                            ? consultant.cons_verified
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="1"> Verified </option>
                        <option value="0"> Not verified </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Consultant Status : </label>
                      <select
                        name="cons_status"
                        value={
                          consultant.cons_status ? consultant.cons_status : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Consultant
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    consultant: state.consultant.consultant,
  };
};

const mapActionsRoProps = {
  onGetConsultantDetails: getConsultantDetails,
  onUpdateConsultant: updateConsultant,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditConsultant);
