import React from 'react';
import './Streams.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewStream } from '../../actions/Streams';
import { getDropDownCourseLevels } from '../../actions/Courses';
import swal from 'sweetalert';

class AddStream extends React.Component {

  componentDidMount = () => {
    this.props.onGetCourseLevels();
  }

  onAddStreamSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new stream?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_stream_form'));
        var course_levels = document.getElementsByClassName('course_levels');
        var levels = new Array();

        for(let i=0;i<course_levels.length;i++)
        {
          if(course_levels[i].checked)
          {
            levels.push(course_levels[i].value);
          }
        }

        formData.append('course_levels', levels.toString());
        this.props.onAddNewStream(formData);
      }
    });
  }

  render() {

    const { levels } = this.props;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/streams">Streams</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Stream</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Stream </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new stream form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
              <br/>

              <form id="add_stream_form" onSubmit={this.onAddStreamSubmit} autoComplete="off">

                <div className="form-group">
                  <input type="text" name="str_name" placeholder="Stream Name" className="form-control" required/>
                </div>

                <div className="form-group">
                  <select name="str_status" className="form-control">
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="small_text"> Select course levels</label>
                  {
                    levels && levels ?
                      levels.map(level => (
                        <div key={level.id}><input type="checkbox" className="course_levels" name="course_levels[]" defaultValue={level.id} /><span className="ml-1 text-blue"> {level.level_name} </span> </div>
                      ))
                    : null
                  }
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add Stream </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading,
    levels: state.course.levels
  }
};

const mapActionsRoProps = {
  onGetCourseLevels : getDropDownCourseLevels,
  onAddNewStream : addNewStream,
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddStream);
