import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sendNewNotification } from "../../actions/Notifications";
import swal from "sweetalert";

class SendNotification extends React.Component {
  onSendNotificationSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To send this push notification?",
      buttons: ["Cancel", "Send"],
    }).then((response) => {
      if (response) {
        var formData = new FormData(
          document.getElementById("add_notification_form")
        );
        this.props.onSendNewNotification(formData);
      }
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/notifications">Notifications</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Send Notification
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Send Notification </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Send new notification form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-5 card p-3">
              <br />

              <form
                id="add_notification_form"
                onSubmit={this.onSendNotificationSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">{"Title *"}</label>
                  <textarea
                    name="title"
                    rows="2"
                    placeholder="Notification Title"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">{"Message *"}</label>
                  <textarea
                    name="message"
                    rows="4"
                    placeholder="Notification Message"
                    className="form-control"
                    required
                  />
                </div>

                <div className="row">
                  <div className="col form-group">
                    <label className="small_text">{"Send to *"}</label>
                    <select name="send_to" className="form-control" required>
                      <option value="ALL">All Users</option>
                      <option value="MED-UG">Medical UG Users</option>
                      <option value="MED-PG">Medical PG Users</option>
                    </select>
                  </div>

                  <div className="col form-group">
                    <label className="small_text">{"Sent by *"}</label>
                    <input
                      type="text"
                      name="sent_by"
                      placeholder="Sent By"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Send Notification
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
  };
};

const mapActionsRoProps = {
  onSendNewNotification: sendNewNotification,
};

export default connect(mapStatesToProps, mapActionsRoProps)(SendNotification);
