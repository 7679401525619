import { GET_CUTOFFS_SUCCESS, GET_CUTOFF_SUCCESS, GET_FILTERS_SUCCESS ,INSERT_COURSE_SUCCESS} from '../actions/Cutoffs';

const initialState = {
    cutoffs : [],
    cutoff : {},
    courses:[],
    categories: [],
    rounds: [],
    course_levels: [],
    authorities: [],
    years:[],
 
}

export default function Cutoff_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_CUTOFF_SUCCESS :
            return Object.assign({}, state, { cutoff : payload.cutoff });

        case GET_CUTOFFS_SUCCESS :
            return Object.assign({}, state, { cutoffs : payload.cutoffs });

        case GET_FILTERS_SUCCESS :
            return Object.assign({}, state, { colleges:payload.colleges,years:payload.years,categories : payload.categories, courses:payload.courses,rounds : payload.rounds, course_levels : payload.course_levels, quotas:payload.quotas,authorities : payload.authorities });
        case INSERT_COURSE_SUCCESS:
            
            return Object.assign({},state,{courses:payload.courses})
        default :
            return state;
    }
}
