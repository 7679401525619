import React from "react";
import "./MemberPlans.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPlanDetails, updateMemberPlan } from "../../actions/MemberPlans";
import { getDropDownPlanBenefits } from "../../actions/PlanBenefits";
import swal from "sweetalert";
import $ from "jquery";

class EditMemberPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = { plan_id: this.props.match.params.plan_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetPlanDetails(this.state.plan_id);
    this.props.onGetPlanbenefits();
    $(".plan_benefits input[type=checkbox]").prop("checked", false);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let plan = this.props.plan;
    plan[name] = value;
    this.setState({ plan: plan });
  };

  onUpdateMemberPlanSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this plan?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_member_plan_form")
        );
        this.props.onUpdateMemberPlan(this.state.plan_id, formData);
      }
    });
  };

  render() {
    const { plan, benefits } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/member_plans">Plans</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Plan
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Plan </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/member_plans/add_plan">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit plan form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-12 card p-3">
              <br />

              <form
                id="edit_member_plan_form"
                onSubmit={this.onUpdateMemberPlanSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12">
                    <div className="form-group">
                      <label className="small_text">Plan Name * : </label>
                      <input
                        type="text"
                        name="plan_name"
                        placeholder="Plan Name *"
                        value={plan.plan_name ? plan.plan_name : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Plan Price : </label>
                      <input
                        type="text"
                        min="0"
                        max="999999"
                        name="plan_price"
                        placeholder="Plan Price *"
                        value={plan.plan_price ? plan.plan_price : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Offer Price : </label>
                      <input
                        type="number"
                        min="0"
                        max="999999"
                        name="offer_price"
                        placeholder="Offer Price"
                        value={plan.offer_price ? plan.offer_price : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12">
                    <div className="form-group">
                      <label className="small_text">Offer Name : </label>
                      <input
                        type="text"
                        name="offer_name"
                        placeholder="Offer Name"
                        value={plan.offer_name ? plan.offer_name : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Is popular * : </label>
                      <select
                        name="is_popular"
                        value={plan.is_popular ? plan.is_popular : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      >
                        <option value="1"> Yes </option>
                        <option value="0"> No </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Status * : </label>
                      <select
                        name="plan_status"
                        value={plan.plan_status ? plan.plan_status : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr />

                <h4 className="text-center"> Benefits </h4>

                <div className="form-group plan_benefits">
                  <div className="row">
                    {benefits && benefits.length > 0
                      ? benefits.map((benefit) => (
                          <div
                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                            key={benefit.id}
                          >
                            {/* Check checkbox if benefit already added else no */}
                            <input
                              type="checkbox"
                              name="plan_benefits[]"
                              defaultValue={benefit.id}
                              defaultChecked={
                                plan.plan_benefits &&
                                plan.plan_benefits !== "" &&
                                plan.plan_benefits.includes(
                                  benefit.id.toString()
                                )
                                  ? true
                                  : false
                              }
                            />
                            <span className="text-blue ml-1">
                              {benefit.benefit}
                            </span>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>

                <div className="form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Plan
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    plan: state.plan.member_plan,
    benefits: state.benefit.benefits,
  };
};

const mapActionsRoProps = {
  onGetPlanDetails: getPlanDetails,
  onGetPlanbenefits: getDropDownPlanBenefits,
  onUpdateMemberPlan: updateMemberPlan,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditMemberPlan);
