import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";
import { onGetStatesSuccess } from "./States";
import { onGetMemberPlansSuccess } from "./MemberPlans";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const getAllUsers = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_users" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetUsersSuccess(response.data.users));
        dispatch(onGetStatesSuccess(response.data.states));
        dispatch(onGetMemberPlansSuccess(response.data.plans));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getUserDetails = (user_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_user_details/" + user_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetUserSuccess(response.data.user));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewUser = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_user", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateUser = (user_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_user/" + user_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const changeUserPassword = (user_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/change_user_password/" + user_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteUser = (user_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_user/" + user_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllUsers(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: {
      users: users,
    },
  };
};

export const onGetUserSuccess = (user) => {
  return {
    type: GET_USER_SUCCESS,
    payload: {
      user: user,
    },
  };
};
