import { GET_COURSE_FEES_SUCCESS, GET_FEE_SUCCESS, GET_CATEGORIES_SUCCESS } from '../actions/CourseFees';

const initialState = {
    course_fees : [],
    course_fee : {},
    years : [],
    categories : []
}

export default function College_course_reducer (state = initialState, { type, payload }) {
    
    switch (type)
    {
        case GET_FEE_SUCCESS :
          return Object.assign({}, state, { course_fee : payload.course_fee });
        case GET_COURSE_FEES_SUCCESS :
            return Object.assign({}, state, { course_fees : payload.course_fees, years : payload.years, categories : payload.categories });
        case GET_CATEGORIES_SUCCESS:
           return Object.assign({}, state, { categories: payload.categories });

        default :
            return state;
    }
}
