import React from "react";
import "./CourseCutoffs.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {
  getCutoffFilters,
  getCutoffDetails,
  updateCutoff,
} from "../../actions/Cutoffs";
import {
  getAllCollegeCourses,
  deleteCollegeCourse,
} from "../../actions/CollegeCourses";

import swal from "sweetalert";

class EditCourseCutoff extends React.Component {
  constructor(props) {
    super(props);
    const { cutoff_id } = props.match.params;
    this.state = { cutoff_id: cutoff_id, courses: [] };
  }

  componentDidMount() {
    this.props.onGetCutoffFilters();
    this.props.onGetCutoffDetails(this.state.cutoff_id);
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    let cutoff = this.props.cutoff;
    cutoff[name] = value;
    this.setState({ cutoff: cutoff });
  };

  onUpdateCutoffSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this course cutoff?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_cutoff_form")
        );
        this.props.onUpdateCutoff(this.state.cutoff_id, formData);
      }
    });
  };

  render() {
    let count = localStorage.getItem("count");
    if (count == 0) {
      localStorage.setItem("count", 1);
      window.location.reload();
    }
    let this_year = new Date().getFullYear();
    let all_years = [];
    for (let x = 0; x <= 2; x++) {
      all_years.push(this_year - x);
    }

    let years_list = [];
    if (all_years) {
      years_list = all_years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ));
    }

    const { cutoff, categories, authorities, quotas, rounds, col_courses } =
      this.props;
    let college_id = cutoff.cutoff_college;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/cutoffs">Cutoffs</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Cutoff
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit cutoffs </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/cutoffs/add_cutoff"}>
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit cutoff form  */}

          <h4 className="text-success text-center">
            Cutoff Edit for {cutoff.course_name} of {cutoff.college_name}
          </h4>
          <div className="row edit_container form_container mt-4">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card pt-4">
              <form
                id="edit_cutoff_form"
                onSubmit={this.onUpdateCutoffSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Cutoff year : </label>
                      <select
                        name="cutoff_year"
                        className="form-control"
                        value={cutoff.cutoff_year ? cutoff.cutoff_year : ""}
                        onChange={(e) => this.handleOnChange(e)}
                        required
                      >
                        <option value="">Select Year</option>
                        {all_years &&
                          all_years.length > 0 &&
                          all_years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Cutoff category : </label>
                      <select
                        name="cutoff_category"
                        className="form-control"
                        value={
                          cutoff.cutoff_category ? cutoff.cutoff_category : ""
                        }
                        onChange={(e) => this.handleOnChange(e)}
                        required
                      >
                        <option value="">Select Category</option>
                        {categories &&
                          categories.length > 0 &&
                          categories.map((category, index) => (
                            <option key={index} value={category.id}>
                              {category.category}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* <div className="form-group">
                      <label className="small_text">College Courses : </label>
                      <select name="cutoff_course" className="form-control" value={cutoff.cutoff_course ? cutoff.cutoff_course : ''} onChange={(e) => this.handleOnChange(e)} required>
                        <option value="">Select Courses</option>
                        {(courses && courses.length > 0) && courses.map((course, index) => (

                          cutoff.cutoff_course!==course.cutoff_course ?<option key={index} value={course.id}>{(course.course_name !== '' ? (course.course_name) : '') }</option>:""
                        ))
}
                        {(courses && courses.length > 0) && courses.map((course, index) => (

                           cutoff.cutoff_course!==course.cutoff_course ?<option key={index} value={course.id}>{(course.course_name !== '' ? (course.course_name) : '') }</option>:""
                          ))
                        }
                      </select>
                    </div> */}
                    <div className="form-group">
                      <label className="small_text"> Round : </label>
                      <select
                        name="cutoff_round"
                        className="form-control"
                        value={cutoff.cutoff_round ? cutoff.cutoff_round : ""}
                        onChange={(e) => this.handleOnChange(e)}
                        required
                      >
                        <option value="">Select Round</option>
                        {rounds &&
                          rounds.length > 0 &&
                          rounds.map((round, index) => (
                            <option key={index} value={round.id}>
                              {round.round}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Course Quota : </label>
                      <select
                        name="cutoff_quota"
                        className="form-control"
                        value={cutoff.cutoff_quota ? cutoff.cutoff_quota : ""}
                        onChange={(e) => this.handleOnChange(e)}
                        required
                      >
                        <option value="">Select Quota</option>
                        {quotas &&
                          quotas.length > 0 &&
                          quotas.map((level, index) => (
                            <option key={index} value={level.id}>
                              {level.quota}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Cutoff Rank: </label>
                      <br />
                      <input
                        className="form-control"
                        type="number"
                        defaultValue={cutoff.cutoff_rank}
                        name="cutoff_rank"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Cutoff
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    categories: state.cutoff.categories,
    rounds: state.cutoff.rounds,
    quotas: state.cutoff.quotas,
    course_levels: state.cutoff.course_levels,
    authorities: state.cutoff.authorities,
    cutoff: state.cutoff.cutoff,
    col_courses: state.col_course.col_courses,
  };
};

const mapActionsRoProps = {
  onGetCutoffFilters: getCutoffFilters,
  onGetCutoffDetails: getCutoffDetails,
  onUpdateCutoff: updateCutoff,
  onGetCollegeCourses: getAllCollegeCourses,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditCourseCutoff);
