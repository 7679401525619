import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const EVENT_DETAILS_SUCCESS = "EVENT_DETAILS_SUCCESS";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const getAllEvents = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_all_events" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetEventsSuccess(response.data.events));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getEventDetails = (event_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_event_details/" + event_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetEventSuccess(response.data.event));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getEventCategories = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_categories")
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCategoriesSuccess(response.data.categories));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const addNewEvent = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_event", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateEvent = (event_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_event/" + event_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteEvent = (event_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_event/" + event_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllEvents(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetEventsSuccess = (events) => {
  return {
    type: GET_EVENTS_SUCCESS,
    payload: {
      events: events,
    },
  };
};

export const onGetEventSuccess = (event) => {
  return {
    type: EVENT_DETAILS_SUCCESS,
    payload: {
      event: event,
    },
  };
};

export const onGetCategoriesSuccess = (categories) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: {
      categories: categories,
    },
  };
};
