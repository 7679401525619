import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import swal from "sweetalert";

import { newCourses } from "../../actions/Cutoffs";

const BulkAddCutoff = () => {
  const [colleges, setColleges] = useState([]);
  const [college_id, setCollegeID] = useState(0);
  const [state, setState] = useState([]);
  const [year, setYear] = useState(0);
  const [course_level, setCourseLevel] = useState(0);
  const [college_type, setCollegeType] = useState("");
  const [rounds, setRounds] = useState([]);
  const [roundvalue, setRoundValue] = useState(0);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(<div></div>);
  const [course_id, setCourseId] = useState(0);
  const [quota, setQuota] = useState(0);
  let courses_list_total = useSelector((state) => state.cutoff.courses);
  let userid = useSelector((state) => state.admin.admin_info.id);
  const dispatch = useDispatch();

  useEffect(() => {
    loadColleges();
    loadCategories();
    loadRounds();
  }, []);

  const loadRounds = async () => {
    let token = "Bearer " + localStorage.getItem("auth_token");
    await axios
      .get("https://api.prod.goocampus.in/api/admin/get_all_cutoff_rounds", {
        headers: { Authorization: token },
      })
      .then((response) => {
        setRounds(response.data.rounds);
      });
  };

  const loadCategories = async () => {
    let token = "Bearer " + localStorage.getItem("auth_token");
    await axios
      .get(
        "https://api.prod.goocampus.in/api/admin/get_all_cutoff_categories",
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setCategories(response.data.categories);
      });
  };

  const loadColleges = async () => {
    let token = "Bearer " + localStorage.getItem("auth_token");

    await axios
      .get(
        "https://api.prod.goocampus.in/api/admin/get_all_colleges_for_cutoff",
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setColleges(response.data.colleges);
      });
  };
  const loadCourses = async (college_id, course_level) => {
    let token = "Bearer " + localStorage.getItem("auth_token");
    setCollegeID(college_id);

    setCourseId(0);
    await axios
      .get(
        `https://api.prod.goocampus.in/api/admin/get_all_courses_for_cutoff/${college_id}/${course_level}`,
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setCollegeType(response.data.college_type);
        setState(response.data.college_state);
        document.getElementById("course_id").value = 0;
        let coursedata = [];
        let course = response.data.courses;

        if (course.length === 0) {
          document.getElementById("course_id").value = 0;
          setError(
            <div className="alert alert-danger mt-5 w-100">
              <strong>Error!</strong> There are no Courses for this Course level
              in this College,Select Different Course Level or Add the courses
              for this college
            </div>
          );
          $("#course_id").hide();
          $("#course_label").hide();
        } else {
          $("#course_id").show();
          $("#course_label").show();
          dispatch(newCourses(course));
          setError(<div></div>);
        }
      });
  };

  let this_year = new Date().getFullYear() + 1;
  let all_years = [];
  let years_list = [];
  for (let x = 0; x <= 5; x++) {
    all_years.push(this_year - x);
  }

  let rounds_list = [];

  if (rounds && rounds !== "" && rounds.length > 0) {
    rounds_list = rounds.map((round) => (
      <option key={round.id} value={round.id}>
        {round.round}
      </option>
    ));
  }

  let categories_list = [];

  if (categories && categories !== "" && categories.length > 0) {
    categories_list = categories.map((category) => (
      <option key={category.id} value={category.id}>
        {category.category}
      </option>
    ));
  }

  if (all_years) {
    years_list = all_years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  }

  let colleges_list = [];
  if (colleges && colleges !== "" && colleges.length > 0) {
    colleges_list = colleges.map((college) => (
      <option key={college.id} value={college.id}>
        {college.col_name}
      </option>
    ));
  }

  let course_list = [];
  console.log(courses_list_total);
  if (
    courses_list_total &&
    courses_list_total !== "" &&
    courses_list_total.length > 0
  ) {
    course_list = courses_list_total.map((course) => (
      <option key={course.course_id} value={course.course_id}>
        {course.course_name}
      </option>
    ));
  }

  let quota_list = <select></select>;
  if (college_type === "Private") {
    quota_list = (
      <select
        className="form-control"
        onChange={(e) => setQuota(e.target.value)}
        id={"quota"}
      >
        <option value={0}>Select Quota</option>
        <option value={1}>AIQ</option>
        <option value={10}>State Government</option>
        <option value={11}>Private</option>
        <option value={2}>Management</option>
        <option value={3}>NRI</option>
      </select>
    );
  } else if (college_type === "Government") {
    quota_list = (
      <select
        className="form-control"
        onChange={(e) => setQuota(e.target.value)}
        id={"quota"}
      >
        <option value={0}>Select Quota</option>
        <option value={1}>AIQ</option>
        <option value={10}>State Government</option>
      </select>
    );
  } else if (college_type === "Deemed") {
    quota_list = (
      <select
        onChange={(e) => setQuota(e.target.value)}
        className="form-control"
        id={"quota"}
      >
        <option value={0}>Select Quota</option>
        <option value={2}>Management</option>
        <option value={3}>NRI</option>
      </select>
    );
  }

  let category_list_total = (
    <select className="form-control" id="category">
      <option value={0}>Select Category</option>
      {categories_list}
    </select>
  );

  if (parseInt(quota) === 10 || parseInt(quota === 11)) {
    category_list_total = (
      <select className="form-control" id="category">
        <option value={0}>Select Category</option>
        {categories_list}
      </select>
    );
  } else if (parseInt(quota) === 2) {
    category_list_total = (
      <p>
        NA
        <input id="category" type="hidden" value={14} />
      </p>
    );
  } else if (parseInt(quota) === 3) {
    category_list_total = (
      <p>
        NA
        <input id="category" type="hidden" value={15} />
      </p>
    );
  }

  let year_select_list = <select></select>;
  let college_select_list = <select></select>;
  let course_level_list = <select></select>;
  let total_courses_list = <table></table>;

  year_select_list = (
    <select
      className="form-control"
      id="cutoff_year"
      name="cutoff_year"
      onChange={(e) => {
        setYear(e.target.value);
      }}
    >
      <option value="0">Select year</option>
      {years_list}
    </select>
  );

  if (parseInt(year) !== 0) {
    course_level_list = (
      <div>
        <label>Course Level</label>
        <select
          className="form-control"
          id="course_level"
          name="cutoff_course_level"
          onChange={(e) => {
            setCourseLevel(e.target.value);
            loadCourses(college_id, e.target.value);
          }}
          defaultValue={0}
        >
          <option value={0}> --Select Course Level--</option>
          <option value={1}>UG</option>
          <option value={2}>PG</option>
        </select>
      </div>
    );
  } else {
    course_level_list = <div></div>;
  }
  if (parseInt(year) !== 0 && parseInt(course_level) !== 0) {
    college_select_list = (
      <div>
        <label>Colleges</label>
        <select
          className="form-control"
          id="cutoff_college"
          name="cutoff_college"
          onChange={(e) => {
            loadCourses(e.target.value, course_level);
          }}
        >
          <option value="0">
            ----------Select College--------------------
          </option>
          {colleges_list}
        </select>
      </div>
    );
  } else {
    college_select_list = <div></div>;
  }

  if (parseInt(course_level) !== 0 && parseInt(college_id) !== 0) {
    total_courses_list = (
      <div>
        <label id="course_label">Courses </label>
        <select
          className="form-control"
          id="course_id"
          defaultValue={course_id}
          onChange={(e) => {
            setCourseId(e.target.value);
          }}
        >
          <option value={0}>Select Course</option>
          {course_list}
        </select>
      </div>
    );
  } else {
    total_courses_list = <div></div>;
  }
  let formlist = <div></div>;

  if (parseInt(course_id) !== 0) {
    formlist = (
      <div className="row col-md-12 mt-5">
        <hr style={{ backgroundColor: "darkgray", padding: "0.00001% 50%" }} />

        <div className="col-md-2">
          <label>Rounds</label>
          <br />
          <select
            className="form-control"
            id="round"
            onChange={(e) => setRoundValue(e.target.value)}
          >
            <option value={0}>Select Round</option>
            {rounds_list}
          </select>
        </div>
        <div className="col-md-3">
          <label>Quota</label>
          <br />
          {quota_list}
        </div>
        <div className="col-md-3">
          <label>Categories</label>
          <br />
          {category_list_total}
        </div>
        <div className="col-md-2">
          <label>Rank</label>
          <br />
          <input
            type="number"
            id="rank"
            placeholder="Enter the Rank"
            className="form-control"
          />
        </div>
        <div className="col-md-2">
          <div className="text-center mt-3">
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                addCourseSubmit();
              }}
            >
              Add Cutoff
            </button>
          </div>
        </div>
      </div>
    );
  }

  const addCourseSubmit = () => {
    var state_id = state;
    var cutoff_year = parseInt(document.getElementById("cutoff_year").value);
    var cutoff_college = college_id;

    var cutoff_course = course_id;
    var round = roundvalue;

    var category = document.getElementById("category").value;
    var rank = document.getElementById("rank").value;
    let token = localStorage.getItem("auth_token");
    // var coursename = document.getElementById('course_name').value;
    let quota_value = document.getElementById("quota").value;
    console.log(quota_value);
    if (round === 0) {
      setError(
        <div className="alert alert-danger mt-5 w-100">
          <strong>Error!</strong> Please Select Round
        </div>
      );
    } else if (parseInt(quota_value) === 0) {
      setError(
        <div className="alert alert-danger mt-5 w-100">
          <strong>Error!</strong> Please Select Quota
        </div>
      );
    } else if (parseInt(category) === 0) {
      setError(
        <div className="alert alert-danger mt-5 w-100">
          <strong>Error!</strong> Please Select Category
        </div>
      );
    } else if (rank === "" || rank === null || rank === "0") {
      setError(
        <div className="alert alert-danger mt-5  w-100">
          <strong>Error!</strong> Please Enter Rank
        </div>
      );
    } else {
      setError(<div></div>);
      //  if(rank===0)
      //  {

      //  }

      if (parseInt(rank) !== 0 || rank !== "") {
        var formData = new FormData(document.getElementById("addCourseSubmit"));
        formData.append("cutoff_college", cutoff_college);
        formData.append("state_id", state_id);
        formData.append("cutoff_year", cutoff_year);
        formData.append("cutoff_course", cutoff_course);
        formData.append("cutoff_round", round);
        formData.append("cutoff_rank", rank);
        formData.append("cutoff_category", category);
        formData.append("cutoff_quota", quota_value);

        formData.append("cutoff_authority", 0);
        formData.delete("cutoff_course_level");
        formData.append("added_by", parseInt(userid));
        // post=post+1;

        axios
          .post(
            "https://api.prod.goocampus.in/api/admin/add_new_cutoff",
            formData,
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((response) => {
            if (response.data.code === 200) {
              swal({
                title: "Success!",
                text: "Cutoff for all the courses done",
                icon: "success",
              });
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
            }
          });
      } else {
        setError(
          <div className="alert alert-danger mt-5  w-100">
            <strong>Error!</strong> Please Enter Rank greater than zero
          </div>
        );
      }
    }
  };

  return (
    <div className="row">
      <div className="col">
        {/* Breadcrumbs and add button container */}
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <nav area-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/cutoffs">Cutoffs</Link>
                </li>
                <li className="breadcrumb-item active" area-current="page">
                  Add Cutoffs At Once
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <h4 className="text-center page_header"> Add Cutoffs At Once </h4>
          </div>
        </div>
        {/*  Add new cutoff form  */}

        <div className="row mt-5 ml-5">
          <div className="col-md-2">
            <label>Year</label>

            {year_select_list}
          </div>
          <div className="col-md-2">{course_level_list}</div>
          <div className="col-md-5">{college_select_list}</div>

          <div className="col-md-3">{total_courses_list}</div>
          {error}
          <form className="w-100" id="addCourseSubmit" autoComplete="off">
            {formlist}
          </form>
        </div>
      </div>
    </div>
  );
};

export default BulkAddCutoff;
