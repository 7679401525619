import React from "react";
import "./CourseCutoffs.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllAdmins } from "../../actions/Admins";
import {
  getCutoffFilters,
  getAllCourseCutoffs,
  deleteCutoff,
} from "../../actions/Cutoffs";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import $ from "jquery";
import axios from "axios";

class CourseCutoffs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activePage: 1, admin_id: 0, states: [] };

    this.deleteCourseCutoff = this.deleteCourseCutoff.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.onGetCourseCutoffs("");
    this.props.onGetCutoffFilters();
    this.props.onGetAdmins("");
    this.getallStates();
  }

  getallStates = () => {
    let token = "Bearer " + localStorage.getItem("auth_token");
    axios
      .get("https://api.prod.goocampus.in/api/admin/get_dropdown_states", {
        headers: { Authorization: token },
      })
      .then((response) => {
        this.setState({ states: response.data.states });
      });
  };

  deleteCourseCutoff = (cutoff_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this cutoff permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteCutoff(cutoff_id);
        window.location.reoload();
      }
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });

    var params = $("#search_filter_form").serialize();

    params += "&page_no=" + pageNumber;

    this.props.onGetCourseCutoffs("?" + params);
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if (e.target.name === "page_size_changer") {
      $("#page_size").val(e.target.value);
    }
    var params = $("#search_filter_form").serialize();
    console.log(params);
    this.props.onGetCourseCutoffs("?" + params);
  };

  render() {
    const {
      admins,
      cutoffs,
      years,
      courses,
      categories,
      rounds,
      quotas,
      total_count,
      page_count,
      page_size,
    } = this.props;
    console.log(cutoffs);
    let cutoffs_display = {};
    let years_list = [];
    let categories_list = [];
    let pagination_display = "";
    let rounds_list = [];
    let quota_list = [];
    let course_list = [];
    let states_list = [];

    let states = this.state.states;
    let adminoptions = admins.map((admin, index) => (
      <option value={admin.id}>{admin.first_name}</option>
    ));

    // Cutoffs years for filters
    if (years && years !== "" && years.length > 0) {
      years_list = years.map((year) => (
        <option key={year.cutoff_year} value={year.cutoff_year}>
          {year.cutoff_year}
        </option>
      ));
    }

    if (rounds && rounds !== "" && rounds.length > 0) {
      rounds_list = rounds.map((round) => (
        <option key={round.id} value={round.id}>
          {round.round}
        </option>
      ));
    }
    if (states && states !== "" && states.length > 0) {
      states_list = states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.state_name}
        </option>
      ));
    }
    // Cutoffs categories for filters
    if (categories && categories !== "" && categories.length > 0) {
      categories_list = categories.map((cat) => (
        <option key={cat.id} value={cat.id}>
          {cat.category}
        </option>
      ));
    }
    if (quotas && quotas !== "" && quotas.length > 0) {
      quota_list = quotas.map((quota) => (
        <option key={quota.id} value={quota.id}>
          {quota.quota}
        </option>
      ));
    }
    if (courses && courses !== "" && courses.length > 0) {
      course_list = courses.map((course) => (
        <option key={course.id} value={course.id}>
          {course.course_name}
        </option>
      ));
    }

    if (cutoffs && cutoffs !== "" && cutoffs.length > 0) {
      cutoffs_display = cutoffs.map((cutoff, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{cutoff.col_name}</td>
          <td>{cutoff.course_name}</td>
          <td>{cutoff.round}</td>
          <td>{cutoff.quota}</td>
          <td>{cutoff.category}</td>
          <td>{cutoff.cutoff_rank}</td>
          <td>{cutoff.cutoff_year}</td>
          <td>{cutoff.state_name}</td>
          {this.props.admin_info.role == 1 && <td>{cutoff.first_name}</td>}

          {this.props.admin_info.role == 1 && (
            <td>
              <Link to={"/cutoffs/edit_cutoff/" + cutoff.id}>
                <button
                  className="icon_button"
                  onClick={localStorage.setItem("count", 0)}
                  title="Edit"
                >
                  <i className="fa fa-edit text-primary"></i>
                </button>
              </Link>
            </td>
          )}
          {this.props.admin_info.role == 1 && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.deleteCourseCutoff(cutoff.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      cutoffs_display = (
        <tr>
          <td colSpan="11" className="text-center">
            No course cutoffs found.
          </td>
        </tr>
      );
    }

    // Show pagination only if values are more than 10
    if (total_count > page_size) {
      pagination_display = (
        <div className="row pt-1">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
            <div className="form-group d-inline">
              <label className="small_text">Page size : </label>
              <select
                name="page_size_changer"
                className="form-control ml-2 w-auto d-inline"
                value={page_size}
                onChange={this.onApplyFilters}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <p className="small_text d-inline ml-2">
              Showing {page_count} of {total_count} cutoffs.
            </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
            <div className="pagination_container table-responsive">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={parseInt(page_size)}
                totalItemsCount={total_count}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      );
    } else {
      pagination_display = (
        <p className="small_text d-inline ml-2">
          Showing {page_count} of {total_count} cutoffs.
        </p>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Cutoffs
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Course cutoffs </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/cutoffs/bulksupload"}>
                <button type="button" className="btn btn-primary btn-sm">
                  BulkUpload<i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link to={"/cutoffs/add_cutoff"}>
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link to={"/cutoffs/add_bulk_cutoff"}>
                <button type="button" className="btn btn-primary btn-sm">
                  Add All Cutoffs once
                </button>
              </Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select
                    name="sort_key"
                    className="form-control ml-2 w-auto d-inline"
                    onChange={this.onApplyFilters}
                  >
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Years H - L</option>
                    <option value="4">Years L - H</option>
                    <option value="5">Rank Ascending</option>
                    <option value="6">Rank Descending</option>
                  </select>
                </div>
              </div>
              {this.props.admin_info.role == 1 && (
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                  <select
                    className="form-control"
                    name="admin_id"
                    id="admin_id"
                    onChange={this.onApplyFilters}
                  >
                    <option value={0}>Select the Admin</option>
                    {adminoptions}
                  </select>
                </div>
              )}

              <input
                type="hidden"
                name="page_size"
                id="page_size"
                value={page_size}
              />
              {this.props.admin_info.role == 1 && this.state.admin_id !== 0 ? (
                <p>{total_count}</p>
              ) : (
                <p></p>
              )}

              <div className="col-xl-2 col-lg-2 col-md-2">
                <select
                  className="form-control"
                  name="state_id"
                  id="state_id"
                  onChange={this.onApplyFilters}
                >
                  <option value={0}>Select State</option>
                  {states_list}
                </select>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input
                    type="search"
                    name="search_key"
                    className="filter_key form-control"
                    placeholder="Search by cutoffs, year, round, authority and category"
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <button
                      className="search_btn btn-primary px-3"
                      type="button"
                      onClick={this.onApplyFilters}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ background: "grey", padding: "0.05%" }} />
            <div className="row mb-4">
              <div className="col-md-2">
                <select
                  name="year"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All years</option>
                  {years_list}
                </select>
              </div>
              <div className="col-md-2">
                <select
                  name="cat_id"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All categories</option>
                  {categories_list}
                </select>
              </div>
              <div className="col-md-2">
                <select
                  name="round_id"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All Rounds</option>
                  {rounds_list}
                </select>
              </div>
              <div className="col-md-2">
                <select
                  name="quota_id"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All Quotas</option>
                  {quota_list}
                </select>
              </div>
              <div className="col-md-4">
                <select
                  name="course_id"
                  className="form-control"
                  onChange={this.onApplyFilters}
                >
                  <option value="">All Courses</option>
                  {course_list}
                </select>
              </div>
            </div>
          </form>

          {/* Display all course cutoffs table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">SlNo</th>
                <th width="30%">College Name</th>
                <th width="10%">College Course</th>
                <th width="5%">Round</th>
                <th width="5%">Quota</th>
                <th width="5%">Category</th>
                <th width="5%">Rank</th>
                <th width="5%">Year</th>
                <th width="5%">State</th>
                {this.props.admin_info.role == 1 && (
                  <th width="5%">Added_By</th>
                )}
                {this.props.admin_info.role == 1 && <th width="5%">Edit</th>}

                {this.props.admin_info.role == 1 && <th width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {/* Display all the cutoffs */}
              {cutoffs_display}
            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    cutoffs: state.cutoff.cutoffs,
    admins: state.adminUser.admins,
    years: state.cutoff.years,
    categories: state.cutoff.categories,
    quotas: state.cutoff.quotas,
    rounds: state.cutoff.rounds,
    courses: state.cutoff.courses,
    total_count: state.paginate.total_count,
    page_count: state.paginate.page_count,
    page_size: state.paginate.page_size,
  };
};

const mapActionsRoProps = {
  onGetAdmins: getAllAdmins,
  onGetCutoffFilters: getCutoffFilters,
  onGetCourseCutoffs: getAllCourseCutoffs,
  onDeleteCutoff: deleteCutoff,
};

export default connect(mapStatesToProps, mapActionsRoProps)(CourseCutoffs);
