import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";
import { onGetCountriesSuccess } from "./Countries";

export const GET_CONS_COLLEGES_SUCCESS = "GET_CONS_COLLEGES_SUCCESS";
export const GET_CONS_COLLEGE = "GET_CONS_COLLEGE";

export const getAllConsultantColleges = (cons_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_cons_colleges/" + cons_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetConsultantCollegesSuccess(response.data.cons_colleges));
        dispatch(onGetCountriesSuccess(response.data.countries));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getConsultantCollegeDetails = (cons_col_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_cons_college_details/" + cons_col_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetConsultantCollegeSuccess(response.data.cons_college));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const addNewConsultantCollege = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_cons_college", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateConsultantCollege = (cons_college_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_cons_college/" + cons_college_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteConsultantCollege = (cons_id, cons_college_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_cons_college/" + cons_college_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllConsultantColleges(cons_id));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onLoadingFalse());
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetConsultantCollegesSuccess = (data) => {
  return {
    type: GET_CONS_COLLEGES_SUCCESS,
    payload: {
      cons_colleges: data,
    },
  };
};

export const onGetConsultantCollegeSuccess = (cons_college) => {
  return {
    type: GET_CONS_COLLEGE,
    payload: {
      cons_college: cons_college,
    },
  };
};
