import React from "react";
import "./NewsUpdates";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllNewsCategories,
  addNewCategory,
  updateCategory,
  deleteCategory,
} from "../../actions/NewsCategories";
import swal from "sweetalert";
import $ from "jquery";

class NewsCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = { category: "", category_id: "" };
    this.handleEditCategory = this.handleEditCategory.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
  }

  componentDidMount() {
    this.props.onGetNewsCategories();
  }

  handleAddCategory = (e) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "To add this new category?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_category_form")
        );
        this.props.onAddNewNewsCategory(formData);
      }
    });
  };

  handleEditCategory = (category) => {
    this.setState({ category: category, category_id: category.id });
    $("#edit_category_form").collapse();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const category = this.state;
    category[name] = value;

    this.setState({ category: category });
  };

  handleUpdateCategory = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this plan?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_category_form")
        );
        this.props.onUpdateNewsCategory(this.state.category_id, formData);
      }
    });
  };

  handleDeleteCategory = (plan_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this plan permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteCategory(plan_id);
      }
    });
  };

  render() {
    const { categories } = this.props;
    const { category } = this.state;
    let categories_display = {};

    if (categories && categories !== "" && categories.length > 0) {
      categories_display = categories.map((category, index) => (
        <tr key={category.id}>
          <td>{index + 1}</td>
          <td>{category.category}</td>
          <td>
            <button
              className="icon_button"
              title="Edit"
              onClick={() => this.handleEditCategory(category)}
            >
              <i className="fa fa-edit text-primary"></i>
            </button>
          </td>
          <td>
            <button
              className="icon_button"
              title="Delete"
              onClick={() => this.handleDeleteCategory(category.id)}
            >
              <i className="fa fa-trash text-danger"></i>
            </button>
          </td>
          }
        </tr>
      ));
    } else {
      categories_display = (
        <tr>
          <td colSpan="4" className="text-center">
            No plans found.
          </td>
        </tr>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/news_updates">News </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> News Categories </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/news_updates">
                <button type="button" className="btn btn-primary btn-sm">
                  News <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                data-toggle="collapse"
                data-target="#add_category_form"
              >
                Add <i className="ml-1 fa fa-plus"></i>
              </button>
            </div>
          </div>

          <div id="add_edit_forms">
            <form
              className="collapse mb-4"
              id="add_category_form"
              data-parent="#add_edit_forms"
              onSubmit={this.handleAddCategory}
              autoComplete="off"
            >
              <div className="row">
                <div className="col"></div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 card pt-3">
                  <div className="form-group">
                    <label className="small_text"> Category * : </label>
                    <input
                      type="text"
                      name="category"
                      className="form-control"
                      placeholder="Category *"
                      required
                    />
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      Add <i className="ml-1 fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </form>

            <form
              className="collapse mb-4"
              id="edit_category_form"
              data-parent="#add_edit_forms"
              onSubmit={this.handleUpdateCategory}
              autoComplete="off"
            >
              <div className="row animated fadeIn">
                <div className="col"></div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 card pt-3">
                  <div className="form-group">
                    <label className="small_text"> Category * : </label>
                    <input
                      type="text"
                      name="category"
                      className="form-control"
                      value={category.category ? category.category : ""}
                      onChange={this.handleInputChange}
                      placeholder="Category *"
                      required
                    />
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      Update <i className="ml-1 fa fa-check"></i>
                    </button>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>

          {/* Display all categories table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">Id</th>
                <th width="82%">Category</th>
                <th width="8%">Edit</th>
                {this.props.admin_info.user_type === "1" && (
                  <th width="5%">Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {/* Display all the categories */}
              {categories_display}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    categories: state.category.categories,
  };
};

const mapActionsRoProps = {
  onGetNewsCategories: getAllNewsCategories,
  onAddNewNewsCategory: addNewCategory,
  onUpdateNewsCategory: updateCategory,
  onDeleteCategory: deleteCategory,
};

export default connect(mapStatesToProps, mapActionsRoProps)(NewsCategories);
