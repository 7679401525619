import React from "react";
import "./Colleges.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewCollege } from "../../actions/Colleges";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownStates } from "../../actions/States";
import { getStateCities } from "../../actions/Cities";
import { getDropDownUniversities } from "../../actions/Universities";
import swal from "sweetalert";

class AddCollege extends React.Component {
  constructor(props) {
    super(props);
    this.state = { images: { col_logo: "", col_image: "" } };
    this.onAddCollegeSubmit = this.onAddCollegeSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetDropDownStreams();
    this.props.onGetDropDownStates();
    this.props.onGetUniversities();
  }

  onAddCollegeSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new college?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("add_col_form"));
        this.props.onAddNewCollege(formData);
      }
    });
  };

  onChangeHandler = (e) => {
    const { name } = e.target;
    let images = this.state.images;

    if (name === "col_logo" || name === "col_image") {
      images[name] = URL.createObjectURL(e.target.files[0]);
    }

    this.setState({ images: images });

    if (e.target.name === "col_state") {
      this.props.onGetStateCities(e.target.value);
    }
  };

  render() {
    const { images } = this.state;
    const { streams, states, cities, universities, admin_info } = this.props;
    let logo_preview = "";
    let banner_preview = "";
    console.log(admin_info);
    if (images.col_logo) {
      logo_preview = (
        <img
          src={images.col_logo}
          className="img-fluid img-thumbnail"
          alt="Logo"
        />
      );
    }

    if (images.col_image) {
      banner_preview = (
        <img
          src={images.col_image}
          className="img-fluid img-thumbnail"
          alt="Banner"
        />
      );
    }

    let streams_list = [];

    if (streams && streams !== "" && streams.length > 0) {
      streams_list = streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.name}
        </option>
      ));
    }

    let states_list = [];

    if (states && states !== "" && states.length > 0) {
      states_list = states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.state_name}
        </option>
      ));
    }

    let cities_list = [];

    if (cities && cities !== "" && cities.length > 0) {
      cities_list = cities.map((city) => (
        <option key={city.id} value={city.id}>
          {city.city_name}
        </option>
      ));
    }

    let universities_list = [];

    if (universities && universities !== "" && universities.length > 0) {
      universities_list = universities.map((university) => (
        <option key={university.id} value={university.id}>
          {university.uni_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add College
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add College </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/colleges/bulk_upload">
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Add new college form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 card p-3">
              <br />

              <form
                id="add_col_form"
                onSubmit={this.onAddCollegeSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <input
                      type="hidden"
                      name="added_by"
                      value={admin_info.id}
                    />
                    <div className="form-group">
                      <label className="small_text"> College Name * : </label>
                      <input
                        type="text"
                        name="col_name"
                        placeholder="College Name"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row form-group">
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> College Code * : </label>
                        <input
                          type="text"
                          name="col_code"
                          placeholder="College Code"
                          className="form-control"
                          required
                        />
                      </div> */}
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">Established Year :</label>
                        <input
                          type="number"
                          name="col_established"
                          placeholder="Established Year"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">College Type * : </label>
                        <select
                          name="col_type"
                          className="form-control"
                          required
                        >
                          <option value="">Select college type</option>
                          <option value="Deemed">Deemed</option>
                          <option value="Government">Government</option>
                          <option value="Private">Private</option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">College Stream * :</label>
                        <select
                          name="col_stream"
                          className="form-control"
                          required
                        >
                          <option value="">Select stream</option>
                          {streams_list}
                        </select>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> State * : </label>
                        <select
                          name="col_state"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value="">Select state</option>
                          {states_list}
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">City * : </label>
                        <select
                          name="col_city"
                          className="form-control"
                          required
                        >
                          <option value="">Select city</option>
                          {cities_list}
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">University * : </label>
                      <select
                        name="col_university"
                        className="form-control"
                        required
                      >
                        <option value="">Select university</option>
                        {universities_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Accreditations : </label>
                      <input
                        type="text"
                        name="col_accrs"
                        placeholder="College Accreditations"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Email ID : </label>
                      <input
                        type="email"
                        name="col_email"
                        placeholder="College Email"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Phone Number : </label>
                      <input
                        type="text"
                        name="col_phone"
                        placeholder="College Phone"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> College Address : </label>
                      <textarea
                        name="col_address"
                        rows="4"
                        placeholder="College Address"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">Popular College :</label>
                        <select name="is_popular" className="form-control">
                          <option value="0"> No </option>
                          <option value="1"> Yes </option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status : </label>
                        <select name="col_status" className="form-control">
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> About College : </label>
                      <textarea
                        name="about_col"
                        rows="8"
                        placeholder="About College"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Google Map Location :
                      </label>
                      <input
                        type="text"
                        name="col_map"
                        placeholder="Google map address"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> College Website : </label>
                      <input
                        type="text"
                        name="col_website"
                        placeholder="College Website"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Description : </label>
                      <textarea
                        name="col_descr"
                        rows="4"
                        placeholder="College Description"
                        className="form-control"
                      />
                    </div>

                    <div className="row form-group mt-3">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">College Logo : </label>
                        <input
                          type="file"
                          name="col_logo"
                          placeholder="College Logo"
                          onChange={this.onChangeHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {logo_preview}
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">College Banner : </label>
                        <input
                          type="file"
                          name="col_image"
                          placeholder="College Banner"
                          onChange={this.onChangeHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {banner_preview}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add College
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    streams: state.stream.streams,
    admin_info: state.admin.admin_info,
    states: state.state.states,
    cities: state.city.cities,
    universities: state.university.universities,
  };
};

const mapActionsRoProps = {
  onGetDropDownStreams: getDropDownStreams,
  onGetDropDownStates: getDropDownStates,
  onGetStateCities: getStateCities,
  onGetUniversities: getDropDownUniversities,
  onAddNewCollege: addNewCollege,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddCollege);
