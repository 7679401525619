import { GET_COL_FACILITIES_SUCCESS, GET_COL_FACILITY_SUCCESS } from '../actions/CollegeFacilities';

const initialState = {
    col_facilities : [],
    col_facility : {}
}

export default function College_facility_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_COL_FACILITY_SUCCESS :
            return Object.assign({}, state, { col_facility : payload.col_facility });

        case GET_COL_FACILITIES_SUCCESS :
            return Object.assign({}, state, { col_facilities : payload.col_facilities });

        default :
            return state;
    }
}
