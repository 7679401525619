import React from "react";
import "./ProjectManagerDashboard.css";
import PieChart from "react-simple-pie-chart";
import { Link } from "react-router-dom";
import { getAdminDashboard } from "../../actions/Dashboard";
import { connect } from "react-redux";
import { formatDate } from "../../utils/DateFormat";

class ProjectManagerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { time: new Date() };
  }

  componentDidMount() {
    if (this.props.isAuth === true) {
      setTimeout(
        function () {
          this.props.onGetAdminDashboard();
        }.bind(this),
        1
      );
    }
    this.timerID = setInterval(() => this.changeTiming(), 1000);
  }

  changeTiming() {
    this.setState({ time: new Date() });
  }

  render() {
    const {
      users,
      week_users,
      month_users,
      month_callbacks,
      plans,
      consultants,
      callbacks,
    } = this.props;

    const user_graph = [
      {
        color: "#386FA4",
        value: users.paid ? users.paid : 0,
      },
      {
        color: "#88CCF1",
        value: users.unpaid ? users.unpaid : 0,
      },
      {
        color: "#EF626C",
        value: users.inactive ? users.inactive : 0,
      },
    ];

    const pricing_graph = [
      {
        color: "#386FA4",
        value: plans[0] ? plans[0].total : 0,
      },
      {
        color: "#69EBD0",
        value: plans[1] ? plans[1].total : 0,
      },
      {
        color: "#88CCF1",
        value: plans[2] ? plans[2].total : 0,
      },
    ];

    const consultants_graph = [
      {
        color: "#386FA4",
        value: consultants.verified ? consultants.verified : 0,
      },
      {
        color: "#88CCF1",
        value: consultants.not_verified ? consultants.not_verified : 0,
      },
      {
        color: "#EF626C",
        value: consultants.inactive ? consultants.inactive : 0,
      },
    ];

    const week_users_graph = [
      {
        color: "#386FA4",
        value: week_users.paid ? week_users.paid : 0,
      },
      {
        color: "#88CCF1",
        value: week_users.unpaid ? week_users.unpaid : 0,
      },
      {
        color: "#EF626C",
        value: week_users.inactive ? week_users.inactive : 0,
      },
    ];

    const month_users_graph = [
      {
        color: "#386FA4",
        value: month_users.paid ? month_users.paid : 0,
      },
      {
        color: "#88CCF1",
        value: month_users.unpaid ? month_users.unpaid : 0,
      },
      {
        color: "#EF626C",
        value: month_users.inactive ? month_users.inactive : 0,
      },
    ];

    const month_callbacks_graph = [
      {
        color: "#386FA4",
        value: month_callbacks.attended ? month_callbacks.attended : 0,
      },
      {
        color: "#EF626C",
        value: month_callbacks.un_attended ? month_callbacks.un_attended : 0,
      },
    ];

    let users_display = [];
    let payments_display = [];
    let callbacks_display = [];

    if (users && users.recent_users && users.recent_users.length > 0) {
      users_display = (
        <div className="text-left">
          {users.recent_users &&
            users.recent_users.map((user, index) => (
              <div key={index}>
                <span className="small_text text-blue" title={user.created_at}>
                  {index +
                    1 +
                    ") " +
                    user.fname +
                    " " +
                    user.lname +
                    " - " +
                    user.mobile +
                    " - (" +
                    formatDate(user.created_at) +
                    ")"}
                </span>
                <br />
              </div>
            ))}
          <div className="text-center mt-2">
            <Link to="/users">
              <button className="btn btn-info btn-sm ml-0">
                <b> View all </b>
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      users_display = (
        <div>
          <br />
          <span className="small_text text-blue"> No users</span>
          <br />
        </div>
      );
    }

    if (users && users.recent_paid && users.recent_paid.length > 0) {
      payments_display = (
        <div className="text-left">
          {users.recent_paid &&
            users.recent_paid.map((user, index) => (
              <div key={index}>
                <span className="small_text text-blue" title={user.paid_at}>
                  {index +
                    1 +
                    ") " +
                    user.fname +
                    " " +
                    user.lname +
                    " - " +
                    user.mobile +
                    " - (" +
                    formatDate(user.paid_at) +
                    ")"}
                </span>
                <br />
              </div>
            ))}
          <div className="text-center mt-2">
            <Link to="/users">
              <button className="btn btn-info btn-sm ml-0">
                <b> View all </b>
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      payments_display = (
        <div>
          <br />
          <span className="small_text text-blue"> No payments</span>
          <br />
        </div>
      );
    }

    if (callbacks && callbacks.length > 0) {
      callbacks_display = (
        <div className="text-left">
          {callbacks &&
            callbacks.map((call, index) => (
              <div key={index}>
                <span className="small_text text-blue" title={call.created_at}>
                  {index +
                    1 +
                    ") " +
                    call.name +
                    " - " +
                    call.mobile +
                    " - (" +
                    formatDate(call.created_at) +
                    ")"}
                </span>
                <br />
              </div>
            ))}
          <div className="text-center mt-2">
            <Link to="/callbacks">
              <button className="btn btn-info btn-sm ml-0">
                <b> View all </b>
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      callbacks_display = (
        <div>
          <br />
          <span className="small_text text-blue"> No callbacks</span>
          <br />
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" area-current="page">
                    Project Manager Dashboard
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header">
                Project Manager Dashboard
              </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <h2 className="text-orange">
                {this.state.time.toLocaleTimeString()}
              </h2>
            </div>
          </div>
        </div>

        {/* Graphs container */}
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <div className="row">
            {/* Users graph card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b> Total Users </b>
                </h5>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                    style={{ opacity: "1" }}
                  >
                    <PieChart slices={user_graph} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                    <h6 className="small_text mt-3">
                      <i className="fa fa-square mr-1"></i> Registered Users -
                      {users.total}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#386FA4" }}
                      ></i>
                      Paid Users - {users.paid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#88CCF1" }}
                      ></i>
                      Unpaid Users - {users.unpaid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#EF626C" }}
                      ></i>
                      Inactive Users - {users.inactive}
                    </h6>
                    <Link to="/users">
                      <button className="btn btn-info btn-sm ml-0">
                        <b> Users</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Last 7 days users graph card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b>
                    Users
                    <span className="small_text">
                      <b> (Last 7 days) </b>
                    </span>
                  </b>
                </h5>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                    style={{ opacity: "1" }}
                  >
                    <PieChart slices={week_users_graph} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                    <h6 className="small_text mt-3">
                      <i className="fa fa-square mr-1"></i> Registered Users -
                      {week_users.total}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#386FA4" }}
                      ></i>
                      Paid Users - {week_users.paid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#88CCF1" }}
                      ></i>
                      Unpaid Users - {week_users.unpaid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#EF626C" }}
                      ></i>
                      Inactive Users - {week_users.inactive}
                    </h6>
                    <Link to="/users">
                      <button className="btn btn-info btn-sm ml-0">
                        <b> Users</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Last 30 days users graph card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b>
                    Users
                    <span className="small_text">
                      <b> (Last 30 days) </b>
                    </span>
                  </b>
                </h5>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                    style={{ opacity: "1" }}
                  >
                    <PieChart slices={month_users_graph} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                    <h6 className="small_text mt-3">
                      <i className="fa fa-square mr-1"></i> Registered Users -
                      {month_users.total}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#386FA4" }}
                      ></i>
                      Paid Users - {month_users.paid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#88CCF1" }}
                      ></i>
                      Unpaid Users - {month_users.unpaid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#EF626C" }}
                      ></i>
                      Inactive Users - {month_users.inactive}
                    </h6>
                    <Link to="/users">
                      <button className="btn btn-info btn-sm ml-0">
                        <b> Users</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Pricing plans graph card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b> Pricing Plans </b>
                </h5>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                    style={{ opacity: "1" }}
                  >
                    <PieChart slices={pricing_graph} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                    <h6 className="small_text mt-3">
                      <i className="fa fa-square mr-1"></i> Total Paid Users -
                      {users.paid}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#386FA4" }}
                      ></i>
                      {plans[0]
                        ? plans[0].plan_name + " - " + plans[0].total
                        : ""}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#69EBD0" }}
                      ></i>
                      {plans[1]
                        ? plans[1].plan_name + " - " + plans[1].total
                        : ""}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#88CCF1" }}
                      ></i>
                      {plans[2]
                        ? plans[2].plan_name + " - " + plans[2].total
                        : ""}
                    </h6>
                    <Link to="/member_plans">
                      <button className="btn btn-info btn-sm ml-0">
                        <b> Plans</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Consultants graph card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b> Consultants </b>
                </h5>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                    style={{ opacity: "1" }}
                  >
                    <PieChart slices={consultants_graph} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                    <h6 className="small_text mt-3">
                      <i className="fa fa-square mr-1"></i> Total Consultants -
                      {consultants.verified +
                        consultants.not_verified +
                        consultants.inactive}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#386FA4" }}
                      ></i>
                      Verified - {consultants.verified}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#88CCF1" }}
                      ></i>
                      Not verified - {consultants.not_verified}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#EF626C" }}
                      ></i>
                      Inactive - {consultants.inactive}
                    </h6>
                    <Link to="/consultants">
                      <button className="btn btn-info btn-sm ml-0">
                        <b>Consultants</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Consultants graph card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b>
                    Callbacks
                    <span className="small_text">
                      <b> (Last 30 days) </b>
                    </span>
                  </b>
                </h5>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                    style={{ opacity: "1" }}
                  >
                    <PieChart slices={month_callbacks_graph} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                    <h6 className="small_text mt-3">
                      <i className="fa fa-square mr-1"></i> Total Callbacks -
                      {month_callbacks.total}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#386FA4" }}
                      ></i>
                      Attended - {month_callbacks.attended}
                    </h6>
                    <h6 className="small_text mt-1">
                      <i
                        className="fa fa-square mr-1"
                        style={{ color: "#EF626C" }}
                      ></i>
                      Unattended - {month_callbacks.un_attended}
                    </h6>
                    <Link to="/callbacks">
                      <button className="btn btn-info btn-sm ml-0">
                        <b> Callbacks</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Recently registered users card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b> Recently registered </b>
                </h5>
                {users_display}
              </div>
            </div>

            {/* Recently paid users card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b> Recent payments </b>
                </h5>
                {payments_display}
              </div>
            </div>

            {/* Recently paid users card */}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
              <div className="card p-3">
                <h5 className="text-blue">
                  <b> Recent callbacks </b>
                </h5>
                {callbacks_display}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }
}

const mapStatesToProps = (state) => {
  return {
    isAuth: state.admin.isAuth,
    admin_info: state.admin.admin_info,
    users: state.dashboard.users,
    week_users: state.dashboard.week_users,
    month_users: state.dashboard.month_users,
    month_callbacks: state.dashboard.month_callbacks,
    plans: state.dashboard.plans,
    consultants: state.dashboard.consultants,
    callbacks: state.dashboard.callbacks,
  };
};

const mapActionsToProps = {
  onGetAdminDashboard: getAdminDashboard,
};

export default connect(
  mapStatesToProps,
  mapActionsToProps
)(ProjectManagerDashboard);
