import React from 'react';
import './CollegeCourses.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bulkUploadCollegeCourses } from '../../actions/CollegeCourses';
import swal from 'sweetalert';

class BulkUpload extends React.Component {

    constructor(props){
        super(props);
        this.state = { college_id : this.props.match.params.college_id, college : this.props.match.params.college };
    }

    onBulkUploadSubmit = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "To upload college courses excel?",
            buttons: ['Cancel', 'Upload'],
        })
        .then(willDelete => {
            if (willDelete) {
                var formData = new FormData(document.getElementById('col_courses_bulk_upload_form'));
                this.props.onBulkUploadCollegeCourses( formData);
            }
        });
    }

    render() {

        const { college, college_id } = this.state;

        return (

            <div className="row">
                <div className="col">
                
                    {/* Breadcrumbs and add button container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <nav area-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                                    <li className="breadcrumb-item"><Link to="/colleges">Colleges</Link></li>
                                    <li className="breadcrumb-item"><Link to={'/college_courses/' + college + '/' + college_id}>Courses</Link></li>
                                    <li className="breadcrumb-item active" area-current="page">Bulk Upload</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <h4 className="text-center page_header"> Bulk Upload </h4>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                            <Link to={'/college_courses/' + college + '/' + college_id + '/add_col_course'}><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
                        </div>
                    </div>
                    
                    {/* BulkUpload bulk upload form  */}
                    <div className="row bulk_container form_container">
                        <div className="col"></div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
                        <br/>

                            <form id="col_courses_bulk_upload_form" onSubmit={this.onBulkUploadSubmit} autoComplete="off">

                                <div className="form-group">
                                <label className="small_text"> College Courses Excel File : </label>
                                <input type="file" name="college_courses_excel" className="form-control" placeholder="Select Excel File" required/>
                                </div>

                                <div className="form-group text-center mt-1">
                                <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Upload </button>
                                </div>

                            </form>

                        </div>
                        <div className="col"></div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onBulkUploadCollegeCourses : bulkUploadCollegeCourses
};

export default connect(mapStatesToProps, mapActionsRoProps) (BulkUpload);
