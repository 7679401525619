import React from "react";
import "./Universities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  bulkUploadUniversities,
  uploadUniversitiesImages,
} from "../../actions/Universities";
import swal from "sweetalert";

class BulkUpload extends React.Component {
  onBulkUploadSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To upload this university excel file?",
      buttons: ["Cancel", "Uplaod"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("uni_bulk_upload_form")
        );
        this.props.onBulkUploadUniversities(formData);
      }
    });
  };

  onImagesUploadSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To upload this university images?",
      buttons: ["Cancel", "Uplaod"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("uni_images_upload_form")
        );
        this.props.onUploadUniversitiesImages(formData);
      }
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>{" "}
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/universities">Universities</Link>{" "}
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Bulk Upload
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Bulk Upload </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/universities/add_university">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="text-center">
            <label className="small_text">
              {" "}
              File size should be less than 2MB. <br /> Excel file should has
              extensions as .xlsx or .xls. <br /> Image file name shoule start
              from University Code, Ex. UNI001_logo.png or UNI001_banner.jpg.
            </label>
          </div>
          <br />

          {/* BulkUpload bulk upload form  */}
          <div className="row bulk_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="uni_bulk_upload_form"
                onSubmit={this.onBulkUploadSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">
                    {" "}
                    Universities Excel File :{" "}
                  </label>
                  <input
                    type="file"
                    name="universities_excel"
                    className="form-control"
                    placeholder="Select Excel File"
                    required
                  />
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    {" "}
                    Upload{" "}
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="uni_images_upload_form"
                onSubmit={this.onImagesUploadSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">
                    {" "}
                    University Image Files :{" "}
                  </label>
                  <input
                    type="file"
                    max="5"
                    name="university_images[]"
                    multiple
                    className="form-control"
                    placeholder="Select Images"
                    required
                  />
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    {" "}
                    Upload{" "}
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
  };
};

const mapActionsRoProps = {
  onBulkUploadUniversities: bulkUploadUniversities,
  onUploadUniversitiesImages: uploadUniversitiesImages,
};

export default connect(mapStatesToProps, mapActionsRoProps)(BulkUpload);
