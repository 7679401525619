import React from "react";
import "./Courses.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDropDownCourseLevels, addNewCourse } from "../../actions/Courses";
import swal from "sweetalert";

class AddCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stream_id: this.props.match.params.stream_id,
      stream: this.props.match.params.stream,
      levels: this.props.levels,
    };
  }

  componentDidMount() {
    this.props.onGetDropDownCourseLevels();
  }

  onAddCourseSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new course?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("add_course_form"));
        this.props.onAddNewCourse(formData);
      }
    });
  };

  render() {
    const { stream, stream_id } = this.state;
    let course_levels = [];

    if (
      this.props.levels &&
      this.props.levels !== "" &&
      this.props.levels.length > 0
    ) {
      course_levels = this.props.levels.map((level) => (
        <option key={level.id} value={level.id}>
          {level.level_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/streams">Streams</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/courses/" + stream + "/" + stream_id}>
                      Courses
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Course
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add {stream} Course </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={"/courses/" + stream + "/" + stream_id + "/bulk_upload"}
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Add new course form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="add_course_form"
                onSubmit={this.onAddCourseSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="course_name"
                        placeholder="Course Name"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="course_mode"
                        placeholder="Course Mode"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <select
                        name="course_level"
                        className="form-control"
                        required
                      >
                        <option value=""> Select course level</option>
                        {course_levels}
                      </select>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="text"
                            name="course_duration"
                            placeholder="Course Duration in months"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group">
                          <select name="course_status" className="form-control">
                            <option value="1"> Active </option>
                            <option value="0"> Inactive </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <textarea
                        name="course_description"
                        rows="4"
                        placeholder="Course Description"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        name="course_eligibility"
                        rows="4"
                        placeholder="Course Eligibility"
                        className="form-control"
                      />
                    </div>

                    <input
                      type="hidden"
                      name="course_stream"
                      className="form-control"
                      defaultValue={this.state.stream_id}
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Course
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    levels: state.course.levels,
  };
};

const mapActionsRoProps = {
  onGetDropDownCourseLevels: getDropDownCourseLevels,
  onAddNewCourse: addNewCourse,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddCourse);
