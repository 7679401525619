import React from 'react';
import './CourseCutoffs.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCutoffFilters, addNewCutoff } from '../../actions/Cutoffs';
import { getAllColleges } from '../../actions/Colleges';
import swal from 'sweetalert';

class AddCourseCutoff extends React.Component {

  componentDidMount () {
    this.props.onGetCutoffFilters();
    this.props.onGetColleges('');
  }

  onAddCourseCutoffSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new course cutoff?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_cutoff_form'));
        this.props.onAddNewCutoff(formData);
      }
    });
  }

  render() {

    let this_year = (new Date()).getFullYear()+1;
    let all_years = [];
    for(let x = 0; x <= 2; x++) {
      all_years.push(this_year - x)
    }

    const {categories, authorities, rounds,colleges,courses,quotas} = this.props;
   
    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/cutoffs">Cutoffs</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Cutoff</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Cutoff </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/* <Link to={ '/cutoffs/' + '/bulk_upload' }><button type="button" className="btn btn-primary btn-sm">Bulk Upload <i className="ml-1 fa fa-plus"></i></button></Link> */}
            </div>
          </div>
          
          {/*  Add new cutoff form  */}
          <div className="row add_container form_container mt-4">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card pt-4">

              <form id="add_cutoff_form" onSubmit={this.onAddCourseCutoffSubmit} autoComplete="off">
             

                <div className="row">
                  
                  <div className="col">
                  <div className="form-group">
                      <label className="small_text"> Cutoff Colleges : </label>
                      <select name="cutoff_college" className="form-control" required>
                        <option value="">Select Colleges</option>
                        {(colleges && colleges.length > 0) &&
                          colleges.map((college, index) => (
                            <option key={index} value={college.id}>{college.col_name}</option>
                          ))
                        }
                      </select>
                    </div> 
                    <div className="form-group">
                      <label className="small_text"> Cutoff year : </label>
                      <select name="cutoff_year" className="form-control" required>
                        <option value="">Select Year</option>
                        {(all_years && all_years.length > 0) &&
                          all_years.map((year) => (
                            <option key={year} value={year} >{year}</option>
                          ))
                        }
                      </select>
                    </div>
                            
                    <div className="form-group">
                      <label className="small_text"> Cutoff category : </label>
                      <select name="cutoff_category" className="form-control" required>
                        <option value="">Select Category</option>
                        {(categories && categories.length > 0) &&
                          categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.category}</option>
                          ))
                        }
                      </select>
                    </div>
                  <div className="form-group">
                      <label className="small_text"> Cutoff Courses : </label>
                      <select name="cutoff_course" className="form-control" required>
                        <option value="">Select Course</option>
                        {(courses && courses.length > 0) &&
                          courses.map((course, index) => (
                            <option key={index} value={course.id}>{course.course_name}</option>
                          ))
                        }
                      </select>
                    </div> 
               
                  </div>

                  <div className="col">  

                       <div className="form-group">
                      <label className="small_text">Authority / State : </label>
                      <select name="cutoff_authority" className="form-control">
                        <option value="0">Select Authority</option>
                        {(authorities && authorities.length > 0) &&
                          authorities.map((authority, index) => (
                            <option key={index} value={authority.id}>{(authority.state !== '' ? (authority.state + ' - ') : '') + authority.author_code + ' - ' + authority.author_name}</option>
                          ))
                        }
                      </select>
                    </div>              
                    <div className="form-group">
                      <label className="small_text"> Round : </label>
                      <select name="cutoff_round" className="form-control" required>
                        <option value="">Select Round</option>
                        {(rounds && rounds.length > 0) &&
                          rounds.map((round, index) => (
                            <option key={index} value={round.id}>{round.round}</option>
                          ))
                        }
                      </select>
                    </div>
                                
                    <div className="form-group">
                      <label className="small_text">Cutoff Quota : </label>
                      <select name="cutoff_quota" className="form-control" required>
                        <option value="">Select Quota</option>
                        {(quotas && quotas.length > 0) &&
                          quotas.map((quota, index) => (
                            <option key={index} value={quota.id}>{quota.quota}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="small_text">Cutoff Rank : </label>
                      <input type="number" className='form-control' name="cutoff_rank" />
                      
                    </div>
{/* 
                    <div className="form-group">
                      <label className="small_text">Cutoff file : </label>
                      <input type="file" name="cutoff_file" placeholder="Cutoff file" className="form-control" required/>
                    </div> */}
                  </div>
                </div>
                
                <div className="form-group text-center mt-3">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add Cutoff </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  
  return {
    isLoading: state.admin.isLoading,
    categories: state.cutoff.categories,
    colleges: state.cutoff.colleges,
    rounds: state.cutoff.rounds,
    quotas:state.cutoff.quotas,
    courses:state.cutoff.courses,
    course_levels: state.cutoff.course_levels,
    authorities: state.cutoff.authorities,
  }
};

const mapActionsRoProps = {
  onGetColleges : getAllColleges,
  onGetCutoffFilters : getCutoffFilters,
  onAddNewCutoff : addNewCutoff
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddCourseCutoff);
