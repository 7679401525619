import { GET_UNIVERSITIES_SUCCESS, GET_UNIVERSITY_SUCCESS } from '../actions/Universities';

const initialState = {
    universities : [],
    university : {}
}

export default function University_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_UNIVERSITY_SUCCESS :
            return Object.assign({}, state, { university : payload.university });

        case GET_UNIVERSITIES_SUCCESS :
            return Object.assign({}, state, { universities : payload.universities });

        default :
            return state;
    }
}
