import React from "react";
import "./Exams.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getExamDetails, updateExam } from "../../actions/Exams";
import { getDropDownCountries } from "../../actions/Countries";
import swal from "sweetalert";

class Exams extends React.Component {
  constructor(props) {
    super(props);
    this.state = { exam_id: this.props.match.params.exam_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetDropDownCountries();
    this.props.onGetExamDetails(this.state.exam_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let exam = this.props.exam;
    exam[name] = value;
    this.setState({ exam: exam });
  };

  onUpdateExamSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this exam?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_exam_form"));
        this.props.onUpdateExam(this.state.exam_id, formData);
      }
    });
  };

  render() {
    const { exam, countries } = this.props;

    let countries_list = [];

    if (countries && countries !== "" && countries.length > 0) {
      countries_list = countries.map((country) => (
        <option key={country.id} value={country.id}>
          {country.con_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/exams">Exams</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Exam
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Exam </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/exams/add_exam">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit exam form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
              <br />

              <form
                id="edit_exam_form"
                onSubmit={this.onUpdateExamSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">Exam Code : </label>
                  <input
                    type="text"
                    name="exam_code"
                    placeholder="Exam Code"
                    value={exam.exam_code ? exam.exam_code : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">Exam Name : </label>
                  <input
                    type="text"
                    name="exam_name"
                    placeholder="Exam Name"
                    value={exam.exam_name ? exam.exam_name : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">Exam Description : </label>
                  <textarea
                    name="exam_description"
                    rows="4"
                    placeholder="Exam Description"
                    value={exam.exam_description ? exam.exam_description : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label className="small_text"> Country : </label>
                  <select
                    name="exam_country"
                    value={exam.exam_country ? exam.exam_country : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                  >
                    <option value="">Select country</option>
                    {countries_list}
                  </select>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Exam
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    countries: state.country.countries,
    exam: state.exam.exam,
  };
};

const mapActionsRoProps = {
  onGetDropDownCountries: getDropDownCountries,
  onGetExamDetails: getExamDetails,
  onUpdateExam: updateExam,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Exams);
