import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import Admin_login_reducer from "../reducers/Login";
import Admin_dashboard_reducer from "../reducers/Dashboard";
import Pagination_reducer from "../reducers/Pagination";
import User_reducer from "../reducers/Users";
import Admin_reducer from "../reducers/Admins";
import Callback_reducer from "../reducers/Callbacks";
import Event_reducer from "../reducers/Events";
import News_update_reducer from "../reducers/NewsUpdates";
import News_category_reducer from "../reducers/NewsCategories";
import University_reducer from "../reducers/Universities";
import College_reducer from "../reducers/Colleges";
import Cutoff_reducer from "../reducers/Cutoffs";
import Country_reducer from "../reducers/Countries";
import State_reducer from "../reducers/States";
import City_reducer from "../reducers/Cities";
import Exam_reducer from "../reducers/Exams";
import Stream_reducer from "../reducers/Streams";
import Course_reducer from "../reducers/Courses";
import College_course_reducer from "../reducers/CollegeCourses";
import Course_fees_reducer from "../reducers/CourseFees";
import Consultant_reducer from "../reducers/Consultants";
import Consultant_college_reducer from "../reducers/ConsultantColleges";
import Member_plan_reducer from "../reducers/MemberPlans";
import Plan_benefit_reducer from "../reducers/PlanBenefits";
import Coupon_reducer from "../reducers/Coupons";
import Facility_reducer from "../reducers/Facilities";
import Authority_reducer from "../reducers/Authorities";
import College_facility_reducer from "../reducers/CollegeFacilities";
import SuperAdmin_reducer from "../reducers/SuperAdmins";
import Senior_Manager_reducer from "../reducers/SeniorManager";
import Video_reducer from "../reducers/Videos";
import Banner_reducer from "../reducers/Banners";
import Notification_reducer from "../reducers/Notifications";

const All_reducers = combineReducers({
  admin: Admin_login_reducer,
  superAdmin: SuperAdmin_reducer,
  seniorManagers: Senior_Manager_reducer,
  dashboard: Admin_dashboard_reducer,
  paginate: Pagination_reducer,
  user: User_reducer,
  adminUser: Admin_reducer,
  callback: Callback_reducer,
  event: Event_reducer,
  news: News_update_reducer,
  category: News_category_reducer,
  university: University_reducer,
  college: College_reducer,
  cutoff: Cutoff_reducer,
  country: Country_reducer,
  state: State_reducer,
  city: City_reducer,
  exam: Exam_reducer,
  stream: Stream_reducer,
  course: Course_reducer,
  col_course: College_course_reducer,
  course_fee: Course_fees_reducer,
  consultant: Consultant_reducer,
  cons_college: Consultant_college_reducer,
  plan: Member_plan_reducer,
  benefit: Plan_benefit_reducer,
  coupon: Coupon_reducer,
  facility: Facility_reducer,
  authority: Authority_reducer,
  col_facility: College_facility_reducer,
  video: Video_reducer,
  banner: Banner_reducer,
  notification: Notification_reducer,
});

const store = createStore(All_reducers, applyMiddleware(thunk));

export default store;
