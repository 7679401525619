import React from 'react';
import './States.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewState } from '../../actions/States';
import swal from 'sweetalert';

class States extends React.Component {
  constructor(props){
    super(props);
    this.onAddStateSubmit = this.onAddStateSubmit.bind(this);
  }

  onAddStateSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new state?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_state_form'));
        this.props.onAddNewState(formData);
      }
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/states">States</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add State</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add State </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new state form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
              <br/>

              <form id="add_state_form" onSubmit={this.onAddStateSubmit} autoComplete="off">

                <div className="form-group">
                    <input type="text" name="state_name" placeholder="State Name" className="form-control" required/>
                </div>

                <div className="form-group">
                    <input type="text" name="state_code" placeholder="State Code" className="form-control"/>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading}> Add State </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewState : addNewState
};

export default connect(mapStatesToProps, mapActionsRoProps) (States);
