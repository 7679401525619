import { GET_CONS_COLLEGES_SUCCESS, GET_CONS_COLLEGE } from '../actions/ConsultantColleges';

const initialState = {
    cons_colleges : [],
    cons_college : {}
}

export default function Consultant_college_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_CONS_COLLEGE :
            return Object.assign({}, state, { cons_college : payload.cons_college });

        case GET_CONS_COLLEGES_SUCCESS :
            return Object.assign({}, state, { cons_colleges : payload.cons_colleges });

        default :
            return state;
    }
}
