import {
  GET_BANNERS_SUCCESS,
  BANNER_DETAILS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
} from "../actions/Banners";

const initialState = {
  banners: [],
  banner: {},
  categories: [],
};

export default function Banner_reducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case BANNER_DETAILS_SUCCESS:
      return Object.assign({}, state, { banner: payload.banner });

    case GET_BANNERS_SUCCESS:
      return Object.assign({}, state, { banners: payload.banners });

    case GET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, { categories: payload.categories });

    default:
      return state;
  }
}
