import React from "react";
import "./CollegeCourses.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewCollegeCourse } from "../../actions/CollegeCourses";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownStreamCourses } from "../../actions/Courses";
import swal from "sweetalert";

class AddCollegeCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      college_id: this.props.match.params.college_id,
      college: this.props.match.params.college,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetDropDownStreams();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "stream_id") {
      console.log(value);
      this.props.onGetDropDownStreamCourses(value);
    }
  };

  onAddCollegeCourseSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new college course?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_col_course_form")
        );
        this.props.onAddNewCollegeCourse(formData);
      }
    });
  };

  render() {
    const { streams, courses } = this.props;

    const { college, college_id } = this.state;

    let streams_list = [];

    if (streams && streams !== "" && streams.length > 0) {
      streams_list = streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.name}
        </option>
      ));
    }

    let courses_list = [];

    if (courses && courses !== "" && courses.length > 0) {
      courses_list = courses.map((course) => (
        <option key={course.id} value={course.id}>
          {course.course_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/college_courses/" + college + "/" + college_id}>
                      Courses
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Course
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Course </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={
                  "/college_courses/" +
                  college +
                  "/" +
                  college_id +
                  "/bulk_upload/"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          <h6 className="text-center mt-0 page_header text-capitalize">
            Add course in {college}
          </h6>

          {/*  Add new col_course form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-7 col-12 card p-3">
              <br />

              <form
                id="add_col_course_form"
                onSubmit={this.onAddCollegeCourseSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <select
                    name="stream_id"
                    className="form-control"
                    onChange={this.onChangeHandler}
                    required
                  >
                    <option value="">Select stream</option>
                    {streams_list}
                  </select>
                </div>

                <div className="form-group">
                  <select name="course_id" className="form-control" required>
                    <option value="">Select course</option>
                    {courses_list}
                  </select>
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    name="total_seats"
                    placeholder="Total seats"
                    className="form-control"
                  />
                </div>

                <input
                  type="hidden"
                  name="college_id"
                  defaultValue={this.state.college_id}
                  className="form-control"
                />

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add College Course
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    streams: state.stream.streams,
    courses: state.course.courses,
  };
};

const mapActionsRoProps = {
  onGetDropDownStreams: getDropDownStreams,
  onGetDropDownStreamCourses: getDropDownStreamCourses,
  onAddNewCollegeCourse: addNewCollegeCourse,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddCollegeCourse);
