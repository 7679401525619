import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";
import { onGetLevelsSuccess } from "./Courses";
import { onGetStreamsSuccess } from "./Streams";

export const GET_COL_COURSES_SUCCESS = "GET_COL_COURSES_SUCCESS";
export const GET_COL_COURSE_SUCCESS = "GET_COL_COURSE_SUCCESS";

export const getAllCollegeCourses = (college_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_college_courses/" + college_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCollegeCoursesSuccess(response.data.college_courses));
          dispatch(onGetStreamsSuccess(response.data.streams));
          dispatch(onGetLevelsSuccess(response.data.course_levels));
          dispatch(
            addPaginationValues(
              response.data.total_count,
              response.data.page_count,
              response.data.page_size
            )
          );
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

export const getCollegeCourseDetails = (col_course_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_college_course_details/" + col_course_id).then(
      (response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCollegeCourseSuccess(response.data.college_course));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      }
    );
  };
};

// Bulk upload api call
export const bulkUploadCollegeCourses = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/bulk_upload_college_courses", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const addNewCollegeCourse = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_college_course", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCollegeCourse = (col_course_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_college_course/" + col_course_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCollegeCourse = (college_id, col_course_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_college_course/" + col_course_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllCollegeCourses(college_id));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCollegeCoursesSuccess = (data) => {
  return {
    type: GET_COL_COURSES_SUCCESS,
    payload: {
      col_courses: data,
    },
  };
};

export const onGetCollegeCourseSuccess = (col_course) => {
  return {
    type: GET_COL_COURSE_SUCCESS,
    payload: {
      col_course: col_course,
    },
  };
};
