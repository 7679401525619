import React from "react";
import "./Courses.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDropDownCourseLevels,
  getCourseDetails,
  updateCourse,
} from "../../actions/Courses";
import swal from "sweetalert";

class EditCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stream_id: this.props.match.params.stream_id,
      stream: this.props.match.params.stream,
      course_id: this.props.match.params.course_id,
      course: this.props.course,
      levels: this.props.levels,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetCourseDetails(this.state.course_id);
    this.props.onGetDropDownCourseLevels();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let course = this.props.course;
    course[name] = value;
    this.setState({ course: course });
  };

  onUpdateCourseSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this course?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_course_form")
        );
        this.props.onUpdateCourse(this.state.course_id, formData);
      }
    });
  };

  render() {
    const course = this.props.course;
    const { stream, stream_id } = this.state;
    let course_levels = [];

    if (
      this.props.levels &&
      this.props.levels !== "" &&
      this.props.levels.length > 0
    ) {
      course_levels = this.props.levels.map((level) => (
        <option key={level.id} value={level.id}>
          {level.level_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/streams">Streams</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/courses/" + stream + "/" + stream_id}>
                      Courses
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Course
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header">Edit {stream} Course</h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={"/courses/" + stream + "/" + stream_id + "/bulk_upload"}
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link to={"/courses/" + stream + "/" + stream_id + "/add_course"}>
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit course form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_course_form"
                onSubmit={this.onUpdateCourseSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">Course Name : </label>
                      <input
                        type="text"
                        name="course_name"
                        placeholder="Course Name"
                        value={course.course_name ? course.course_name : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Course Mode : </label>
                      <input
                        type="text"
                        name="course_mode"
                        placeholder="Course Mode"
                        value={course.course_mode ? course.course_mode : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Course Level : </label>
                      <select
                        name="course_level"
                        value={course.course_level ? course.course_level : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      >
                        <option value=""> Select course level</option>
                        {course_levels}
                      </select>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="small_text">
                            Course Duration (in months) :
                          </label>
                          <input
                            type="text"
                            name="course_duration"
                            placeholder="Course Duration"
                            value={
                              course.course_duration
                                ? course.course_duration
                                : ""
                            }
                            onChange={this.onChangeHandler}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group">
                          <label className="small_text">Course Status :</label>
                          <select
                            name="course_status"
                            value={
                              course.course_status ? course.course_status : ""
                            }
                            onChange={this.onChangeHandler}
                            className="form-control"
                          >
                            <option value="1"> Active </option>
                            <option value="0"> Inactive </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">Course Description :</label>
                      <textarea
                        name="course_description"
                        rows="4"
                        placeholder="Course Description"
                        value={
                          course.course_description
                            ? course.course_description
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Course Eligibility :</label>
                      <textarea
                        name="course_eligibility"
                        rows="4"
                        placeholder="Course Eligibility"
                        value={
                          course.course_eligibility
                            ? course.course_eligibility
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <input
                      type="hidden"
                      name="course_stream"
                      className="form-control"
                      defaultValue={
                        course.course_stream ? course.course_stream : ""
                      }
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Course
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    course: state.course.course,
    levels: state.course.levels,
  };
};

const mapActionsRoProps = {
  onGetCourseDetails: getCourseDetails,
  onGetDropDownCourseLevels: getDropDownCourseLevels,
  onUpdateCourse: updateCourse,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditCourse);
