import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";

export const getStreamCourses = (stream_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_stream_courses/" + stream_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCoursesSuccess(response.data.courses));
          dispatch(onGetLevelsSuccess(response.data.course_levels));
          dispatch(
            addPaginationValues(
              response.data.total_count,
              response.data.page_count,
              response.data.page_size
            )
          );
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const getDropDownCourseLevels = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_course_levels_dropdown")
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetLevelsSuccess(response.data.course_levels));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const getDropDownCourses = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_courses")
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCoursesSuccess(response.data.courses));
        } else {
          dispatch(onDisplayError(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, try again later"));
      });
  };
};

export const getDropDownStreamCourses = (stream_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_stream_courses/" + stream_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCoursesSuccess(response.data.courses));
        } else {
          dispatch(onDisplayError(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, try again later"));
      });
  };
};

export const getCourseDetails = (course_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_course_details/" + course_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCourseSuccess(response.data.course));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

// Bulk upload api call
export const bulkUploadCourses = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/bulk_upload_courses", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError(error.message));
      });
  };
};

export const addNewCourse = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_course", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCourse = (course_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_course/" + course_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCourse = (stream_id, course_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_course/" + course_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getStreamCourses(stream_id));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCoursesSuccess = (data) => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: {
      courses: data,
    },
  };
};

export const onGetCourseSuccess = (course) => {
  return {
    type: GET_COURSE_SUCCESS,
    payload: {
      course: course,
    },
  };
};

export const onGetLevelsSuccess = (course_levels) => {
  return {
    type: GET_LEVELS_SUCCESS,
    payload: {
      course_levels: course_levels,
    },
  };
};
