import React from "react";
import "./CourseFees.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllCourseFees, deleteCourseFee } from "../../actions/CourseFees";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import $ from "jquery";

class CourseFees extends React.Component {
  constructor(props) {
    super(props);
    const { params } = props.match;
    this.state = {
      activePage: 1,
      college: params.college,
      college_id: params.college_id,
      col_course_id: params.col_course_id,
      course: params.course,
    };

    this.deleteCourseFee = this.deleteCourseFee.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.onGetCourseFees(this.state.col_course_id);
  }

  deleteCourseFee = (course_fee_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this course fee permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteCourseFee(this.state.col_course_id, course_fee_id);
      }
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $("#search_filter_form").serialize();
    params += "&page_no=" + pageNumber;
    this.props.onGetCourseFees(this.state.col_course_id + "?" + params);
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if (e.target.name === "page_size_changer") {
      $("#page_size").val(e.target.value);
    }
    var params = $("#search_filter_form").serialize();
    this.props.onGetCourseFees(this.state.col_course_id + "?" + params);
  };

  render() {
    const { college, college_id, course, col_course_id } = this.state;
    const { course_fees, categories, total_count, page_count, page_size } =
      this.props;

    let course_fees_display = {};
    // let years_list = [];
    let categories_list = [];
    let pagination_display = "";

    // Fees years for filters
    // if(years && years !== '' && years.length > 0)
    // {
    //   years_list = years.map((year) => (
    //     <option key={year.id} value={year.id}> {year.name} </option>
    //   ));
    // }

    // Fees categories for filters
    if (categories && categories !== "" && categories.length > 0) {
      categories_list = categories.map((cat) => (
        <option key={cat.id} value={cat.id}>
          {cat.category}
        </option>
      ));
    }

    if (course_fees && course_fees !== "" && course_fees.length > 0) {
      course_fees_display = course_fees.map((course_fee, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          {console.log(course_fee.year4)}
          <td>{course_fee.category !== "" ? course_fee.category : ""}</td>
          <td>{course_fee.year}</td>
          <td>{course_fee.currency}</td>
          <td>{course_fee.year1}</td>
          <td>{course_fee.year2}</td>
          <td>{course_fee.year3}</td>
          <td>{course_fee.year4 === 0 ? "N/A" : course_fee.year4}</td>
          <td>{course_fee.year5 === 0 ? "N/A" : course_fee.year5}</td>
          {this.props.admin_info.role == 1 && <td>{course_fee.first_name}</td>}

          <td>
            <Link
              to={
                "/course_fees/" +
                college +
                "/" +
                college_id +
                "/" +
                course +
                "/" +
                col_course_id +
                "/edit_course_fee/" +
                course_fee.id
              }
            >
              <button className="icon_button" title="Edit">
                <i className="fa fa-edit text-primary"></i>
              </button>
            </Link>
          </td>
          {this.props.admin_info.role == 1 && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.deleteCourseFee(course_fee.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      course_fees_display = (
        <tr>
          <td colSpan="11" className="text-center">
            No course fees found.
          </td>
        </tr>
      );
    }

    // Show pagination only if values are more than 10
    if (total_count > page_size) {
      pagination_display = (
        <div className="row pt-1">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
            <div className="form-group d-inline">
              <label className="small_text">Page size : </label>
              <select
                name="page_size_changer"
                className="form-control ml-2 w-auto d-inline"
                value={page_size}
                onChange={this.onApplyFilters}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <p className="small_text d-inline ml-2">
              Showing {page_count} of {total_count} fees.
            </p>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
            <div className="pagination_container table-responsive">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={page_size}
                totalItemsCount={total_count}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      );
    } else {
      pagination_display = (
        <p className="small_text d-inline ml-2">
          Showing {page_count} of {total_count} fees.
        </p>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/college_courses/" + college + "/" + college_id}>
                      Courses
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Fees
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Course fees </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={
                  "/course_fees/" +
                  college +
                  "/" +
                  college_id +
                  "/" +
                  course +
                  "/" +
                  col_course_id +
                  "/bulk_upload"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Bulk Upload <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link
                to={
                  "/course_fees/" +
                  college +
                  "/" +
                  college_id +
                  "/" +
                  course +
                  "/" +
                  col_course_id +
                  "/add_course_fee"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          <h6 className="text-center mt-0 page_header text-capitalize">
            {college} [{course}]
          </h6>

          {/* filters, sort and search container */}
          <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select
                    name="sort_key"
                    className="form-control ml-2 w-auto d-inline"
                    onChange={this.onApplyFilters}
                  >
                    <option value="1">Years H - L</option>
                    <option value="2">Years L - H</option>
                    <option value="3">Newest First</option>
                    <option value="4">Oldest First</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input
                  type="hidden"
                  name="page_size"
                  id="page_size"
                  value={page_size}
                />
                <div className="row">
                  {/* <div className="col">
                    <select name="year" className="form-control" onChange={this.onApplyFilters}>
                      <option value="">All years</option>
                      {years_list}
                    </select>
                  </div> */}
                  <div className="col">
                    <select
                      name="cat_id"
                      className="form-control"
                      onChange={this.onApplyFilters}
                    >
                      <option value="">All categories</option>
                      {categories_list}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input
                    type="search"
                    name="search_key"
                    className="filter_key form-control"
                    placeholder="Search by fees, year or currency"
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <button
                      className="search_btn btn-primary px-3"
                      type="button"
                      onClick={this.onApplyFilters}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* Display all course fees table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">Id</th>
                <th width="15%">Category</th>
                <th width="15%">Year</th>
                <th width="15%">Currency</th>
                <th width="10%">Year 1</th>
                <th width="10%">Year 2</th>
                <th width="10%">Year 3</th>
                <th width="10%">Year 4</th>
                <th width="10%">Year 5</th>
                {this.props.admin_info.role == 1 && (
                  <th width="5%">Added_By</th>
                )}
                <th width="5%">Edit</th>
                {this.props.admin_info.role == 1 && <th width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {/* Display all the course_fees */}
              {course_fees_display}
            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    course_fees: state.course_fee.course_fees,
    years: state.course_fee.years,
    categories: state.course_fee.categories,
    total_count: state.paginate.total_count,
    page_count: state.paginate.page_count,
    page_size: state.paginate.page_size,
  };
};

const mapActionsRoProps = {
  onGetCourseFees: getAllCourseFees,
  onDeleteCourseFee: deleteCourseFee,
};

export default connect(mapStatesToProps, mapActionsRoProps)(CourseFees);
