import React from "react";
import "./Banners.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getBannerDetails, updateBanner } from "../../actions/Banners";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";
import { BASE_URL } from "../../utils/ApiService";
import Calendar from "react-calendar";
import { dateFormats, formatDate } from "../../utils/DateFormat";

class EditBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: "",
      banner_id: this.props.match.params.banner_id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetBannerDetails(this.state.banner_id);
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let banner = this.props.banner;
    banner[name] = value;

    if (name === "image") {
      this.setState({ imagePath: URL.createObjectURL(e.target.files[0]) });
      banner[name] = "";
    }

    this.setState({ banner });
  };

  onDateChange = (date, type = 1) => {
    let banner = this.props.banner;
    const newDate = formatDate(date, false, dateFormats.YEAR_MONTH_DATE);

    if (type === 1) {
      banner["start_date"] = newDate;
      banner["end_date"] = newDate;
    } else {
      banner["end_date"] = newDate;
    }

    this.setState({ banner });
  };

  onUpdateBannerSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this banner?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_banner_form")
        );
        this.props.onUpdateBanner(this.state.banner_id, formData);
      }
    });
  };

  render() {
    const { streams, levels, banner } = this.props;
    const { imagePath } = this.state;
    const imageURL = !!imagePath
      ? imagePath
      : !!banner.image
      ? BASE_URL.IMAGE + banner.image
      : "";

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/banners">Banners</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Banner
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Banner </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/banners/add_banner">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit banner form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_banner_form"
                onSubmit={this.onUpdateBannerSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">{"Title *"}</label>
                      <textarea
                        name="title"
                        rows="1"
                        placeholder="Banner Title"
                        className="form-control"
                        value={!!banner.title ? banner.title : ""}
                        onChange={this.onChangeHandler}
                        required
                      />
                    </div>

                    <div className="row">
                      <div className="col-8 form-group">
                        <label className="small_text">{"Link *"}</label>
                        <input
                          type="text"
                          name="link"
                          placeholder="Link"
                          className="form-control"
                          value={!!banner.link ? banner.link : ""}
                          onChange={this.onChangeHandler}
                          required
                        />
                      </div>
                      <div className="col-4 form-group pt-3">
                        {!!banner.link && (
                          <a
                            href={banner.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-md mt-2 mb-0"
                            title="Click here to open the link"
                          >
                            {"OPEN"}
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"Start Date *"}</label>
                      <input
                        type="hidden"
                        name="start_date"
                        placeholder="Start Date"
                        defaultValue={
                          !!banner.start_date ? banner.start_date : ""
                        }
                        className="form-control"
                        required
                      />
                      <Calendar
                        onChange={(date) => this.onDateChange(date)}
                        value={
                          !!banner.start_date
                            ? new Date(banner.start_date)
                            : new Date()
                        }
                        minDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text">{"Stream *"}</label>
                        <select
                          name="stream"
                          className="form-control"
                          value={!!banner.stream ? banner.stream : ""}
                          onChange={this.onChangeHandler}
                          required
                        >
                          <option value="">Select stream</option>
                          {streams && streams.length > 0
                            ? streams.map((stream) => (
                                <option key={stream.id} value={stream.id}>
                                  {stream.name}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Course Level *"}</label>
                        <select
                          name="course_level"
                          className="form-control"
                          value={
                            !!banner.course_level ? banner.course_level : ""
                          }
                          onChange={this.onChangeHandler}
                          required
                        >
                          <option value="">Select course level</option>
                          {levels && levels.length > 0
                            ? levels.map((level) => (
                                <option key={level.id} value={level.id}>
                                  {level.code}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Status *"}</label>
                        <select
                          name="status"
                          className="form-control"
                          value={!!banner.status ? banner.status : ""}
                          onChange={this.onChangeHandler}
                          required
                        >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-8">
                        <label className="small_text">{"Image * :"}</label>
                        <input
                          type="file"
                          name="image"
                          onChange={this.onChangeHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col">
                        {!!imageURL && (
                          <img
                            src={imageURL}
                            className="img-fluid img-thumbnail"
                            alt="Banner"
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"End Date *"}</label>
                      <input
                        type="hidden"
                        name="end_date"
                        placeholder="End Date"
                        defaultValue={!!banner.end_date ? banner.end_date : ""}
                        className="form-control"
                        required
                      />
                      <Calendar
                        onChange={(date) => this.onDateChange(date, 2)}
                        value={
                          !!banner.end_date
                            ? new Date(banner.end_date)
                            : !!banner.start_date
                            ? new Date(banner.start_date)
                            : new Date()
                        }
                        minDate={
                          !!banner.start_date
                            ? new Date(banner.start_date)
                            : new Date()
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Banner
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    streams: state.stream.streams,
    levels: state.course.levels,
    banner: state.banner.banner,
  };
};

const mapActionsRoProps = {
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onGetBannerDetails: getBannerDetails,
  onUpdateBanner: updateBanner,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditBanner);
