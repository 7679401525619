import { GET_NOTIFICATIONS_SUCCESS } from "../actions/Notifications";

const initialState = {
  notifications: [],
};

export default function Notification_reducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, { notifications: payload.notifications });

    default:
      return state;
  }
}
