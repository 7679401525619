import React from "react";
import "./ConsultantColleges.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getConsultantCollegeDetails,
  updateConsultantCollege,
} from "../../actions/ConsultantColleges";
import { getDropDownColleges } from "../../actions/Colleges";
import swal from "sweetalert";

class EditConsultantCollege extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultant: this.props.match.params.consultant,
      cons_id: this.props.match.params.cons_id,
      cons_col_id: this.props.match.params.cons_col_id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetConsultantCollegeDetails(this.state.cons_col_id);
    this.props.onGetDropDownColleges();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let cons_college = this.props.cons_college;
    cons_college[name] = value;
    this.setState({ cons_college: cons_college });
  };

  onUpdateConsultantCollegeSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this consultant college?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_cons_form"));
        this.props.onUpdateConsultantCollege(this.state.cons_col_id, formData);
      }
    });
  };

  render() {
    const { consultant, cons_id } = this.state;
    const { colleges, cons_college } = this.props;
    let colleges_list = [];

    if (colleges && colleges.length > 0) {
      colleges_list = colleges.map((college) => (
        <option key={college.id} value={college.id}>
          {college.col_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consultants">Consultants</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/consultant_colleges/" + consultant + "/" + cons_id}
                    >
                      Colleges
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit College
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header">
                Edit Consultant College
              </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link
                to={
                  "/consultant_colleges/" +
                  consultant +
                  "/" +
                  cons_id +
                  "/add_college"
                }
              >
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit cons_college form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="edit_cons_form"
                onSubmit={this.onUpdateConsultantCollegeSubmit}
                autoComplete="off"
              >
                <input
                  type="hidden"
                  name="cons_id"
                  className="form-control"
                  defaultValue={cons_college.cons_id}
                />

                <div className="form-group">
                  <label className="small_text"> College : </label>
                  <select
                    name="cons_college"
                    value={
                      cons_college.cons_college ? cons_college.cons_college : ""
                    }
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  >
                    <option value=""> Select college </option>
                    {colleges_list}
                  </select>
                </div>

                <div className="form-group">
                  <label className="small_text"> Status : </label>
                  <select
                    name="cons_status"
                    value={
                      cons_college.cons_status ? cons_college.cons_status : ""
                    }
                    onChange={this.onChangeHandler}
                    className="form-control"
                  >
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update College
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    colleges: state.college.colleges,
    cons_college: state.cons_college.cons_college,
  };
};

const mapActionsRoProps = {
  onGetDropDownColleges: getDropDownColleges,
  onGetConsultantCollegeDetails: getConsultantCollegeDetails,
  onUpdateConsultantCollege: updateConsultantCollege,
};

export default connect(
  mapStatesToProps,
  mapActionsRoProps
)(EditConsultantCollege);
