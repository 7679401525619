import { GET_SUPER_ADMINS_SUCCESS, GET_SUPER_ADMIN_SUCCESS } from '../actions/SuperAdmin';

const initialState = {
    admins : [],
    admin : {}
}

export default function SuperAdmin_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_SUPER_ADMIN_SUCCESS :
          
            return Object.assign({}, state, { admin : payload.admin });

        case GET_SUPER_ADMINS_SUCCESS :
            return Object.assign({}, state, { admins : payload.admins });

        default :
            return state;
    }
}
