import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_CONSULTANTS_SUCCESS = "GET_CONSULTANTS_SUCCESS";
export const GET_CONSULTANT_SUCCESS = "GET_CONSULTANT_SUCCESS";

export const getAllConsultants = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_consultants" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetConsultantsSuccess(response.data.consultants));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getDropDownConsultants = (host_url) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_consultants").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetConsultantsSuccess(response.data.consultants));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getConsultantDetails = (cons_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_consultant_details/" + cons_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetConsultantSuccess(response.data.consultant));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewConsultant = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_consultant", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateConsultant = (consultant_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_consultant/" + consultant_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteConsultant = (consultant_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_consultant/" + consultant_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllConsultants(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetConsultantsSuccess = (data) => {
  return {
    type: GET_CONSULTANTS_SUCCESS,
    payload: {
      consultants: data,
    },
  };
};

export const onGetConsultantSuccess = (consultant) => {
  return {
    type: GET_CONSULTANT_SUCCESS,
    payload: {
      consultant: consultant,
    },
  };
};
