import React from "react";
import "./Banners.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewBanner } from "../../actions/Banners";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";
import Calendar from "react-calendar";
import { dateFormats, formatDate } from "../../utils/DateFormat";

class AddBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: "",
      linkPath: "",
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount = () => {
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
  };

  onAddBannerSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new banner?",
      buttons: ["Cancel", "Add"],
    }).then((response) => {
      if (response) {
        var formData = new FormData(document.getElementById("add_banner_form"));
        this.props.onAddNewBanner(formData);
      }
    });
  };

  onInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "link") {
      this.setState({ linkPath: value });
    } else if (name === "image") {
      this.setState({ imagePath: URL.createObjectURL(e.target.files[0]) });
    }
  };

  onDateChange = (date, type = 1) => {
    const newDate = formatDate(date, false, dateFormats.YEAR_MONTH_DATE);
    if (type === 1) {
      this.setState({ startDate: newDate, endDate: newDate });
    } else {
      this.setState({ endDate: newDate });
    }
  };

  render() {
    const { streams, levels } = this.props;
    const { imagePath, linkPath, startDate, endDate } = this.state;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/banners">Banners</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Banner
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Banner </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new banner form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="add_banner_form"
                onSubmit={this.onAddBannerSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">{"Title *"}</label>
                      <textarea
                        name="title"
                        rows="1"
                        placeholder="Banner Title"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row">
                      <div className="col-8 form-group">
                        <label className="small_text">{"Link *"}</label>
                        <input
                          type="text"
                          name="link"
                          placeholder="Link"
                          className="form-control"
                          required
                          onChange={this.onInputChange}
                        />
                      </div>
                      <div className="col-4 form-group pt-3">
                        {!!linkPath && (
                          <a
                            href={linkPath}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-md mt-2 mb-0"
                            title="Click here to open the link"
                          >
                            {"OPEN"}
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"Start Date *"}</label>
                      <input
                        type="hidden"
                        name="start_date"
                        placeholder="Start Date"
                        defaultValue={startDate}
                        className="form-control"
                        required
                      />
                      <Calendar
                        onChange={(date) => this.onDateChange(date)}
                        value={!!startDate ? new Date(startDate) : new Date()}
                        minDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text">{"Stream *"}</label>
                        <select name="stream" className="form-control" required>
                          <option value="">Select stream</option>
                          {streams && streams.length > 0
                            ? streams.map((stream) => (
                                <option key={stream.id} value={stream.id}>
                                  {stream.name}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Course Level *"}</label>
                        <select
                          name="course_level"
                          className="form-control"
                          required
                        >
                          <option value="">Select course level</option>
                          {levels && levels.length > 0
                            ? levels.map((level) => (
                                <option key={level.id} value={level.id}>
                                  {level.code}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Status *"}</label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-8">
                        <label className="small_text">{"Image * :"}</label>
                        <input
                          type="file"
                          name="image"
                          onChange={this.onInputChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col">
                        {!!imagePath && (
                          <img
                            src={imagePath}
                            className="img-fluid img-thumbnail"
                            alt="Banner"
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"End Date *"}</label>
                      <input
                        type="hidden"
                        name="end_date"
                        placeholder="End Date"
                        defaultValue={endDate}
                        className="form-control"
                        required
                      />
                      <Calendar
                        onChange={(date) => this.onDateChange(date, 2)}
                        value={
                          !!endDate
                            ? new Date(endDate)
                            : !!startDate
                            ? new Date(startDate)
                            : new Date()
                        }
                        minDate={!!startDate ? new Date(startDate) : new Date()}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Banner
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    streams: state.stream.streams,
    levels: state.course.levels,
  };
};

const mapActionsRoProps = {
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onAddNewBanner: addNewBanner,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddBanner);
