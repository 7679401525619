import React from "react";
import "./Users.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetails,
  updateUser,
  changeUserPassword,
} from "../../actions/Users";
import { onDisplayError } from "../../actions/Login";
import { getDropDownStates } from "../../actions/States";
import { getStateAuthorities } from "../../actions/Authorities";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import { getDropDownMemberPlans } from "../../actions/MemberPlans";
import swal from "sweetalert";
import $ from "jquery";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user_id: this.props.match.params.user_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetUserDetails(this.state.user_id);
    this.props.onGetDropDownStates();
    this.props.onGetDropDownAuthorities();
    this.props.onGetDropDownStreams();
    this.props.onGetDropDownCourseLevels();
    this.props.onGetDropDownMemberPlans();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let user = this.props.user;
    user[name] = value;
    this.setState({ user: user });
  };

  onUpdateUserSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this user?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_user_form"));
        this.props.onUpdateUser(this.state.user_id, formData);
      }
    });
  };

  onChangePasswordSubmit = (e) => {
    e.preventDefault();
    if ($("#new_password").val().length < 8) {
      this.props.onDisplayErroMessage(
        "Password must have atleast 8 characters"
      );
    } else if ($("#new_password").val() !== $("#confirm_password").val()) {
      this.props.onDisplayErroMessage(
        "Confirm password must be same as password"
      );
    } else {
      swal({
        title: "Are you sure?",
        text: "To update new password?",
        buttons: ["Cancel", "Update"],
      }).then((willDelete) => {
        if (willDelete) {
          var formData = new FormData(
            document.getElementById("change_password_form")
          );
          this.props.onChangePassword(this.state.user_id, formData);
        }
      });
    }
  };

  render() {
    const { user, states, authorities, streams, levels, plans } = this.props;
    let states_list = [];
    let authorities_list = [];
    let streams_list = [];
    let levels_list = [];
    let plans_list = [];

    if (states && states !== "" && states.length > 0) {
      states_list = states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.state_name}
        </option>
      ));
    }

    if (authorities && authorities !== "" && authorities.length > 0) {
      authorities_list = authorities.map((author) => (
        <option key={author.id} value={author.id}>
          {author.state_name !== ""
            ? author.state_name + " - " + author.author_name
            : author.author_name}
        </option>
      ));
    }

    if (streams && streams !== "" && streams.length > 0) {
      streams_list = streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.name}
        </option>
      ));
    }

    if (levels && levels !== "" && levels.length > 0) {
      levels_list = levels.map((level) => (
        <option key={level.id} value={level.id}>
          {level.level_name}
        </option>
      ));
    }

    if (plans && plans !== "" && plans.length > 0) {
      plans_list = plans.map((plan) => (
        <option key={plan.id} value={plan.id}>
          {plan.plan_name + " => " + plan.plan_price}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/users">Users</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit User
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit User </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/users/add_user">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit user form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_user_form"
                onSubmit={this.onUpdateUserSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text"> First Name : </label>
                        <input
                          type="text"
                          name="fname"
                          placeholder="First Name *"
                          value={user.fname ? user.fname : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          autoFocus
                          required
                        />
                      </div>

                      <div className="col">
                        <label className="small_text"> Last Name : </label>
                        <input
                          type="text"
                          name="lname"
                          placeholder="Last Name *"
                          value={user.lname ? user.lname : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID : </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email ID *"
                        value={user.email ? user.email : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Mobile Number : </label>
                      <input
                        type="number"
                        name="mobile"
                        placeholder="Mobile Number *"
                        value={user.mobile ? user.mobile : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> State : </label>
                      <select
                        name="state"
                        value={user.state ? user.state : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="">Select state</option>
                        {states_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Interested Authorities :
                      </label>
                      <select
                        name="int_couns_authors[]"
                        multiple
                        value={
                          user.int_couns_authors
                            ? JSON.parse(user.int_couns_authors)
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        {authorities_list}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> City : </label>
                      <input
                        type="text"
                        name="city"
                        placeholder="City Name"
                        value={user.city ? user.city : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Interested Streams :</label>
                      <select
                        name="int_streams[]"
                        multiple
                        value={
                          user.int_streams ? JSON.parse(user.int_streams) : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        {streams_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Interested Course Level :
                      </label>
                      <select
                        name="int_course_level"
                        value={
                          user.int_course_level ? user.int_course_level : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="">Interested course level</option>
                        {levels_list}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Active Membership Plan :
                      </label>
                      <select
                        name="user_plan"
                        value={user.user_plan ? user.user_plan : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value="">Select membership plan</option>
                        {plans_list}
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> User Type : </label>
                        <select
                          name="user_type"
                          value={user.user_type ? user.user_type : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value=""> Select user type </option>
                          <option value="0"> Unpaid </option>
                          <option value="1"> Paid </option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status : </label>
                        <select
                          name="status"
                          value={user.status ? user.status : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update User
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>

          {/*  Change user password form  */}
          <div className="row add_container form_container mt-4">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <h5 className="text-center page_header text-uppercase">
                Change Password
              </h5>

              <form
                id="change_password_form"
                onSubmit={this.onChangePasswordSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> New Password : </label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      placeholder="New Password"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> Confirm Password : </label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-2">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Password
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    states: state.state.states,
    authorities: state.authority.authorities,
    streams: state.stream.streams,
    levels: state.course.levels,
    plans: state.plan.member_plans,
    user: state.user.user,
  };
};

const mapActionsRoProps = {
  onGetUserDetails: getUserDetails,
  onGetDropDownStates: getDropDownStates,
  onGetDropDownAuthorities: getStateAuthorities,
  onGetDropDownStreams: getDropDownStreams,
  onGetDropDownCourseLevels: getDropDownCourseLevels,
  onGetDropDownMemberPlans: getDropDownMemberPlans,
  onDisplayErroMessage: onDisplayError,
  onUpdateUser: updateUser,
  onChangePassword: changeUserPassword,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditUser);
