import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";
import { onGetStatesSuccess } from "./States";

export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS";
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS";

// Get all universities api call
export const getAllUniversities = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_universities" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetUniversitiesSuccess(response.data.universities));
        dispatch(onGetStatesSuccess(response.data.states));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

// Get universities api call
export const getDropDownUniversities = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_dropdown_universities").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetUniversitiesSuccess(response.data.universities));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

// Get single university details api call
export const getUniversityDetails = (uni_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_university_details/" + uni_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetUniversitySuccess(response.data.university));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

// Bulk upload api call
export const bulkUploadUniversities = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/bulk_upload_universities", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

// Upload unicersities images api call
export const uploadUniversitiesImages = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/upload_university_images", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          console.log(response.data.images);
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

// Add new university api call
export const addNewUniversity = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/add_new_university", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

// Update university api call
export const updateUniversity = (university_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/update_university/" + university_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

// Delete university api call
export const deleteUniversity = (university_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.delete("/delete_university/" + university_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllUniversities(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetUniversitiesSuccess = (data) => {
  return {
    type: GET_UNIVERSITIES_SUCCESS,
    payload: {
      universities: data,
    },
  };
};

export const onGetUniversitySuccess = (data) => {
  return {
    type: GET_UNIVERSITY_SUCCESS,
    payload: {
      university: data,
    },
  };
};
