import { GET_CALLBACKS_SUCCESS, GET_CALLBACK_SUCCESS } from '../actions/Callbacks';

const initialState = {
    callbacks : [],
    callback : {}
}

export default function Callback_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_CALLBACK_SUCCESS :
            return Object.assign({}, state, { callback : payload.callback });

        case GET_CALLBACKS_SUCCESS :
            return Object.assign({}, state, { callbacks : payload.callbacks });

        default :
            return state;
    }
}
