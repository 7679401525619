import { GET_COUPONS_SUCCESS, GET_COUPON_SUCCESS } from '../actions/Coupons';

const initialState = {
    coupons : [],
    coupon : {}
}

export default function Coupon_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_COUPON_SUCCESS :
            return Object.assign({}, state, { coupon : payload.coupon });

        case GET_COUPONS_SUCCESS :
            return Object.assign({}, state, { coupons : payload.coupons });

        default :
            return state;
    }
}
