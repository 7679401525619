import React from "react";
import "./Exams.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewExam } from "../../actions/Exams";
import { getDropDownCountries } from "../../actions/Countries";
import swal from "sweetalert";

class AddExam extends React.Component {
  componentDidMount() {
    this.props.onGetDropDownCountries();
  }

  onAddExamSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new exam?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("add_exam_form"));
        this.props.onAddNewExam(formData);
      }
    });
  };

  render() {
    let countries_list = [];

    if (this.props.countries !== "" && this.props.countries.length > 0) {
      countries_list = this.props.countries.map((country) => (
        <option key={country.id} value={country.id}>
          {country.con_name}
        </option>
      ));
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/exams">Exams</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Exam
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Exam </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new exam form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
              <br />

              <form
                id="add_exam_form"
                onSubmit={this.onAddExamSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="exam_code"
                    placeholder="Exam Code"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="exam_name"
                    placeholder="Exam Name"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <textarea
                    name="exam_description"
                    rows="4"
                    placeholder="Exam Description"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <select name="exam_country" className="form-control">
                    <option value="">Select country</option>
                    {countries_list}
                  </select>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Exam
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    countries: state.country.countries,
  };
};

const mapActionsRoProps = {
  onGetDropDownCountries: getDropDownCountries,
  onAddNewExam: addNewExam,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddExam);
