import React from "react";
import "./Videos.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewVideo } from "../../actions/Videos";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";
import { BASE_URL } from "../../utils/ApiService";
import { Constants } from "../../constants";

class AddVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { youtubeID: "" };
  }

  componentDidMount = () => {
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
  };

  onAddVideoSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new video?",
      buttons: ["Cancel", "Add"],
    }).then((response) => {
      if (response) {
        var formData = new FormData(document.getElementById("add_video_form"));
        this.props.onAddNewVideo(formData);
      }
    });
  };

  onInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "youtube_id") {
      this.setState({ youtubeID: value });
    }
  };

  render() {
    const { streams, levels } = this.props;
    const { youtubeID } = this.state;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/videos">Videos</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Video
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Video </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new video form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="add_video_form"
                onSubmit={this.onAddVideoSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col-8 form-group">
                        <label className="small_text">{"Youtube ID *"}</label>
                        <input
                          type="text"
                          name="youtube_id"
                          placeholder="Youtube ID"
                          className="form-control"
                          minLength={Constants.YOUTUBE_ID_LIMIT}
                          maxLength={Constants.YOUTUBE_ID_LIMIT}
                          required
                          onChange={this.onInputChange}
                        />
                      </div>
                      <div className="col-4 form-group pt-3">
                        {!!youtubeID &&
                          youtubeID.length === Constants.YOUTUBE_ID_LIMIT && (
                            <a
                              href={`${BASE_URL.YOUTUBE}${youtubeID}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-primary btn-md mt-2 mb-0"
                              title="Click here to play video after adding valid youtube id"
                            >
                              {"PREVIEW"}
                            </a>
                          )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"Title *"}</label>
                      <textarea
                        name="title"
                        rows="4"
                        placeholder="Video Title"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text">{"Stream *"}</label>
                        <select name="stream" className="form-control" required>
                          <option value="">Select stream</option>
                          {streams && streams.length > 0
                            ? streams.map((stream) => (
                                <option key={stream.id} value={stream.id}>
                                  {stream.name}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Course Level *"}</label>
                        <select
                          name="course_level"
                          className="form-control"
                          required
                        >
                          <option value="">Select course level</option>
                          {levels && levels.length > 0
                            ? levels.map((level) => (
                                <option key={level.id} value={level.id}>
                                  {level.code}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Status *"}</label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">{"Description *"}</label>
                      <textarea
                        name="description"
                        rows="15"
                        placeholder="Video Description"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Video
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    streams: state.stream.streams,
    levels: state.course.levels,
  };
};

const mapActionsRoProps = {
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onAddNewVideo: addNewVideo,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddVideo);
