import { GET_SENIOR_MANAGERS_SUCCESS, GET_SENIOR_MANAGER_SUCCESS } from '../actions/SeniorManager';

const initialState = {
    admins : [],
    admin : {}
}

export default function Senior_Manager_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_SENIOR_MANAGER_SUCCESS :
            return Object.assign({}, state, { admin : payload.admin });

        case GET_SENIOR_MANAGERS_SUCCESS :
               
            return Object.assign({}, state, { admins : payload.admins });

        default :
            return state;
    }
}
