import React from "react";
import "./Colleges.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  bulkUploadColleges,
  uploadCollegeImages,
} from "../../actions/Colleges";
import swal from "sweetalert";

const SampleExcel = require("./colleges_bulk_excel.csv");

class BulkUpload extends React.Component {
  onBulkUploadSubmit = (e) => {
    console.log(this.props);
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "To upload this college excel file?",
      buttons: ["Cancel", "Uplaod"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("col_bulk_upload_form")
        );
        this.props.onBulkUploadColleges(formData);
        //let token = localStorage.getItem('auth_token');
      }
    });
  };

  onUploadImagesSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To upload this college images?",
      buttons: ["Cancel", "Uplaod"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("col_images_upload_form")
        );
        this.props.onUploadCollegeImages(formData);
      }
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/colleges">Colleges</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Bulk Upload
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Bulk Upload </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <a href={SampleExcel} download="colleges_sample_excel">
                <button type="button" className="btn btn-primary btn-sm">
                  Download sample <i className="ml-1 fa fa-download"></i>
                </button>
              </a>
              <Link to="/colleges/add_college">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="text-center">
            <label className="small_text">
              File size should be less than 2MB. <br /> Excel file should has
              extensions as .xlsx or .xls. <br /> Image file name shoule start
              from College Code, Ex. COL001_logo.png or COL001_banner.jpg.
              <br /> Gallery images are limited to 5, named as Ex :
              COL001_gallery_1.jpg to COL001_gallery_5.jpg.
            </label>
          </div>
          <br />

          {/* BulkUpload bulk upload form  */}
          <div className="row bulk_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="col_bulk_upload_form"
                onSubmit={this.onBulkUploadSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text"> Colleges Excel File : </label>
                  <input
                    type="file"
                    name="colleges_excel"
                    className="form-control"
                    placeholder="Select Excel File"
                    required
                  />
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br />

              <form
                id="col_images_upload_form"
                onSubmit={this.onUploadImagesSubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text"> College Image Files : </label>
                  <input
                    type="file"
                    name="college_images[]"
                    multiple
                    className="form-control"
                    placeholder="Select Images"
                    required
                  />
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    error: state.college.onDisplayError,
    message: state.college.onDisplayMessage,
  };
};

const mapActionsRoProps = {
  onBulkUploadColleges: bulkUploadColleges,
  onUploadCollegeImages: uploadCollegeImages,
};

export default connect(mapStatesToProps, mapActionsRoProps)(BulkUpload);
