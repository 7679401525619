import React from "react";
import "./MemberPlans.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllPlanBenefits,
  addNewBenefit,
  updateBenefit,
  deleteBenefit,
} from "../../actions/PlanBenefits";
import swal from "sweetalert";
import $ from "jquery";

class PlanBenefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = { benefit: "", benefit_id: "" };
    this.handleEditBenefit = this.handleEditBenefit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDeleteBenefit = this.handleDeleteBenefit.bind(this);
  }

  componentDidMount() {
    this.props.onGetPlanBenefits();
  }

  handleAddBenefit = (e) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "To add this new benefit?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("add_benefit_form")
        );
        this.props.onAddNewPlanBenefit(formData);
      }
    });
  };

  handleEditBenefit = (benefit) => {
    this.setState({ benefit: benefit, benefit_id: benefit.id });
    $("#edit_benefit_form").collapse();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const benefit = this.state;
    benefit[name] = value;

    this.setState({ benefit: benefit });
  };

  handleUpdateBenefit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this plan?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_benefit_form")
        );
        this.props.onUpdatePlanBenefit(this.state.benefit_id, formData);
      }
    });
  };

  handleDeleteBenefit = (plan_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this plan permanently?",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    }).then((willDelete) => {
      if (willDelete) {
        this.props.onDeleteBenefit(plan_id);
      }
    });
  };

  render() {
    const { benefits } = this.props;
    const { benefit } = this.state;
    let benefits_display = {};

    if (benefits && benefits !== "" && benefits.length > 0) {
      benefits_display = benefits.map((benefit, index) => (
        <tr key={benefit.id}>
          <td>{index + 1}</td>
          <td>{benefit.benefit}</td>
          <td>
            <button
              className="icon_button"
              title="Edit"
              onClick={() => this.handleEditBenefit(benefit)}
            >
              <i className="fa fa-edit text-primary"></i>
            </button>
          </td>
          {this.props.admin_info.user_type === "1" && (
            <td>
              <button
                className="icon_button"
                title="Delete"
                onClick={() => this.handleDeleteBenefit(benefit.id)}
              >
                <i className="fa fa-trash text-danger"></i>
              </button>
            </td>
          )}
        </tr>
      ));
    } else {
      benefits_display = (
        <tr>
          <td colSpan="4" className="text-center">
            No plans found.
          </td>
        </tr>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/member_plans">Plans </Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Benefits
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Plan Benefits </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/member_plans">
                <button type="button" className="btn btn-primary btn-sm">
                  Memeber Plans <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                data-toggle="collapse"
                data-target="#add_benefit_form"
              >
                Add <i className="ml-1 fa fa-plus"></i>
              </button>
            </div>
          </div>

          <div id="add_edit_forms">
            <form
              className="collapse mb-4"
              id="add_benefit_form"
              data-parent="#add_edit_forms"
              onSubmit={this.handleAddBenefit}
              autoComplete="off"
            >
              <div className="row">
                <div className="col"></div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 card pt-3">
                  <div className="form-group">
                    <label className="small_text"> Benefit * : </label>
                    <input
                      type="text"
                      name="benefit"
                      className="form-control"
                      placeholder="Benefit *"
                      required
                    />
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      Add <i className="ml-1 fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </form>

            <form
              className="collapse mb-4"
              id="edit_benefit_form"
              data-parent="#add_edit_forms"
              onSubmit={this.handleUpdateBenefit}
              autoComplete="off"
            >
              <div className="row animated fadeIn">
                <div className="col"></div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 card pt-3">
                  <div className="form-group">
                    <label className="small_text"> Benefit * : </label>
                    <input
                      type="text"
                      name="benefit"
                      className="form-control"
                      value={benefit.benefit ? benefit.benefit : ""}
                      onChange={this.handleInputChange}
                      placeholder="Benefit *"
                      required
                    />
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      Update <i className="ml-1 fa fa-check"></i>
                    </button>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>

          {/* Display all benefits table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th width="5%">Id</th>
                <th width="82%">Benefit</th>
                <th width="8%">Edit</th>
                {this.props.admin_info.user_type === "1" && (
                  <th width="5%">Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {/* Display all the benefits */}
              {benefits_display}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    benefits: state.benefit.benefits,
  };
};

const mapActionsRoProps = {
  onGetPlanBenefits: getAllPlanBenefits,
  onAddNewPlanBenefit: addNewBenefit,
  onUpdatePlanBenefit: updateBenefit,
  onDeleteBenefit: deleteBenefit,
};

export default connect(mapStatesToProps, mapActionsRoProps)(PlanBenefits);
