import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getSuperAdminDetails,
  updateSuperAdmin,
  changeSuperAdminPassword,
} from "../../actions/SuperAdmin";
import { onDisplayError } from "../../actions/Login";
import swal from "sweetalert";
import $ from "jquery";

class EditSuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { admin_id: this.props.match.params.super_admin_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.getSuperAdminDetails(this.state.admin_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let admin = this.props.admin;
    admin[name] = value;
    this.setState({ admin: admin });
  };

  onUpdateAdminSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this admin?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_super_admin_form")
        );
        this.props.updateSuperAdmin(this.state.admin_id, formData);
      }
    });
  };

  onChangePasswordSubmit = (e) => {
    e.preventDefault();
    if ($("#new_password").val().length < 8) {
      this.props.onDisplayErroMessage(
        "Password must have atleast 8 characters"
      );
    } else if ($("#new_password").val() !== $("#confirm_password").val()) {
      this.props.onDisplayErroMessage(
        "Confirm password must be same as password"
      );
    } else {
      swal({
        title: "Are you sure?",
        text: "To update new password?",
        buttons: ["Cancel", "Update"],
      }).then((willDelete) => {
        if (willDelete) {
          var formData = new FormData(
            document.getElementById("change_password_form")
          );
          this.props.changeSuperAdminPassword(this.state.admin_id, formData);
        }
      });
    }
  };

  render() {
    const { admin } = this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/superadmins">GC Team</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit GC Team
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit SuperAdmins </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/superadmins/add_super_admin">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit admin form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_super_admin_form"
                onSubmit={this.onUpdateAdminSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text"> First Name * : </label>
                        <input
                          type="text"
                          name="first_name"
                          placeholder="First Name *"
                          value={admin.first_name ? admin.first_name : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                          autoFocus
                        />
                      </div>

                      <div className="col">
                        <label className="small_text"> Last Name * : </label>
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Last Name *"
                          value={admin.last_name ? admin.last_name : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col">
                        <label className="small_text"> Gender * : </label>
                        <select
                          name="gender"
                          value={admin.gender ? admin.gender : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                        >
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className="form-group">
                      <label className="small_text"> Email ID * : </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email ID *"
                        value={admin.email ? admin.email : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div> */}
                    <div className="form-group">
                      <div className="row">     
                      <div className="col-md-8">
                      <label className="small_text"> Email ID * : </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email ID *"
                        value={admin.email ? admin.email : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                    <label className="small_text"> User Type * : </label>
                        <select name="role" value={admin.role} onChange={this.onChangeHandler} className="form-control" required>
                          <option value=""> Select user type </option>
                          <option value="1"> SuperAdmin </option>
                          <option value="2"> Admin </option>
                          <option value="3">Sr Management</option>
                          <option value="4">Management</option>
                          <option value="5">Operations Manager</option>
                          <option value="6">Operations Executive</option>
                          <option value="7">Finance Executive</option>
                          <option value="8">Sr Counsellor</option>
                          <option value="9">Counsellor</option>
                          <option value="10">Data Entry Operator</option>
                        </select>
                    </div>
                    </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Mobile Number * : </label>
                      <input
                        type="number"
                        name="phone"
                        value={admin.phone ? admin.phone : ""}
                        onChange={this.onChangeHandler}
                        placeholder="Mobile Number *"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text">
                          Job Description * :
                        </label>
                        <input
                          type="text"
                          name="job_description"
                          className="form-control"
                          value={
                            admin.job_description ? admin.job_description : ""
                          }
                          onChange={this.onChangeHandler}
                          placeholder="Job Description"
                          required
                        />
                        {/* <input type="hidden" value="1" name="role" /> */}
                        <input
                          type="hidden"
                          name="modified_by"
                          defaultValue={this.props.admin_info.id}
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status * : </label>
                        <select
                          name="status"
                          value={admin.status ? admin.status : ""}
                          onChange={this.onChangeHandler}
                          className="form-control"
                          required
                        >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="my-3" />
                <div className="row">
                  <div className="col-md-3">
                    <label className="small_text"> Employee Type * : </label>
                    <select
                      name="employee_type"
                      value={admin.employee_type ? admin.employee_type : ""}
                      onChange={this.onChangeHandler}
                      className="form-control"
                      required
                    >
                      <option value="1"> Permanent </option>
                      <option value="0"> Temparory </option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="small_text"> Department * : </label>
                    <select
                      name="department"
                      value={admin.department ? admin.department : ""}
                      onChange={this.onChangeHandler}
                      className="form-control"
                      required
                    >
                      <option value="1"> Administration </option>
                      <option value="2"> Management </option>
                      <option value="3"> Operations </option>
                      <option value="4"> Counseling </option>
                      <option value="5"> Marketing </option>
                      <option value="6"> Development </option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="small_text"> State * : </label>
                    <input
                      type="text"
                      value={admin.state ? admin.state : ""}
                      onChange={this.onChangeHandler}
                      name="state"
                      placeholder="State"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="small_text"> City * : </label>
                    <input
                      type="text"
                      value={admin.city ? admin.city : ""}
                      onChange={this.onChangeHandler}
                      name="city"
                      placeholder="City"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Admin
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>

          {/*  Change admin password form  */}
          <div className="row add_container form_container mt-5">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <h5 className="text-center page_header text-uppercase">
                Change Password
              </h5>

              <form
                id="change_password_form"
                onSubmit={this.onChangePasswordSubmit}
                autoComplete="off"
              >
                <div className="row mt-2">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> New Password : </label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      placeholder="New Password"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> Confirm Password : </label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Password
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    admin_info: state.admin.admin_info,
    admin: state.superAdmin.admin,
  };
};

const mapActionsRoProps = {
  getSuperAdminDetails: getSuperAdminDetails,
  onDisplayErroMessage: onDisplayError,
  updateSuperAdmin: updateSuperAdmin,
  changeSuperAdminPassword: changeSuperAdminPassword,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditSuperAdmin);
