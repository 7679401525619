import { GET_COURSES_SUCCESS, GET_COURSE_SUCCESS, GET_LEVELS_SUCCESS } from '../actions/Courses';

const initialState = {
    courses : [],
    course : {},
    levels : []
}

export default function Course_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_LEVELS_SUCCESS :
            return Object.assign({}, state, { levels : payload.course_levels });

        case GET_COURSE_SUCCESS :
            return Object.assign({}, state, { course : payload.course });

        case GET_COURSES_SUCCESS :
            return Object.assign({}, state, { courses : payload.courses });

        default :
            return state;
    }
}
