import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_CUTOFFS_SUCCESS = "GET_CUTOFFS_SUCCESS";
export const GET_CUTOFF_SUCCESS = "GET_CUTOFF_SUCCESS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const INSERT_COURSE_SUCCESS = "INSERT_COURSE_SUCCESS";

export const getAllCourseCutoffs = (data) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_cutoffs" + data)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCutoffsSuccess(response.data.cutoffs));
          dispatch(
            addPaginationValues(
              response.data.total_count,
              response.data.page_count,
              response.data.page_size
            )
          );
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const getCutoffFilters = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_cutoff_filters")
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetFilterssSuccess(response.data));
        } else {
          dispatch(onDisplayError(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, try again later"));
      });
  };
};

export const newCourses = (courses) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    if (courses && courses !== "" && courses.length > 0) {
      dispatch(onCourseGetSuccess(courses));
      dispatch(onLoadingFalse());
    } else {
      dispatch(onLoadingFalse());
      dispatch(onDisplayError("Failed, try again later"));
    }
  };
};

export const getCutoffDetails = (cutoff_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_cutoff_details/" + cutoff_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onGetCutoffSuccess(response.data.cutoff));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

// Bulk upload api call
export const bulkUploadCutoffs = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/cutoff_bulk_upload", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const addNewCutoff = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_cutoff", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateCutoff = (cutoff_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_cutoff/" + cutoff_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteCutoff = (cutoff_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_cutoff/" + cutoff_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllCourseCutoffs());
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetCutoffsSuccess = (cutoffs) => {
  return {
    type: GET_CUTOFFS_SUCCESS,
    payload: {
      cutoffs: cutoffs,
    },
  };
};

export const onGetCutoffSuccess = (cutoff) => {
  return {
    type: GET_CUTOFF_SUCCESS,
    payload: {
      cutoff: cutoff,
    },
  };
};
export const onCourseGetSuccess = (data) => {
  return {
    type: INSERT_COURSE_SUCCESS,
    payload: { courses: data },
  };
};
export const onGetFilterssSuccess = (data) => {
  return {
    type: GET_FILTERS_SUCCESS,
    payload: {
      colleges: data.colleges,
      quotas: data.quotas,
      courses: data.courses,
      categories: data.categories,
      rounds: data.rounds,
      years: data.years,
      course_levels: data.course_levels,
      authorities: data.authorities,
    },
  };
};
