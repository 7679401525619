import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";

export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const PLAN_DETAILS_SUCCESS = "PLAN_DETAILS_SUCCESS";

export const getAllMemberPlans = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_all_plans").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetMemberPlansSuccess(response.data.plans));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getDropDownMemberPlans = () => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_dropdown_plans").then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetMemberPlansSuccess(response.data.plans));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getPlanDetails = (plan_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.get("/get_plan_details/" + plan_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetMemberPlanSuccess(response.data.plan));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewMemberPlan = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_plan", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateMemberPlan = (plan_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_plan/" + plan_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteMemberPlan = (plan_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_plan/" + plan_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllMemberPlans(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetMemberPlansSuccess = (data) => {
  return {
    type: GET_PLANS_SUCCESS,
    payload: {
      member_plans: data,
    },
  };
};

export const onGetMemberPlanSuccess = (member_plan) => {
  return {
    type: PLAN_DETAILS_SUCCESS,
    payload: {
      member_plan: member_plan,
    },
  };
};
