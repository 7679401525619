import React from "react";
import "./NewsUpdates.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNewsCategories,
  getNewsDetails,
  updateNewsUpdate,
} from "../../actions/NewsUpdates";
import { getDropDownAuthorities } from "../../actions/Authorities";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";

class EditNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = { news_id: this.props.match.params.news_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetDropDownAuthorities();
    this.props.onGetNewsCategories();
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
    this.props.onGetNewsDetails(this.state.news_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let news_update = this.props.news_update;
    news_update[name] = value;

    this.setState({ news_update: news_update });
  };

  onUpdateNewsUpdateSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this news?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(
          document.getElementById("edit_news_update_form")
        );
        var news_streams = document.getElementsByClassName("news_streams");
        var streams = new Array();

        for (let i = 0; i < news_streams.length; i++) {
          if (news_streams[i].checked) {
            streams.push(news_streams[i].value);
          }
        }

        formData.append("news_streams", streams.toString());
        this.props.onUpdateNewsUpdate(this.state.news_id, formData);
      }
    });
  };

  render() {
    const { authorities, categories, streams, levels, news_update } =
      this.props;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/news_updates">News</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit News
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit News </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/news_updates/news_categories">
                <button type="button" className="btn btn-primary btn-sm">
                  Categories <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
              <Link to="/news_updates/add_news">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit news_update form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_news_update_form"
                onSubmit={this.onUpdateNewsUpdateSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> News title : </label>
                      <textarea
                        name="news_title"
                        rows="2"
                        placeholder="News Title"
                        value={
                          news_update.news_title ? news_update.news_title : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Brief description :</label>
                      <textarea
                        name="brief_descr"
                        rows="3"
                        placeholder="Brief Description"
                        value={
                          news_update.brief_descr ? news_update.brief_descr : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Detailed description :
                      </label>
                      <textarea
                        name="detailed_descr"
                        rows="6"
                        placeholder="Detailed Description"
                        value={
                          news_update.detailed_descr
                            ? news_update.detailed_descr
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text">
                        Counselling authority :
                      </label>
                      <select
                        name="couns_authority"
                        value={
                          news_update.couns_authority
                            ? news_update.couns_authority
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value=""> Select counselling authority</option>
                        {authorities && authorities.length > 0
                          ? authorities.map((author) => (
                              <option key={author.id} value={author.id}>
                                {author.state_name !== ""
                                  ? author.state_name +
                                    " - " +
                                    author.author_name
                                  : author.author_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> News category : </label>
                      <select
                        name="news_category"
                        value={
                          news_update.news_category
                            ? news_update.news_category
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value=""> Select category</option>
                        {categories && categories.length > 0
                          ? categories.map((cat) => (
                              <option key={cat.id} value={cat.id}>
                                {cat.category}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Select streams </label>
                      <div className="row">
                        {streams && streams
                          ? streams.map((stream) => (
                              <div
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                                key={stream.id}
                              >
                                <input
                                  type="checkbox"
                                  className="news_streams"
                                  defaultValue={stream.id}
                                  defaultChecked={
                                    news_update.news_streams
                                      ? news_update.news_streams.includes(
                                          stream.id.toString()
                                        )
                                      : false
                                  }
                                />
                                <span className="ml-1 text-blue">
                                  {stream.name}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course level : </label>
                      <select
                        name="course_level"
                        value={
                          news_update.course_level
                            ? news_update.course_level
                            : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      >
                        <option value=""> Select course level</option>
                        {levels && levels.length > 0
                          ? levels.map((level) => (
                              <option key={level.id} value={level.id}>
                                {level.level_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> News link : </label>
                      <input
                        type="text"
                        name="news_link"
                        placeholder="News Link"
                        value={
                          news_update.news_link ? news_update.news_link : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> News file : </label>
                      <input
                        type="file"
                        name="news_file"
                        onChange={this.onChangeHandler}
                        placeholder="News File"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> News status : </label>
                      <select
                        name="news_status"
                        value={
                          news_update.news_status ? news_update.news_status : ""
                        }
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update News
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    authorities: state.authority.authorities,
    categories: state.category.categories,
    streams: state.stream.streams,
    levels: state.course.levels,
    news_update: state.news.news_update,
  };
};

const mapActionsRoProps = {
  onGetDropDownAuthorities: getDropDownAuthorities,
  onGetNewsCategories: getNewsCategories,
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onGetNewsDetails: getNewsDetails,
  onUpdateNewsUpdate: updateNewsUpdate,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditNews);
