import {
  GET_VIDEOS_SUCCESS,
  VIDEO_DETAILS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
} from "../actions/Videos";

const initialState = {
  videos: [],
  video: {},
  categories: [],
};

export default function Video_reducer(state = initialState, { type, payload }) {
  switch (type) {
    case VIDEO_DETAILS_SUCCESS:
      return Object.assign({}, state, { video: payload.video });

    case GET_VIDEOS_SUCCESS:
      return Object.assign({}, state, { videos: payload.videos });

    case GET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, { categories: payload.categories });

    default:
      return state;
  }
}
