import { Axios } from "../utils/ApiService";
import {
  onLoadingTrue,
  onLoadingFalse,
  onDisplayMessage,
  onDisplayError,
} from "./Login";
import { addPaginationValues } from "./Pagination";

export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";

export const getAllAdmins = (params) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_all_admins" + params).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetAdminsSuccess(response.data.admins));
        dispatch(
          addPaginationValues(
            response.data.total_count,
            response.data.page_count,
            response.data.page_size
          )
        );
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const getAdminDetails = (admin_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.get("/get_admin_details/" + admin_id).then((response) => {
      dispatch(onLoadingFalse());
      if (response.data.code === 200) {
        dispatch(onGetAdminSuccess(response.data.admin));
      } else {
        dispatch(onDisplayError(response.data.error));
      }
    });
  };
};

export const addNewAdmin = (formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/add_new_admin", formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const updateAdmin = (admin_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.post("/update_admin/" + admin_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const changeAdminPassword = (admin_id, formData) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());
    return Axios.post("/change_admin_password/" + admin_id, formData)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(onDisplayMessage(response.data.message));
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const deleteAdmin = (admin_id) => {
  return (dispatch) => {
    dispatch(onLoadingTrue());

    return Axios.delete("/delete_admin/" + admin_id)
      .then((response) => {
        dispatch(onLoadingFalse());
        if (response.data.code === 200) {
          dispatch(getAllAdmins(""));
          setTimeout(function () {
            dispatch(onDisplayMessage(response.data.message));
          }, 1500);
        } else {
          dispatch(onDisplayError(response.data.error));
        }
      })
      .catch((error) => {
        dispatch(onLoadingFalse());
        dispatch(onDisplayError("Failed, Try again later"));
      });
  };
};

export const onGetAdminsSuccess = (admins) => {
  return {
    type: GET_ADMINS_SUCCESS,
    payload: {
      admins: admins,
    },
  };
};

export const onGetAdminSuccess = (admin) => {
  return {
    type: GET_ADMIN_SUCCESS,
    payload: {
      admin: admin,
    },
  };
};
