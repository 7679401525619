import React from "react";
import "./Cities.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCityDetails, updateCity } from "../../actions/Cities";
import swal from "sweetalert";

class Cities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state_id: this.props.match.params.state_id,
      state: this.props.match.params.state,
      city_id: this.props.match.params.city_id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetCityDetails(this.state.city_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let city = this.props.city;
    city[name] = value;
    this.setState({ city: city });
  };

  onUpdateCitySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this city?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_city_form"));
        this.props.onUpdateCity(this.state.city_id, formData);
      }
    });
  };

  render() {
    const { city } = this.props;
    const { state, state_id } = this.state;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/states">States</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/cities/" + state + "/" + state_id}>Cities</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit City
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit City </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/cities/" + state + "/" + state_id + "/add_city"}>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={this.addNewCity}
                >
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit city form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card p-3">
              <br />

              <form
                id="edit_city_form"
                onSubmit={this.onUpdateCitySubmit}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="small_text">City Code : </label>
                  <input
                    type="text"
                    name="city_code"
                    placeholder="City Code"
                    value={city.city_code ? city.city_code : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="small_text">City Name : </label>
                  <input
                    type="text"
                    name="city_name"
                    placeholder="City Name"
                    value={city.city_name ? city.city_name : ""}
                    onChange={this.onChangeHandler}
                    className="form-control"
                    required
                  />
                </div>

                <input
                  type="hidden"
                  name="city_state"
                  defaultValue={city.city_state ? city.city_state : ""}
                />

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update City
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    city: state.city.city,
  };
};

const mapActionsRoProps = {
  onGetCityDetails: getCityDetails,
  onUpdateCity: updateCity,
};

export default connect(mapStatesToProps, mapActionsRoProps)(Cities);
