import React from "react";
import "./Events.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addNewEvent } from "../../actions/Events";
import { getDropDownAuthorities } from "../../actions/Authorities";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";
import Calendar from "react-calendar";

class AddEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { event_starts: "", event_ends: "" };
    this._handleStartDateChange = this._handleStartDateChange.bind(this);
    this._handleEndDateChange = this._handleEndDateChange.bind(this);
  }

  componentDidMount = () => {
    this.props.onGetDropDownAuthorities();
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
  };

  onAddEventSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new event?",
      buttons: ["Cancel", "Add"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("add_event_form"));
        var event_streams = document.getElementsByClassName("event_streams");
        var streams = new Array();

        for (let i = 0; i < event_streams.length; i++) {
          if (event_streams[i].checked) {
            streams.push(event_streams[i].value);
          }
        }

        formData.append("event_streams", streams.toString());
        this.props.onAddNewEvent(formData);
      }
    });
  };

  _handleFormatDate = (date) => {
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  _handleStartDateChange = (date) => {
    var formattedDate = this._handleFormatDate(date);
    this.setState({ event_starts: formattedDate, event_ends: "" });
  };

  _handleEndDateChange = (date) => {
    var formattedDate = this._handleFormatDate(date);
    this.setState({ event_ends: formattedDate });
  };

  render() {
    const { authorities, streams, levels } = this.props;
    const { event_starts, event_ends } = this.state;

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/events">Events</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Add Event
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Event </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right"></div>
          </div>

          {/*  Add new event form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="add_event_form"
                onSubmit={this.onAddEventSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <textarea
                        name="event_name"
                        rows="2"
                        placeholder="Event Name"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        name="event_details"
                        rows="6"
                        placeholder="Event Details"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Event start date : </label>
                      <input
                        type="hidden"
                        name="event_starts"
                        placeholder="Event Start Date"
                        defaultValue={event_starts}
                        className="form-control"
                        required
                      />
                      <Calendar onChange={this._handleStartDateChange} />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <select name="event_authority" className="form-control">
                        <option value=""> Select counselling authority</option>
                        {authorities && authorities.length > 0
                          ? authorities.map((author) => (
                              <option key={author.id} value={author.id}>
                                {author.state_name !== ""
                                  ? author.state_name +
                                    " - " +
                                    author.author_name
                                  : author.author_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Select streams : </label>
                      <div className="row">
                        {streams && streams
                          ? streams.map((stream) => (
                              <div
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                                key={stream.id}
                              >
                                <input
                                  type="checkbox"
                                  className="event_streams"
                                  defaultValue={stream.id}
                                />
                                <span className="ml-1 text-blue">
                                  {stream.name}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <select
                        name="event_level"
                        className="form-control"
                        required
                      >
                        <option value=""> Select course level</option>
                        {levels && levels.length > 0
                          ? levels.map((level) => (
                              <option key={level.id} value={level.id}>
                                {level.level_name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        name="event_status"
                        className="form-control"
                        required
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Event end date : </label>
                      <input
                        type="hidden"
                        name="event_ends"
                        placeholder="Event End Date"
                        defaultValue={event_ends}
                        className="form-control"
                        required
                      />
                      <Calendar
                        onChange={this._handleEndDateChange}
                        minDate={new Date(event_starts)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Add Event
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    authorities: state.authority.authorities,
    streams: state.stream.streams,
    levels: state.course.levels,
  };
};

const mapActionsRoProps = {
  onGetDropDownAuthorities: getDropDownAuthorities,
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onAddNewEvent: addNewEvent,
};

export default connect(mapStatesToProps, mapActionsRoProps)(AddEvent);
