import React from "react";
import "./Videos.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getVideoDetails, updateVideo } from "../../actions/Videos";
import { getDropDownStreams } from "../../actions/Streams";
import { getDropDownCourseLevels } from "../../actions/Courses";
import swal from "sweetalert";
import { Constants } from "../../constants";
import { BASE_URL } from "../../utils/ApiService";

class EditVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video_id: this.props.match.params.video_id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.onGetVideoDetails(this.state.video_id);
    this.props.onGetDropDownStream();
    this.props.onGetDropDownLevels();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let video = this.props.video;
    video[name] = value;

    this.setState({ video });
  };

  onUpdateVideoSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this video?",
      buttons: ["Cancel", "Update"],
    }).then((willDelete) => {
      if (willDelete) {
        var formData = new FormData(document.getElementById("edit_video_form"));
        this.props.onUpdateVideo(this.state.video_id, formData);
      }
    });
  };

  render() {
    const { streams, levels, video } = this.props;

    const imagePath = !!video.image ? BASE_URL.IMAGE + video.image : "";

    return (
      <div className="row">
        <div className="col">
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/videos">Videos</Link>
                  </li>
                  <li className="breadcrumb-item active" area-current="page">
                    Edit Video
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Video </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/videos/add_video">
                <button type="button" className="btn btn-primary btn-sm">
                  Add <i className="ml-1 fa fa-plus"></i>
                </button>
              </Link>
            </div>
          </div>

          {/*  Edit video form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br />

              <form
                id="edit_video_form"
                onSubmit={this.onUpdateVideoSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row">
                      <div className="col-8 form-group">
                        <label className="small_text">{"Youtube ID *"}</label>
                        <input
                          type="text"
                          name="youtube_id"
                          placeholder="Youtube ID"
                          className="form-control"
                          minLength={Constants.YOUTUBE_ID_LIMIT}
                          maxLength={Constants.YOUTUBE_ID_LIMIT}
                          value={!!video.youtube_id ? video.youtube_id : ""}
                          onChange={this.onChangeHandler}
                          required
                        />
                      </div>
                      <div className="col-4 form-group pt-3">
                        {!!video.youtube_id &&
                          video.youtube_id.length ===
                            Constants.YOUTUBE_ID_LIMIT && (
                            <a
                              href={`${BASE_URL.YOUTUBE}${video.youtube_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-primary btn-md mt-2 mb-0"
                              title="Click here to play video after adding valid youtube id"
                            >
                              {"PREVIEW"}
                            </a>
                          )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"Title *"}</label>
                      <textarea
                        name="title"
                        rows="3"
                        placeholder="Video Title"
                        value={!!video.title ? video.title : ""}
                        onChange={this.onChangeHandler}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="row">
                      <div className="col form-group">
                        <label className="small_text">{"Stream *"}</label>
                        <select
                          name="stream"
                          className="form-control"
                          value={!!video.stream ? video.stream : ""}
                          onChange={this.onChangeHandler}
                          required
                        >
                          <option value="">Select stream</option>
                          {streams && streams.length > 0
                            ? streams.map((stream) => (
                                <option key={stream.id} value={stream.id}>
                                  {stream.name}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Course Level *"}</label>
                        <select
                          name="course_level"
                          className="form-control"
                          value={!!video.course_level ? video.course_level : ""}
                          onChange={this.onChangeHandler}
                          required
                        >
                          <option value=""> Select course level</option>
                          {levels && levels.length > 0
                            ? levels.map((level) => (
                                <option key={level.id} value={level.id}>
                                  {level.code}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div className="col form-group">
                        <label className="small_text">{"Status *"}</label>
                        <select
                          name="status"
                          className="form-control"
                          value={!!video.status ? video.status : ""}
                          onChange={this.onChangeHandler}
                          required
                        >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">{"Thumbnail *"}</label>
                      <div>
                        {!!imagePath ? (
                          <img
                            src={imagePath}
                            className="img-fluid img-thumbnail"
                            alt="Banner"
                          />
                        ) : (
                          <span className="small_text text-danger">
                            {"Update details to add thumbnail"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">{"Description *"}</label>
                      <textarea
                        name="description"
                        rows="15"
                        placeholder="Video Description"
                        className="form-control"
                        value={!!video.description ? video.description : ""}
                        onChange={this.onChangeHandler}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.props.isLoading}
                  >
                    Update Video
                  </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isLoading: state.admin.isLoading,
    streams: state.stream.streams,
    levels: state.course.levels,
    video: state.video.video,
  };
};

const mapActionsRoProps = {
  onGetDropDownStream: getDropDownStreams,
  onGetDropDownLevels: getDropDownCourseLevels,
  onGetVideoDetails: getVideoDetails,
  onUpdateVideo: updateVideo,
};

export default connect(mapStatesToProps, mapActionsRoProps)(EditVideo);
